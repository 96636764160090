import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useEffect, useMemo, useRef, useState } from 'react'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { modalVariants } from '../Employee/utils'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import DatePicker from 'react-datepicker'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { useGetOrgVendors } from '@/services/vendors/queries'
import { useAuthContext } from '@/context'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { TbCurrencyNaira } from 'react-icons/tb'
import { BillType } from '@/services/bill-payment/types'
import { useMutationSaveBill } from '@/services/bill-payment/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { capitalizeText } from '@/utils/string'
import { handleShowImage } from '@/modules/dashboard/Expenses/utils/utils'

import downArrow from '@/assets/whiteDownload.svg'
import { Link } from 'react-router-dom'
import { FILE_TYPE } from '@/modules/dashboard/Expenses/utils/enums'
import { PlusSquare } from 'lucide-react'
import { useGetOrgCategories } from '@/services/categories/queries'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    bill: BillType
    docFile?: FileUploadFormatProps | undefined
    setDocFile?: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    setAddVendor: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateBill = ({ closeModal, bill, docFile, setDocFile, setAddVendor }: Props) => {
    const billFormEmptyState = {
        vendor: ((bill?.vendor?.id ?? bill?.vendor) as unknown as string) ?? '',
        issue_date: bill?.createdAt ? (new Date(bill?.createdAt) as unknown as string) : '',
        due_date: bill?.due_date ? (new Date(bill?.due_date) as unknown as string) : '',
        amount: bill?.amount ? `${bill?.amount}` : '0',
        description: bill?.description ?? '',
        file: bill?.invoice_url ?? '',
        category: bill?.category?.id ?? '',
    }
    const [billFormState, setBillFormState] = useState(billFormEmptyState)

    const { mutate: saveBillFn, isLoading: isSaving } = useMutationSaveBill({
        onSuccess: () => {
            toastHandler({
                message: `Invoice bill ${bill.status === 'PENDING' ? 'updated' : 'created'}`,
                state: 'success',
            })
            if (setDocFile) {
                setDocFile(fileEmptyState)
            }
            closeModal(false)
        },
    })
    const handleSave = () => {
        saveBillFn({
            id: bill.id ?? bill._id,
            data: {
                amount: (Number(billFormState.amount) / 100).toString(), //convert back to naira
                invoice_number: bill.invoice_number,
                vendor: billFormState.vendor,
                issue_date: billFormState.issue_date,
                due_date: billFormState.due_date,
                sub_total: bill.sub_total / 100,
                tax: bill.tax / 100,
                notes: bill.notes,
                description: billFormState.description,
                category: billFormState.category,
            },
        })
    }
    const disableButton = () => {
        const res = Object.values({ ...billFormState })
            .map((value, key) => (key === 4 ? true : !!value))
            .some(data => data === false)

        return res
    }

    return (
        <ModalWrapper
            formId='createBillForm'
            shouldConfirmClose
            closeModal={() => closeModal(false)}
            variants={modalVariants}
            className='justify-end right-8 top-0'
        >
            <main className='h-[100vh] w-screen lg:min-w-[31rem] lg:w-auto'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium'>
                        {bill?.status === 'PENDING' ? 'Update' : 'Save'} Bill
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <section className='flex flex-col justify-between h-[calc(100vh-66px)]'>
                    <div>
                        <CreateInvoiceBill
                            docFile={docFile}
                            setDocFile={
                                setDocFile as React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
                            }
                            formState={billFormState}
                            setFormState={setBillFormState}
                            setAddVendor={setAddVendor}
                        />
                    </div>
                    <div className='flex justify-end gap-6 mt-2 border-t-[1px] border-[#DADCE0] py-4 pr-5 '>
                        {/* <Button
                            className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent'
                            onClick={() => {}}
                            disabled={isSaving}
                        >
                            Save as draft
                        </Button> */}
                        <Button
                            className='min-w-[100px] px-4'
                            loading={isSaving}
                            type='submit'
                            disabled={isSaving || disableButton()}
                            onClick={handleSave}
                        >
                            {bill?.status === 'PENDING' && 'Update'}
                            {bill?.status === 'DRAFT' && 'Save'} bill
                        </Button>
                    </div>
                </section>
            </main>
        </ModalWrapper>
    )
}

type createInvoiceBillProps = {
    docFile?: FileUploadFormatProps
    setDocFile: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    formState: invoiceFormProps
    setFormState: React.Dispatch<React.SetStateAction<invoiceFormProps>>
    setAddVendor: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateInvoiceBill = ({ docFile, setDocFile, formState, setFormState, setAddVendor }: createInvoiceBillProps) => {
    const { handleFile: uploadDocFn } = useSingleFileUpload({
        setFileUpload: setDocFile,
    })
    const [showProof, setShowProof] = useState(false)
    const docRef = useRef<HTMLInputElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const [openSelectContainer, setOpenSelectContainer] = useState(false)

    const { selectedAccount } = useAuthContext()
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)
    const { data } = useGetOrgVendors({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })
    const orgVendors = useMemo(() => data?.vendors ?? [], [data])

    const { data: org_categories } = useGetOrgCategories({})
    const orgCategories = useMemo(() => org_categories ?? [], [org_categories])

    return (
        <div className='px-7 h-[74vh] overflow-y-scroll mt-2' ref={modalRef}>
            <div className='mb-2 lg:mb-6'>
                <h1 className='text-dark font-medium text-lg'>Invoice bill</h1>
                <p className='text-gray7 text-sm'>Pay bills to your company vendors directly to their bank.</p>
            </div>
            <div className={`w-full ${showProof && 'flex gap-4'}`}>
                <form
                    id='createBillForm'
                    onSubmit={e => e.preventDefault()}
                    className={`flex flex-col gap-x-5 gap-y-5`}
                >
                    <div className='flex flex-col gap-3'>
                        <label className='flex items-center gap-1 text-sm'>
                            Vendor <span className='text-[red]'>*</span>
                        </label>
                        <Select
                            name='vendor'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    vendor: value,
                                }))
                            }}
                            value={formState.vendor}
                            onOpenChange={setOpenSelectContainer}
                            open={openSelectContainer}
                        >
                            <SelectTrigger
                                className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px] w-full'
                                id='customer'
                            >
                                {formState?.vendor ? <SelectValue /> : <span>Select Vendor</span>}
                            </SelectTrigger>
                            <SelectContent className='pb-3 max-h-[220px] overflow-scroll' id={`vendor_content`}>
                                <button
                                    className='flex items-center gap-1 pl-7 py-1 hover:bg-[#EFEFF9] w-full'
                                    onClick={() => {
                                        setAddVendor(true)
                                        setOpenSelectContainer(false)
                                    }}
                                >
                                    <PlusSquare size={15} className='mr-1' /> Create new vendor
                                </button>
                                {orgVendors?.map(vendor => (
                                    <SelectItem value={vendor?.id} key={vendor?.id}>
                                        {capitalizeText(vendor?.name)}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <label className='flex items-center gap-1 text-sm'>
                            Bill category <span className='text-[red]'>*</span>
                        </label>
                        <Select
                            name='category'
                            onValueChange={value => {
                                setFormState(prevState => ({
                                    ...prevState,
                                    category: value,
                                }))
                            }}
                            value={formState.category}
                        >
                            <SelectTrigger
                                className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px] w-full'
                                id='customer'
                            >
                                {formState?.category ? <SelectValue /> : <span>Select category</span>}
                            </SelectTrigger>
                            <SelectContent className='pb-3 max-h-[220px] overflow-scroll' id={`vendor_content`}>
                                {orgCategories?.map(category => (
                                    <SelectItem value={category?.id} key={category?.id}>
                                        {capitalizeText(category?.label)}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-3'>
                            <label className='flex items-center gap-1 text-sm'>
                                Issue Date <span className='text-[red]'>*</span>
                            </label>

                            <CustomDatePicker
                                ref={calendarRef1}
                                placeholderText='DD/MM/YYYY'
                                className='w-[70%]'
                                onChange={date =>
                                    setFormState(prev => ({
                                        ...prev,
                                        issue_date: date! as unknown as string,
                                    }))
                                }
                                wrapperClassName='border-[1px]'
                                selected={formState.issue_date as unknown as Date}
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className='flex items-center gap-1 text-sm'>
                                Due Date <span className='text-[red]'>*</span>
                            </label>
                            <CustomDatePicker
                                ref={calendarRef2}
                                className='w-[70%]'
                                placeholderText='DD/MM/YYYY'
                                onChange={date =>
                                    setFormState(prev => ({
                                        ...prev,
                                        due_date: date! as unknown as string,
                                    }))
                                }
                                wrapperClassName='border-[1px]'
                                selected={formState.due_date as unknown as Date}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-3'>
                            <label className='flex items-center gap-1 text-sm'>
                                Amount
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type={'text'}
                                name='amount'
                                value={formatAmount(formState.amount).replaceAll('₦', '')}
                                placeholder='Enter amount'
                                className='border-[#DADCE0] h-[3rem] disabled:bg-[#F9F9F9]'
                                onChange={e => {
                                    let value = e.target.value
                                    value = formatAmountWithoutSymbols(value)

                                    setFormState(prevState => ({
                                        ...prevState,
                                        amount: value,
                                    }))
                                }}
                                leftIcon={<TbCurrencyNaira fontSize={25} color={'#838383'} />}
                            />
                        </div>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label className='flex items-center gap-1'>Description</label>
                            <Textarea
                                name='note'
                                placeholder='Describe this bill'
                                className='border border-[#DADCE0] pt-4 min-h-[7.5rem] text-base resize-none focus:!outline-brand'
                                value={formState.description}
                                onChange={e =>
                                    setFormState(prev => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <label className='flex items-center gap-1 text-sm'>
                            Upload Proof
                            <span className='text-[red]'>*</span>
                        </label>
                        <div>
                            <div
                                className={`flex items-center ${
                                    docFile?.file_name ? '' : ' justify-center'
                                } gap-[128px] border border-dashed border-[#DADCE0] rounded-lg p-4 `}
                            >
                                <label htmlFor='first_doc' className='cursor-pointer'>
                                    {!!formState.file && !docFile?.file_name && <p>File already uploaded</p>}
                                    {!docFile?.file_name && !formState.file && (
                                        <>
                                            <p className='text-[#A8A8A8] text-base font-medium text-center'>
                                                Upload bill proof
                                                <br />
                                                Max 2MB | Accepts JPEG, PNG, and PDF formats
                                            </p>
                                        </>
                                    )}
                                    {docFile?.file_name && (
                                        <div className='bg-white flex justify-between items-center rounded-lg lg:w-[410px]'>
                                            <div className='flex items-center gap-4'>
                                                <div className=''>
                                                    {docFile?.fileType === 'pdf' ? (
                                                        <PDFIcon className='w-[4.5rem] h-24 inline-block rounded-lg' />
                                                    ) : (
                                                        <img
                                                            src={docFile.fileUrl}
                                                            alt=''
                                                            className='w-[4.5rem] h-24 rounded-lg'
                                                        />
                                                    )}
                                                </div>

                                                <div className='text-lg font-semibold'>
                                                    <h2 className='text-[#838383] truncate w-[180px] lg:w-auto'>
                                                        {docFile.file_name.slice(0, 20)}.{docFile.fileType}
                                                    </h2>
                                                    <p className='text-[#202020]'>{docFile.size}</p>
                                                </div>
                                            </div>

                                            <p className='cursor-pointer flex flex-col justify-between h-full gap-5'>
                                                {
                                                    <CancelIcon
                                                        onClick={() => setDocFile(fileEmptyState)}
                                                        className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]'
                                                    />
                                                }
                                                <p
                                                    className='text-xs font-semibold text-brand hidden lg:block'
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        setShowProof(!showProof)
                                                        if (modalRef.current) {
                                                            modalRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                        }
                                                    }}
                                                >
                                                    {showProof ? 'Hide' : 'Show'}
                                                </p>
                                            </p>
                                        </div>
                                    )}
                                    <input
                                        type='file'
                                        name='file'
                                        hidden
                                        id='first_doc'
                                        ref={docRef}
                                        onChange={e => {
                                            uploadDocFn(e)
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
                <ShowBillProof showProof={showProof} setShowProof={setShowProof} formState={formState} />
            </div>
        </div>
    )
}

type showProofProps = {
    showProof: boolean
    setShowProof: React.Dispatch<React.SetStateAction<boolean>>
    formState: invoiceFormProps
}
enum receipt_type {
    pdf = 'pdf',
}

const ShowBillProof = ({ showProof, setShowProof, formState }: showProofProps) => {
    const [isPdf, setIsPdf] = useState(false)

    const checkifReceiptIsPdf = () => {
        if (formState.file) {
            const receiptType = formState.file.split('.').slice(-1)[0]
            if (receiptType === receipt_type.pdf) {
                setIsPdf(true)
                return
            }
        }
        setIsPdf(false)
    }

    useEffect(() => {
        checkifReceiptIsPdf()
    }, [])

    return (
        <div className={`border-l border-[#DADCE0] pl-4 w-full min-w-[200px] ${!showProof && 'hidden'}`}>
            <div className='flex flex-col items-center justify-center'>
                <p className='text-sm font-semibold text-brand cursor-pointer mb-8' onClick={() => setShowProof(false)}>
                    Hide
                </p>
                <div
                    className={`overflow-hidden cursor-pointer lg:w-[15rem] h-max  mx-auto mt-5 lg:mt-0 ${formState.file ? 'bg-[#F9F9F9]' : ''} rounded-lg`}
                    onClick={() => {
                        handleShowImage(formState.file as string, isPdf ? FILE_TYPE.pdf : FILE_TYPE.image)
                    }}
                >
                    {formState.file ? (
                        isPdf ? (
                            <div className='group relative bg-[#F9F9F9] w-full flex flex-col justify-center items-center p-4'>
                                <PDFIcon className='w-[50px] h-[65px] rounded-lg ' />
                                <button className='text-sm py-2 text-center inline-block absolute transition-all -bottom-full group-hover:bottom-0  bg-green text-white w-full'>
                                    download receipt
                                </button>
                            </div>
                        ) : (
                            <div className='group relative w-full overflow-hidden rounded-lg'>
                                <img
                                    alt='receipt'
                                    src={formState.file}
                                    className='w-full object-cover h-[284px] rounded-lg border-[3px] border-[#DADCE0]'
                                />
                                <Link
                                    className='absolute top-3 right-3 bg-[#5E5E5ED4] rounded-sm p-1 cursor-pointer text-[#19C37D]/90'
                                    to={formState.file}
                                    target='_blank'
                                    download
                                >
                                    <img src={downArrow} alt='button' />
                                </Link>
                                <button className='text-sm py-2 text-center inline-block absolute transition-all group-hover:bottom-0 -bottom-full bg-green text-white w-full'>
                                    view receipt
                                </button>
                            </div>
                        )
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    )
}

type invoiceFormProps = {
    vendor: string
    issue_date: string
    due_date: string
    amount: string
    description: string
    file: string
    category: string
}

import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import { ExpenseType } from '@/types/expense'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { PDFIcon } from '@/assets/assets.export'
import downArrow from '@/assets/whiteDownload.svg'
import { Link } from 'react-router-dom'
import { Button } from '@/components/Button'
import { useEffect, useState } from 'react'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    expense?: ExpenseType
    setEditExpense: React.Dispatch<React.SetStateAction<boolean>>
    setOpenResolvePrompt: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ResolveAIGeneratedExpense({
    setShowModal,
    expense,
    setEditExpense,
    setOpenResolvePrompt,
}: Props) {
    const [activeUrl, setActiveUrl] = useState(expense?.receipt_urls?.[0] ?? expense?.receipt)

    useEffect(() => {})

    const isPdf = activeUrl?.includes('.pdf')

    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[30rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative'>
                <div className='border-b border-[#DADCE0] p-4 sticky top-0 bg-white rounded-t-lg z-50'>
                    <div className='flex items-center  justify-between h-full '>
                        <h2 className='font-semibold text-[#31254B]'>Scanned result - {expense?.expense_id}</h2>
                        <div
                            className='bg-[#F2F2F2] w-8 h-8 p-2 rounded-full flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setShowModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <p className='bg-[#FFEFE7] m-3 p-3 text-sm  rounded-md mb-4'>
                    Please compare the submitted details below with the information on the uploaded receipt.
                </p>
                <div className='bg-[#F9F9F9] rounded-lg p-3 m-3 mb-20'>
                    <div className='space-y-4'>
                        <div className='flex justify-between'>
                            <h5 className='text-sm text-gray7 '>Merchant name</h5>
                            <p className='font-medium text-sm'>{expense?.merchant_name}</p>
                        </div>
                        <div className='flex justify-between'>
                            <h5 className='text-sm text-gray7 '>Expense date</h5>
                            <p className='font-medium text-sm'>
                                {expense?.createdAt && format(new Date(expense?.createdAt), 'dd/MM/yyyy')}
                            </p>
                        </div>
                        <div className='flex justify-between'>
                            <h5 className='text-sm text-gray7 '>Category</h5>
                            <p className='font-medium text-sm'>{expense?.ai_scan_results?.[0]?.category}</p>
                        </div>
                        <div className='flex justify-between'>
                            <h5 className='text-sm text-gray7 '>Amount</h5>
                            <p className='font-medium text-sm'>{formatAmount(Number(expense?.amount))}</p>
                        </div>
                        <div>
                            <h5 className='text-sm text-gray7 '>Description</h5>
                            <p className='font-medium text-sm mt-1'>{expense?.description}</p>
                        </div>
                    </div>
                    <hr className='text-gray2 my-4' />
                    <h5 className='text-center text-gray7 text-light text-sm'>Uploaded receipt</h5>
                    <div
                        className={`overflow-hidden cursor-pointer h-max mt-3 lg:mt-0 ${activeUrl ? 'bg-[#F9F9F9]' : ''} rounded-lg flex items-center justify-center gap-10 pb-2`}
                    >
                        {activeUrl ? (
                            isPdf ? (
                                <div className='group relative bg-[#F9F9F9] w-[150px]  h-[100px] flex flex-col justify-end items-center p-4'>
                                    <PDFIcon className='w-full h-full rounded-lg ' />
                                </div>
                            ) : (
                                <div className='group relative w-[150px] h-[100px] overflow-hidden rounded-lg'>
                                    <img
                                        alt='receipt'
                                        src={activeUrl}
                                        className='w-full h-full object-cover  rounded-lg border-[3px] border-[#DADCE0]'
                                    />
                                    <Link
                                        className='absolute top-3 right-3 bg-[#5E5E5ED4] rounded-sm p-1 cursor-pointer text-[#19C37D]/90'
                                        to={activeUrl}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download
                                    >
                                        <img src={downArrow} alt='button' />
                                    </Link>
                                </div>
                            )
                        ) : (
                            ''
                        )}
                        {expense?.receipt_urls && (
                            <div className=' flex lg:justify-end mb-4 lg:mb-0 gap-2'>
                                {expense?.receipt_urls?.map((url, i) => (
                                    <button
                                        type='button'
                                        className={`text-primary-100 bg-primary-200  rounded mt-4 border   p-1 ${url === activeUrl ? 'border-brand border-2' : 'border-gray5'}`}
                                        onClick={() => setActiveUrl(expense?.receipt_urls?.[i])}
                                    >
                                        <>
                                            {url?.includes('pdf') ? (
                                                <PDFIcon className='w-5 h-5' />
                                            ) : (
                                                <img src={url} alt='Receipt' className='w-8 h-8 object-cover' />
                                            )}
                                        </>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                    <Button
                        onClick={() => {
                            setEditExpense(true)
                            setShowModal(false)
                        }}
                        className='bg-transparent disabled:bg-transparent disabled:opacity-40 text-[#202020] px-5 h-[2.5rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenResolvePrompt(true)
                            setShowModal(false)
                        }}
                    >
                        Resolve issue
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}

import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import handleResponseError from '../../lib/handleResponseError'

let BASE_URL: any

if (import.meta.env.VITE_APP_ENV === 'development') {
    BASE_URL = 'https://chidi-32.localcan.dev/api'
} else if (import.meta.env.VITE_APP_ENV === 'staging') {
    BASE_URL = 'https://core-staging-api.bizpend.com/api'
} else if (import.meta.env.VITE_APP_ENV === 'production') {
    BASE_URL = 'https://core-production-api.bizpend.com/api'
} else {
    BASE_URL = 'https://core-staging-api.bizpend.com/api'
}

const TEST_URL = 'https://core-dev-api.bizpend.com/api'

const isTestMode = localStorage.getItem('testMode') === 'true'

if (isTestMode) {
    BASE_URL = TEST_URL
}

export { BASE_URL }

const requestHeaders: { [key: string]: string } = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
}

function handleClearLocalStorage() {
    return ['user', 'loggedIn', 'accounts'].forEach(key => {
        window.localStorage.removeItem(key)
    })
}
const Axios: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: requestHeaders,
    withCredentials: true,
    // signal: controller.signal,
})

// Add a request interceptor
Axios.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        // Retrieve the user's time zone
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        // Add the time zone to the headers
        config.headers['x-time-zone'] = userTimeZone

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

let unAuthorizedStatus = false
// Add a response interceptor
Axios.interceptors.response.use(
    function (response) {
        if (window.location.pathname === '/login') {
            unAuthorizedStatus = false
        }
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data
    },
    function (error) {
        // console.log({ error })
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response.status === 401 || error.response.status === 403) {
            // controller.abort()
            if (unAuthorizedStatus) return
            handleResponseError(error.response)
            handleClearLocalStorage()
            window.location.href = '/login'
            unAuthorizedStatus = true
            return
        }

        handleResponseError(error.response)
        throw error
    }
)

export default Axios

import { ChangeEvent, useRef, useState } from 'react'
import { useCreateSignedUrl } from '@/services/file-upload/mutation'
import { ModuleNameEnum, generateFileName, sizeInKilobytes } from '@/hooks/useSingleFileUpload'
import { useAuthContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import { PlusSquare } from 'lucide-react'

type Receipt = {
    url: string
    name?: string
    size?: number
}

type Props = {
    name: string
    setReceiptUrls: (data: Receipt[]) => void
    receiptUrls: Receipt[]
}

export const UploadExpenseReceiptToS3 = ({ name, setReceiptUrls, receiptUrls }: Props) => {
    const { selectedAccount } = useAuthContext()
    const [files, setFiles] = useState<File[]>([])
    const { mutate, isLoading } = useCreateSignedUrl({
        onSuccess: async dataFromSer => {
            const newUploadedReceipts: Receipt[] = []

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const signedUrl = dataFromSer[i]?.signedUrl

                if (signedUrl) {
                    await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                            'Content-Length': String(file.size),
                        },
                    })

                    newUploadedReceipts.push({
                        url: dataFromSer[i]?.url,
                        name: file.name,
                        size: file.size,
                    })
                }
            }

            setReceiptUrls([...receiptUrls, ...newUploadedReceipts])
            setFiles([]) // Clear the new files state
        },
    })

    const handleGetSignedURLs = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : []

        if (selectedFiles.length > 5) {
            toastHandler({ message: `You can only upload up to 5 receipts.`, state: 'error' })
            return
        }

        if (receiptUrls.length + selectedFiles.length > 5) {
            toastHandler({ message: `You can only upload up to 5 receipts in total.`, state: 'error' })
            return
        }

        setFiles(selectedFiles)

        mutate({
            files: selectedFiles.map(file => ({
                fileName: generateFileName({
                    module: 'expense' as ModuleNameEnum,
                    unique_identifier: `${name}-${file.name.split('.').join('-')}`,
                    org_id: selectedAccount?.org?.id ?? '',
                    selectedFileName: file.name,
                }),
                contentType: file.type,
            })),
            module: 'expense',
        })
    }

    const ref = useRef<HTMLInputElement>(null)

    return (
        <section className={`${isLoading ? 'opacity-60' : ''} h-full`}>
            <div className='flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0]'>
                <label htmlFor={name} className='cursor-pointer upload '>
                    {receiptUrls?.length === 0 && (
                        <span className='my-8 flex items-center justify-center'>
                            <span className='text-center text-[18px] text-[#A8A8A8] font-semibold my-auto'>
                                Upload receipt
                                <br />
                                Max 2MB | Accepts JPEG, PNG, and PDF formats
                            </span>
                        </span>
                    )}

                    <input
                        disabled={isLoading}
                        name={name}
                        type='file'
                        id={name}
                        hidden
                        ref={ref}
                        accept={'.pdf, .jpeg, .jpg, .png'}
                        onChange={handleGetSignedURLs}
                        multiple
                    />
                </label>

                {receiptUrls?.length > 0 && (
                    <div className='flex flex-col gap-2 p-1 bg-gray5/30 w-full'>
                        {receiptUrls?.map((receipt, index) => (
                            <div key={index} className='rounded-lg px-5 py-3 w-full'>
                                <div className='bg-white flex justify-between items-center h-full rounded-lg px-4'>
                                    <div className='flex items-center gap-4'>
                                        <div className=''>
                                            {receipt.url.includes('pdf') ? (
                                                <PDFIcon className='w-[4.5rem] h-24 inline-block rounded-lg' />
                                            ) : (
                                                <img
                                                    src={receipt.url}
                                                    alt=''
                                                    className='w-[4.5rem] h-24 rounded-lg object-cover'
                                                />
                                            )}
                                        </div>

                                        <div className='text-lg font-semibold'>
                                            <h2 className='text-[#838383] max-w-xs lg:w-auto truncate'>
                                                {receipt?.name?.slice(0, 60)}
                                            </h2>
                                            <p className='text-[#202020]'>{sizeInKilobytes(receipt?.size ?? 0)}</p>
                                        </div>
                                    </div>

                                    <span
                                        className='cursor-pointer'
                                        onClick={() => {
                                            setReceiptUrls(receiptUrls.filter((_, i) => i !== index))
                                        }}
                                    >
                                        <CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]' />
                                    </span>
                                </div>
                            </div>
                        ))}

                        {receiptUrls.length > 0 && (
                            <div
                                className='flex justify-center mt-2.5 mb-2'
                                onClick={() => {
                                    ref.current?.click()
                                }}
                            >
                                <button
                                    type='button'
                                    className=' inline-flex items-center gap-2 text-xs text-gray7 font-medium border rounded border-gray8 p-1.5 px-2'
                                >
                                    <PlusSquare className='text-gray7 ' size={17} strokeWidth={1} />
                                    Add more receipt
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    )
}

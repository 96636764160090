import { useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion/index2'
import { OrangeBulb } from '@/assets/assets.export'
import { ArrowRight, ChevronDown } from 'lucide-react'
import { cn } from '@/lib/styling'
import { Link } from 'react-router-dom'

export const UrgentActions = () => {
    const [show, setShow] = useState(false)

    return (
        <div className=''>
            <Accordion type='single' collapsible>
                <AccordionItem value='item-1' className='border border-[#E17323] bg-[#FFEFE7] rounded-[12px] p-4 pb-0'>
                    <AccordionTrigger className='w-full pb-4' onClick={() => setShow(!show)}>
                        <div className='flex justify-between items-center w-full'>
                            <div className='flex items-center justify-center gap-3'>
                                <OrangeBulb />
                                <div className='text-left'>
                                    <p className='font-semibold'>Your attention is needed</p>
                                    <p className='text-gray7 font-medium'>
                                        Review pending approvals, incomplete tasks, or flagged items requiring action to
                                        keep workflows moving efficiently.
                                    </p>
                                </div>
                            </div>
                            <ChevronDown className={cn('transition-all', show ? '-rotate-180' : '')} />
                        </div>
                    </AccordionTrigger>
                    <AccordionContent className=''>
                        <div className='border-t mt-3 border-[#DADCE0]'>
                            <div className='mt-7 grid grid-cols-2 lg:grid-cols-4 gap-3'>
                                {actions_data?.map(action => <ActionsCard action={action} />)}
                            </div>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

const ActionsCard = ({
    action,
}: {
    action: {
        heading: string
        amount: number
        link: string
    }
}) => {
    return (
        <div className='bg-white p-4 rounded-[12px]'>
            <h2 className='text-sm text-[#667185] font-medium mb-4'>{action?.heading}</h2>
            <p className='text-[2rem] font-semibold text-[#1D2739]'>{action?.amount}</p>
            <Link
                to={action?.link}
                className='mt-3 border-t border-gray8 flex items-center w-full justify-between pt-3 text-brand'
            >
                <p className=' text-sm font-semibold'>View all</p>
                <ArrowRight />
            </Link>
        </div>
    )
}

const actions_data = [
    {
        heading: 'Pending expense approvals',
        amount: 14,
        link: '/dashboard/expenses/expense_list/company',
    },
    {
        heading: 'Pending invoice approvals',
        amount: 6,
        link: '/dashboard/invoice/invoice_list',
    },
    {
        heading: 'Overdue invoices',
        amount: 12,
        link: '/dashboard/invoice/invoice_list',
    },
    {
        heading: 'Overdue vendor payments',
        amount: 5,
        link: '/dashboard/vendor_management/bill_payment/lists',
    },
]

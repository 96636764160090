import { BillType } from '@/services/bill-payment/types'
import TimelineJourney from '@/components/TimelineJourney'

export default function BillDetailsTimeLine({ bill }: { bill: BillType }) {
    const timelines = bill?.timeline?.events ?? []

    return (
        <div className='flex flex-col text-[#202020]'>
            <h1 className='text-lg font-semibold border-b border-[#DADCE0] py-3 mb-7'>Bill journey timeline</h1>
            <TimelineJourney timelines={timelines} />
            {timelines?.length === 0 && <p className='font-medium'>This bill is still a draft</p>}
        </div>
    )
}

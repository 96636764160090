import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { ChartOfAccountTypeImport } from '@/types/accounting'
import { useUploadCOA } from '@/services/accounting/mutations'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { useAccountingContext } from '@/context'

type Props = {
    closeModal: () => void
    uploadedCOA: ChartOfAccountTypeImport[]
    nextStep?: () => void
    prevStep?: () => void
    overwriteData: boolean
}

type FormType = {
    rows: {
        account_name: string
        account_type: string
        description: string
        code: string
    }[]
}

const PreviewUploadedCOAModal = ({ closeModal, nextStep, uploadedCOA, prevStep, overwriteData }: Props) => {
    const { accounttingTypes } = useAccountingContext()

    const form = useForm<FormType>({
        defaultValues: {
            rows: uploadedCOA?.map(coa => ({
                account_name: coa?.Account_Name,
                account_type: accounttingTypes
                    ?.flatMap(type => type?.children ?? [])
                    ?.find(type => type?.name?.toLowerCase() == coa?.Sub_Account_Type?.toLowerCase())?._id,
                description: coa?.Description,
                code: coa?.Code,
            })),
        },
    })

    const { fields } = useFieldArray<FormType>({
        name: 'rows',
        control: form.control,
    })

    const { mutate: uploadChartOfAccountFn, isLoading } = useUploadCOA({
        onSuccess: () => {
            nextStep?.()
        },
    })

    const handleImport = (values: FormType) => {
        uploadChartOfAccountFn({
            data: values?.rows?.map(coa => ({
                code: coa.code,
                account_name: coa.account_name,
                account_type: coa.account_type,
                description: coa.description,
            })),
            override_duplicate: overwriteData,
        })
    }

    return (
        <section className='min-h-[15rem] w-[33rem] overflow-scroll '>
            <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                <h2 className='text-center text-base font-medium opacity-80'>Preview uploaded Chart of accounts</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='p-5'>
                <div className='mt-2'>
                    <div className='rounded-lg border border-gray5 overflow-hidden mt-2'>
                        <table className='w-full leading-normal border-collapse text-xs'>
                            <thead>
                                <tr>
                                    {['Account name', 'ID', 'Sub-account type'].map((col, i) => (
                                        <th
                                            key={i}
                                            className=' border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'
                                        >
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map((field, i) => (
                                    <tr key={i}>
                                        <td className='border-gray5 px-5 h-12 border-t font-medium text-gray7'>
                                            {field.account_name}
                                        </td>
                                        <td className='border-gray5 px-5 h-12 border-t  font-medium text-gray7'>
                                            {field.code}
                                        </td>

                                        <td className=' border-gray5 px-5 h-12 border-t  font-medium text-gray7'>
                                            <Controller
                                                name={`rows.${i}.account_type`}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <Select onValueChange={field.onChange} value={field.value}>
                                                        <SelectTrigger className=' text-xs text-gray7  h-10  border-gray8 flex disabled:opacity-70'>
                                                            <SelectValue placeholder={'Select account type'} />
                                                        </SelectTrigger>
                                                        <SelectContent className='w-60 h-56'>
                                                            {accounttingTypes?.map(type => (
                                                                <SelectGroup className='my-1'>
                                                                    <SelectLabel className='text-black pl-2'>
                                                                        {type?.name}
                                                                    </SelectLabel>
                                                                    {type?.children.map(child => (
                                                                        <SelectItem
                                                                            className='text-gray7 pl-6'
                                                                            value={child?._id ?? child?.id}
                                                                        >
                                                                            {child?.name}
                                                                        </SelectItem>
                                                                    ))}
                                                                    <SelectSeparator />
                                                                </SelectGroup>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] px-3 '>
                    back to upload page
                </Button>
                <Button
                    onClick={form.handleSubmit(handleImport)}
                    loading={isLoading}
                    size={'lg'}
                    disabled={!form.formState.isValid}
                    className='text-xs px-4'
                >
                    Confirm and import
                </Button>
            </div>
        </section>
    )
}

export default PreviewUploadedCOAModal

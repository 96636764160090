import { AiOutlineClose } from 'react-icons/ai'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { useMutationDeclineBill } from '@/services/bill-payment/mutations'

type FlagTransactionProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    id: string
}

const DeclineBillModal = ({ closeModal, id }: FlagTransactionProps) => {
    const [flagReason, setFlagReason] = useState<string>('')
    const [note, setNote] = useState<string>('')

    const { mutate: declineFn, isLoading } = useMutationDeclineBill({
        onSuccess: () => {
            closeModal(false)
            toastHandler({
                message: 'Bill declined successfully',
                state: 'success',
            })
        },
    })
    const handleDeclineBill = () => {
        declineFn({
            id: id,
            reason: flagReason,
        })
    }

    return (
        <ModalWrapper variants={undefined}>
            <div className='w-screen lg:w-[37rem] max-h-[90vh] overflow-scroll '>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-2xl'>Decline bill</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-[1rem] lg:px-[1.8rem] mt-7'>
                    <h1 className='text-xl'>Why are you declining this bill?</h1>
                    <p className='text-sm text-[#5E5E5E]'>
                        Are you sure you want to decline this bill? Please provide a reason below.
                    </p>
                </div>
                <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className='mt-2 pb-6'>
                    <div className='px-[1rem] lg:p-[1.8rem] pt-3 pb-0 max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                        <div className={`flex flex-col gap-[1.6rem] mb-16`}>
                            <div className='mt-5 flex flex-col gap-2 mb-5'>
                                <label htmlFor='flag' className='text-[#202020] text-[14px] font-medium'>
                                    Reason <span className='text-[red]'>*</span>
                                </label>
                                <Select name='flag' onValueChange={e => setFlagReason(e)}>
                                    <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                        <SelectValue placeholder={`Why are you declining this bill`} />
                                    </SelectTrigger>
                                    <SelectContent className='w-[33.3rem] max-h-[200px] overflow-scroll'>
                                        <SelectItem value='Suspicious Charges'>Suspicious Charges</SelectItem>
                                        <SelectItem value='Fraudulent Activities'>Fraudulent Activities</SelectItem>
                                        <SelectItem value='Others'>Others</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>Additional note (Optional)</label>
                                <Textarea
                                    name='note'
                                    placeholder='Explain further if you wish to.'
                                    className='border border-[#DADCE0] pt-4 min-h-[7.5rem] text-base resize-none'
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </div>
                        </div>
                        <Button
                            className='w-full bg-errorRed text-base font-normal mb-3 hover:bg-errorRed disabled:bg-errorRed disabled:opacity-30'
                            onClick={handleDeclineBill}
                            loading={isLoading}
                            disabled={!(flagReason.length > 0)}
                        >
                            Yes, decline this bill
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}

export default DeclineBillModal

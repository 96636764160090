import { TabTriggersContainer, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import PageContent from '@/layout/PageContent'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { VaultDocuments } from './partials/VaultDocuments/VaultDocuments'
import { VaultSettings } from './partials/VaultSettings/VaultSettings'
import { useGetAllVaults, useGetVaultStorageDetails } from '@/services/vault/queries'
import { useMemo } from 'react'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { ModuleNameEnum } from '@/types/billing'
import usePersistedState from '@/hooks/usePersistedState'

const vault_routes_const = {
    documents: 'documents',
    settings: 'settings',
}
export default function Vault() {
    const { vault_subRoute } = useParams()
    const { setShowUpgradeModal, setCurrentModule, selectedAccount } = useAuthContext()
    const navigate = useNavigate()
    const [storageWarning, setStorageWarning] = usePersistedState({
        key: `storage_warning_${selectedAccount?.org?.id}`,
        defaultValue: true,
    })
    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const { data: vaultStorage } = useGetVaultStorageDetails({
        enabled: !!vaults,
    })
    const sizeUsed = vaultStorage
        ? Math.ceil(
              (vaultStorage?.usedStorageSize.overallStorageSize /
                  (vaultStorage?.usedStorageSize.overallStorageSize + vaultStorage?.remainingStorageSize)) *
                  100
          )
        : 0

    if (isLoadingVaults) return <ProgressBar />
    return (
        <section className='h-full mt-5'>
            <section className='px-2 lg:px-0 pb-[7rem]'>
                {vaults && storageWarning && sizeUsed > 89.9 && (
                    <div className='flex justify-between items-center w-[93%] mx-auto py-2 px-3 border border-errorRed rounded-lg bg-[#FFEDED] mb-2'>
                        <div className=''>
                            <p className='font-semibold mb-1'>Storage is almost full. </p>
                            <p className='text-sm'>
                                {vaultStorage ? `${sizeUsed}%` : '0%'} of your vault storage has been used
                            </p>
                        </div>
                        <div className='flex gap-3 items-center'>
                            <button
                                onClick={() => {
                                    setShowUpgradeModal(true)
                                    setCurrentModule(ModuleNameEnum.VAULT)
                                }}
                                className='bg-black text-gray8 rounded-lg text-xs py-2 px-3'
                            >
                                Upgrade
                            </button>
                            <button className='text-sm' onClick={() => setStorageWarning(false)}>
                                Dismiss
                            </button>
                        </div>
                    </div>
                )}
                <Tabs defaultValue={vault_subRoute} value={vault_subRoute}>
                    <TabsList className='border-none'>
                        <TabTriggersContainer>
                            {vaultsData?.length > 0 && (
                                <>
                                    <TabsTrigger
                                        value={vault_routes_const.documents}
                                        onClick={() => navigate(`/dashboard/vault/${vault_routes_const.documents}`)}
                                        className={`relative border-none ${
                                            vault_routes_const.documents === vault_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {vault_routes_const.documents === vault_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Documents
                                        </motion.span>
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value={vault_routes_const.settings}
                                        onClick={() => navigate(`/dashboard/vault/${vault_routes_const.settings}`)}
                                        className={`relative border-none ${
                                            vault_routes_const.settings === vault_subRoute ? '!text-white' : ''
                                        } text-sm`}
                                    >
                                        {vault_routes_const.settings === vault_subRoute && (
                                            <motion.div layoutId='selected_btn' className={'v2_tab_styles'} />
                                        )}
                                        <motion.span layout='position' className='z-20 relative left-2 bottom-1.5'>
                                            Settings
                                        </motion.span>
                                    </TabsTrigger>
                                </>
                            )}
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={vault_routes_const.documents}>
                        <PageContent>
                            <VaultDocuments />
                        </PageContent>
                    </TabsContent>
                    {vaultsData?.length > 0 && (
                        <TabsContent value={vault_routes_const.settings}>
                            <PageContent>
                                <VaultSettings />
                            </PageContent>
                        </TabsContent>
                    )}
                </Tabs>
            </section>
        </section>
    )
}

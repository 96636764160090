import { Button, Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search, Upload } from 'lucide-react'

import ChartOfAccountTable from '../components/ChartOfAccountsTable'
import { useGetCOA } from '@/services/accounting/queries'
import { useMemo, useRef, useState } from 'react'
import ImportChartOfAccount from '@/components/Modals/Accounting/ImportChartOfAccount'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { Pagination } from '@/types/utils'
import AddNewAccountModal from '@/components/Modals/Accounts/AddNewAccountModal'
import { useAccountingContext } from '@/context'
import { exportCOAFormats } from '../utils'
import { useExportAllCOA, useExportCOAByPage } from '@/services/accounting/mutations'
import { CgSpinner } from 'react-icons/cg'
import useClickOutside from '@/hooks/useClickOutside'

export default function AccountingChartOfAccount() {
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ account_type: string }>()
    const { accounttingTypes } = useAccountingContext()
    const subRoute = searchParamDetails.get('account_type') ?? accounttingTypes?.[0]?._id
    const [openImportModal, setOpenImportModal] = useState(false)
    const [openAddNewAccount, setOpenAddNewAccount] = useState(false)
    const [activeTab, setActiveTab] = useState('all')
    const [uploadDropdown, setUploadDropdown] = useState(false)

    const uploadDropdownRef = useRef<HTMLDivElement>(null)
    useClickOutside(uploadDropdownRef, () => setUploadDropdown(false))

    const { data: coaData, isLoading } = useGetCOA()

    const { isLoading: isExportingAllCoa, mutate: exportAllCoaFn } = useExportAllCOA({
        onSuccess: () => setUploadDropdown(false),
    })
    const { isLoading: isExportingByPage, mutate: exportCoaByPageFn } = useExportCOAByPage({
        onSuccess: () => setUploadDropdown(false),
    })

    const chartOfAccountData = useMemo(
        () =>
            coaData?.results
                ?.filter(coa => coa?.account_type?.parent?.id === subRoute)
                ?.sort((a, b) => Number(a.is_mutable) - Number(b.is_mutable)) ?? [],
        [coaData, subRoute]
    )

    const pagination = useMemo(() => coaData?.pagination ?? ({} as Pagination), [coaData])
    const analytics = useMemo(() => coaData?.analytics ?? [], [coaData])

    const filterDetailsBtn = accounttingTypes?.map(type => ({
        desc: type?.name,
        id: type?._id,
        filterVal: analytics.find(data => data.account_type === type?._id)?.count,
    }))

    const handleExportCOA = (format: string) => {
        if (activeTab === 'all') {
            exportAllCoaFn({
                file_type: format,
            })

            return
        }
        exportCoaByPageFn({
            file_type: format,
            ids: chartOfAccountData,
        })
    }

    return (
        <section className='mt-8'>
            <div className='flex flex-col lg:flex-row gap-5 justify-between'>
                <div>
                    <h2 className='text-xl font-semibold'>Chart of accounts</h2>
                    <p className='text-sm text-gray7'>Organize and manage your financial accounts seamlessly</p>
                </div>
                <div className='flex items-center gap-3'>
                    <Button className='text-sm min-h-[35px]' onClick={() => setOpenAddNewAccount(true)}>
                        Add new account
                    </Button>
                    <Button
                        onClick={() => setOpenImportModal(true)}
                        variant={'outline'}
                        size={'sm'}
                        className='text-sm py-2 px-3'
                    >
                        Import Chart of Accounts
                    </Button>
                </div>
            </div>
            <section className='mt-8 mb-4 bg-white p-4 rounded-xl'>
                <div className='overflow-x-scroll'>
                    <ul className='flex items-center gap-4 mb-5 bg-gray5/20 w-fit p-2 rounded-xl'>
                        {filterDetailsBtn.map(btnDetails => (
                            <li
                                className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                onClick={() => setAccoutingFilter('account_type', btnDetails?.id)}
                            >
                                <button className='flex gap-2'>
                                    <span className='z-10 text-gray7'>{btnDetails.desc} </span>
                                    {btnDetails?.filterVal && (
                                        <span
                                            className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl z-10`}
                                        >
                                            {btnDetails?.filterVal}
                                        </span>
                                    )}
                                </button>
                                {btnDetails.id === subRoute && (
                                    <motion.div
                                        layoutId='accounting-coa-filter'
                                        className={
                                            'bg-white rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute z-0'
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='flex items-center justify-end gap-5'>
                    <Input
                        placeholder='Search'
                        className='max-w-xs h-11 border-gray4'
                        bodyClassName='w-fit'
                        leftIcon={<Search className='text-gray' />}
                    />

                    <div className='relative' ref={uploadDropdownRef}>
                        <button
                            aria-label='Upload'
                            onClick={() => setUploadDropdown(prev => !prev)}
                            className='rounded border-gray1/50 h-11 px-3 border'
                        >
                            <Upload className='text-gray7' size={20} />
                        </button>

                        {uploadDropdown && (
                            <div className='w-52 flex flex-col gap-3 absolute right-0 p-4 rounded-lg justify-center border border-gray/30 bg-white mt-2 z-10'>
                                {isExportingAllCoa || isExportingByPage ? (
                                    <CgSpinner
                                        className='animate-spin text-gray7 self-center'
                                        strokeWidth={1.2}
                                        size={40}
                                    />
                                ) : (
                                    <>
                                        <div className='flex gap-4 mb-2'>
                                            {['All', 'Current page'].map(data => (
                                                <div
                                                    className={`relative text-sm rounded-md p-1.5 px-3`}
                                                    onClick={() => setActiveTab(data.toLowerCase())}
                                                >
                                                    {activeTab == data.toLowerCase() && (
                                                        <motion.span
                                                            layoutId='exports_animation'
                                                            className='bg-brand/20 rounded-md  text-sm  w-full left-[1px] top-0  bottom-0 absolute z-0'
                                                        />
                                                    )}
                                                    <button className='flex gap-2'>
                                                        <span
                                                            className={`z-10 text-sm ${activeTab == data.toLowerCase() ? 'font-medium' : 'text-gray7'}`}
                                                        >
                                                            {data}{' '}
                                                        </span>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        {exportCOAFormats.map(format => (
                                            <div>
                                                <span
                                                    onClick={() => handleExportCOA(format.id)}
                                                    className='cursor-pointer text-gray7 font-medium text-sm'
                                                >
                                                    {format.name}
                                                </span>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={`mt-5 ${isLoading ? 'opacity-30' : ''}`}>
                    <ChartOfAccountTable coa={chartOfAccountData} pagination={pagination} />
                </div>
            </section>

            {openImportModal && (
                <ModalWrapper>
                    <ImportChartOfAccount closeModal={() => setOpenImportModal(false)} />
                </ModalWrapper>
            )}
            {openAddNewAccount && <AddNewAccountModal closeModal={() => setOpenAddNewAccount(false)} />}
        </section>
    )
}

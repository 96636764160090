import { VaultItem } from '@/services/vault/types'
import { FolderLists } from '../Folders/FolderLists'
import { FileLists } from '../Files/FileLists'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetVaultCatgeories, useGetVaultItem } from '@/services/vault/queries'
import { capitalizeText } from '@/utils/string'
import { useVaultContext } from '@/context'
import { ChevronRight } from 'lucide-react'

type FullFolderProps = {
    items: VaultItem[]
}

export const SingleFolderFullView = ({ items }: FullFolderProps) => {
    const folders = items?.filter(item => item.type === 'folder')
    const files = items?.filter(item => item.type === 'file')
    const [category, setCategory] = useState<string>('')
    const [openSelectContainer, setOpenSelectContainer] = useState(false)

    // const [type, setType] = useState<string>('')
    const [searchParams] = useSearchParams()
    const itemId = searchParams.get('folder')
    const navigate = useNavigate()

    const { selectedFolder } = useVaultContext()
    const { data: vaultItem } = useGetVaultItem({
        queryParams: {
            id: itemId as string,
        },
    })
    const { data: vaultCategories } = useGetVaultCatgeories({})
    const notInRootVault = vaultItem?.parent !== vaultItem?.vault

    return (
        <div>
            <p className='flex'>
                <span onClick={() => navigate(-1)} className='text-black cursor-pointer'>
                    {notInRootVault ? selectedFolder?.name : capitalizeText(vaultItem?.name)}
                </span>
                {notInRootVault && (
                    <>
                        <ChevronRight color='#5e5e5e' />
                        <span className='text-gray7'>{capitalizeText(vaultItem?.name)}</span>
                    </>
                )}
            </p>
            <div className='flex gap-3 items-center my-4'>
                <Select
                    onValueChange={setCategory}
                    value={category}
                    onOpenChange={setOpenSelectContainer}
                    open={openSelectContainer}
                >
                    <SelectTrigger className='w-[168px] border-gray8 text-[#5E5E5E]' id='transactionYear'>
                        {category ? <SelectValue /> : <span>Category</span>}
                    </SelectTrigger>
                    <SelectContent className='h-fit max-h-[10rem] w-[10rem] z-[1000]'>
                        {vaultCategories?.map(category => (
                            <SelectItem value={category?.id} className='pl-3' key={category?.id}>
                                <h6 className='text-sm'>{category?.name}</h6>
                            </SelectItem>
                        ))}
                        <button
                            className='w-full text-left text-sm pl-3 py-1'
                            onClick={() => {
                                setCategory('')
                                setOpenSelectContainer(false)
                            }}
                        >
                            Clear filter
                        </button>
                    </SelectContent>
                </Select>
            </div>
            <div className='mt-6'>
                {folders?.length > 0 && (
                    <div className='mb-4'>
                        <h1 className='font-semibold mb-6'>Folders</h1>
                        <div className='mt-3 grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 gap-y-8 2xl:gap-y-5'>
                            <FolderLists folders={folders as VaultItem[]} />
                        </div>
                    </div>
                )}
                {files?.length > 0 && (
                    <div className=''>
                        <h1 className='font-semibold'>Files</h1>
                        <div className='mt-3 grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4'>
                            <FileLists
                                files={
                                    category
                                        ? files?.filter(file => file?.category === category)
                                        : (files as VaultItem[])
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import { format } from 'date-fns'
import { ExpenseType } from '@/types/expense'
import { formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'

import { ExpenseFieldProps } from './utils'
import { PDFIcon } from '@/assets/assets.export'
import { CategoryType } from '@/types/categories'
import { useAuthContext, useCurrencyContext } from '@/context'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'

type Props = {
    setPreview: React.Dispatch<React.SetStateAction<number>>
    formProps: ExpenseFieldProps
    budget?: () => string
    category?: CategoryType
    selectedExpense?: ExpenseType
}

export default function PreviewExpense({ setPreview, formProps, category, budget, selectedExpense }: Props) {
    const { selectedAccount } = useAuthContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const [activeUrl, setActiveUrl] = React.useState(
        formProps?.receiptUrls?.[0] ?? { url: selectedExpense?.receipt, name: undefined, size: undefined }
    )
    const currencySymbol = selectedCurrencyInfo?.(formProps?.currency)?.symbol
    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )

    const previewDetails = [
        { name: 'Merchant name', desc: formProps?.merchant },
        { name: 'Expense date', desc: formProps?.date && format(new Date(formProps?.date), 'dd/MM/yyyy') },
        { name: 'Category', desc: category?.label },
        {
            name: 'Amount',

            desc: `${currencySymbol}${formatAmountNoSymbol(formatAmountWithoutSymbols(formProps?.amount))}`,
        },
        {
            name: 'Receipt',
            desc: selectedExpense?.receipt || Number(formProps?.receiptUrls?.length) > 0 ? 'Attached' : 'N/A',
        },
        {
            name: 'Expense type',
            desc: formProps?.expense_type === 'reimbursable' ? 'Reimbursable' : 'Non-reimbursable',
        },
        { name: 'Budget', desc: budget?.() ?? 'N/A', id: 'budget' },
        {
            name: 'Expense description',
            desc: formProps?.description,
            id: 'description',
        },
    ]

    const checkifReceiptIsPdf = activeUrl?.url?.split('.').slice(-1)[0] === 'pdf'
    const receiptUrls = formProps?.receiptUrls ?? [{ url: selectedExpense?.receipt }]

    return (
        <section className='overflow-scroll mx-6 mt-8'>
            <div className='mb-4 inline-flex items-center cursor-pointer' onClick={() => setPreview(1)}>
                <BsArrowLeft />
                <p className='ml-2'>go back</p>
            </div>
            <div
                className={`grid grid-cols-2 ${Number(formProps?.receiptUrls?.length) > 0 ? 'lg:grid-cols-4' : 'lg:grid-cols-3'} pb-8`}
            >
                {previewDetails?.map(expenseDetail => {
                    if (!orgExpensePermissions && expenseDetail?.id === 'budget') return
                    if (formProps?.attact_budget === 'no_link' && expenseDetail?.id) return
                    return (
                        <div
                            key={expenseDetail?.id}
                            className={`py-3 px-5  rounded-lg flex flex-col justify-center space-y-2 ${
                                expenseDetail?.id ? 'lg:col-span-3' : ''
                            }`}
                        >
                            <h2 className='text-[#838383]'>{expenseDetail?.name}</h2>
                            <p className='text-lg font-medium'>{expenseDetail?.desc}</p>
                        </div>
                    )
                })}
                {activeUrl && Number(formProps?.receiptUrls?.length) > 0 && (
                    <div className={`lg:row-start-1 lg:col-start-3 lg:row-end-4  col-span-2 `}>
                        <div className='  mb-4 lg:mb-0 border border-gray8 rounded-md p-0.5 w-fit ml-auto'>
                            {checkifReceiptIsPdf ? (
                                <PDFIcon className='max-w-[8rem] max-h-[8rem]' />
                            ) : (
                                <img
                                    src={activeUrl?.url}
                                    alt='Receipt'
                                    className='max-w-[8rem] max-h-[8rem] rounded-md'
                                />
                            )}
                        </div>
                        <div className=' flex lg:justify-end mb-4 lg:mb-0 gap-2'>
                            {receiptUrls?.map((url, i) => (
                                <button
                                    type='button'
                                    className={`text-primary-100 bg-primary-200  rounded mt-4 border   p-1 ${url === activeUrl ? 'border-brand border-2' : 'border-gray5'}`}
                                    onClick={() =>
                                        setActiveUrl(
                                            formProps?.receiptUrls?.[i] ?? {
                                                url: url?.url,
                                                name: undefined,
                                                size: undefined,
                                            }
                                        )
                                    }
                                >
                                    <>
                                        {url?.url?.includes('pdf') ? (
                                            <PDFIcon className='w-5 h-5' />
                                        ) : (
                                            <img src={url?.url} alt='Receipt' className='w-5 h-5 object-cover' />
                                        )}
                                    </>
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

import ProgressBar from '@/components/ProgressBar'
import BusinessDirectoryTable from '../components/BusinessDirectoryTable'
import { CiSearch } from 'react-icons/ci'
import React, { useEffect, useMemo, useState } from 'react'
import { useGetAllOrgs } from '@/services/imitating-users/queries'
import { ColumnSort, Pagination } from '@/types/utils'
import { debounce } from 'lodash'
import { SortColumn } from '@/utils/enum'
import { getSortString } from '@/components/Table/utils'

export default function OrgsInBusinessDirectory() {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNoClicked, setPageNoClicked] = useState(false)
    const [backspaceClicked, setBackSpaceClicked] = useState(false)

    const [searchQuery, setSearchQuery] = useState('')

    const [sort, setSort] = useState<SortColumn>()

    const handleSort = (sort: ColumnSort) => {
        setSort(sort)
    }

    const {
        data: all_orgs,
        isLoading,
        refetch,
        isRefetching,
    } = useGetAllOrgs({
        queryParams: {
            search: searchQuery,
            page: pageNumber.toString(),
            limit: '10',
            sort: getSortString(sort as ColumnSort),
        },
    })
    const allOrgs = useMemo(() => all_orgs?.results ?? [], [all_orgs])
    const pagination = useMemo(() => all_orgs?.pagination ?? ({} as Pagination), [all_orgs])
    useEffect(() => {
        if (pageNoClicked) {
            refetch()
            setPageNoClicked(false)
        }
        if (sort) {
            refetch()
        }
    }, [pageNoClicked, refetch, sort])

    const debouncedSearch = React.useRef(
        debounce(() => {
            refetch()
        }, 300)
    ).current

    useEffect(() => {
        if (backspaceClicked) {
            refetch()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, searchQuery, debouncedSearch, refetch])

    return (
        <section>
            <div className='my-7'>
                <div className=''>
                    <h1 className='text-xl font-semibold'>Manage businesses</h1>
                    <p className='text-base text-[#5E5E5E] font-medium mt-2'>
                        This is a list of all businesses that have registered under bizpend
                    </p>
                </div>
            </div>
            <div className='border border-[#DADCE0] h-[56px] w-auto lg:w-[35rem] rounded-lg flex items-center overflow-hidden'>
                <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                    <CiSearch fontSize={25} />
                </div>
                <input
                    type='text'
                    placeholder='Search by Org name'
                    className='flex-1 outline-none bg-[#FAFAFD]'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
            </div>
            <div className='mt-5 pb-32'>
                {isLoading ? (
                    <ProgressBar />
                ) : (
                    <div className={`${isRefetching ? 'opacity-40' : ''} overflow-x-scroll`}>
                        <BusinessDirectoryTable
                            orgs={allOrgs}
                            handleSort={handleSort}
                            pagination={pagination}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            setPageNoClicked={setPageNoClicked}
                        />
                    </div>
                )}
            </div>
        </section>
    )
}

import { DeleteIcon } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { defaultAdditionProps } from '@/types/payroll'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { PercentIcon, PlusSquare } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { TbCurrencyNaira } from 'react-icons/tb'
import CustomReasonModal from './CustomReasonModal'

export default function BonusesAndDeductions({
    fieldDetail,
    setFieldDetails,
    net_salary,
    gross_salary,
    defaultBonusDeduc,
}: {
    fieldDetail: defaultAdditionProps
    setFieldDetails: React.Dispatch<React.SetStateAction<defaultAdditionProps[]>>
    name: string
    net_salary: number
    gross_salary: string
    defaultBonusDeduc?: defaultAdditionProps[]
}) {
    const [fieldAmount, setFieldAmount] = useState(0)
    const [openCustomReasonModal, setOpennCustomReason] = useState(false)
    const [openSelectContainer, setOpenSelectContainer] = useState(false)

    useEffect(() => {
        setFieldAmount(Number(fieldDetail?.amount))
    }, [fieldDetail?.amount, gross_salary])

    const handleSelectDefaultReason = (reason: string) => {
        const defaultReason = defaultBonusDeduc?.find(type => type.name === reason)
        if (defaultReason) {
            setFieldDetails(field => {
                return field.map(data => {
                    if (data.id === fieldDetail?.id) {
                        data.type = defaultReason.type as any
                        data.amount = Number(defaultReason.amount)
                        data.reccuring = defaultReason.reccuring
                        return data
                    }
                    return data
                })
            })
        }
    }

    return (
        <section className='py-2 px-4 rounded-lg'>
            <div className=' flex flex-col lg:flex-row gap-2 lg:gap-5 lg:items-end'>
                <div className='flex flex-col flex-1 gap-3'>
                    <div className='flex border border-[#DADCE0] w-full h-[3rem] rounded-lg overflow-hidden bg-white'>
                        <Select
                            name='money'
                            value={fieldDetail?.type}
                            onValueChange={e => {
                                setFieldDetails(field => {
                                    return field.map(data => {
                                        if (data.id === fieldDetail?.id) {
                                            data.type = e as any
                                            return data
                                        }
                                        return data
                                    })
                                })
                            }}
                        >
                            <SelectTrigger className='bg-[#F9F9F9] text-[10px] border-none w-[3rem] px-2 py-2 justify-between h-max flex self-center mx-1 pl-1 placeholder:text-sm '>
                                <SelectValue placeholder={'reason'} />
                            </SelectTrigger>

                            <SelectContent className='w-[8rem]'>
                                <SelectItem value='amount'>
                                    {' '}
                                    <span className='flex items-center justify-center text-[#838383] ml-2'>
                                        <TbCurrencyNaira fontSize={20} />
                                    </span>
                                </SelectItem>
                                <SelectItem value='percentage'>
                                    {' '}
                                    <span className='flex items-center justify-center text-[#838383] ml-2'>
                                        <PercentIcon fontSize={20} />
                                    </span>
                                </SelectItem>
                            </SelectContent>
                        </Select>

                        <input
                            name={`extra-${fieldDetail?.name}`}
                            type='text'
                            placeholder={`Enter ${fieldDetail?.type === 'amount' ? 'Amount' : 'Value'}`}
                            className='outline-none w-[90%] h-full ml-1 placeholder:text-sm '
                            required
                            value={formatAmount(fieldDetail.amount).replaceAll('₦', '')}
                            onChange={e => {
                                const value_entered = formatAmountWithoutSymbols(e.target.value)

                                if (fieldDetail?.type === 'percentage' && Number(value_entered) > 10000) return
                                if (Number(fieldAmount) > Number(net_salary) && Number(net_salary) < 0) {
                                    toastHandler({ message: 'Net salary cannot be a negative value', state: 'error' })
                                }
                                setFieldDetails(field => {
                                    return field.map(data => {
                                        if (data.id === fieldDetail?.id) {
                                            data.amount = Number(value_entered)
                                            return data
                                        }
                                        return data
                                    })
                                })
                            }}
                        />

                        <div className='relative'>
                            <Select
                                name='payment-freq'
                                value={
                                    fieldDetail.reccuring !== undefined
                                        ? fieldDetail.reccuring
                                            ? 'recurring'
                                            : 'one_time'
                                        : ''
                                }
                                onValueChange={e => {
                                    setFieldDetails(field => {
                                        return field.map(data => {
                                            if (data.id === fieldDetail?.id) {
                                                data.reccuring = e === 'recurring'
                                                return data
                                            }
                                            return data
                                        })
                                    })
                                }}
                            >
                                <SelectTrigger className='bg-gray6/60 text-[10px] font-medium gap-1 whitespace-nowrap border-none w-fit px-2 py-2 justify-between h-max flex self-center mt-2 placeholder:text-sm '>
                                    {fieldDetail.reccuring !== undefined ? (
                                        <SelectValue placeholder={'select option'} />
                                    ) : (
                                        <p className='text-[10px]'>Select Option</p>
                                    )}
                                </SelectTrigger>

                                <SelectContent className='w-[8rem]' sideOffset={-55}>
                                    <SelectItem value='recurring'>Recurring</SelectItem>
                                    <SelectItem value='one_time'>One time</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='flex gap-2 lg:gap-5'>
                    <div className='flex flex-col flex-1 gap-3 text-sm'>
                        <Select
                            name='reason'
                            value={fieldDetail?.name?.toLowerCase()}
                            onValueChange={e => {
                                setFieldDetails(field => {
                                    return field.map(data => {
                                        if (data.id === fieldDetail?.id) {
                                            handleSelectDefaultReason(e)
                                            data.name = e
                                            data.reason = capitalizeText(e) ?? ''
                                            return data
                                        }
                                        return data
                                    })
                                })
                            }}
                            onOpenChange={setOpenSelectContainer}
                            open={openSelectContainer}
                        >
                            <SelectTrigger className='xl:min-w-[18.1rem] h-[3rem] border-[#DADCE0] bg-[white] text-[13px]'>
                                {(fieldDetail?.name as keyof typeof fieldDetail) ? (
                                    <SelectValue />
                                ) : (
                                    <span>Select reason</span>
                                )}
                            </SelectTrigger>
                            <SelectContent className='w-[18.9rem] max-h-[200px] overflow-scroll pb-2'>
                                {
                                    <button
                                        className='flex items-center gap-1 pl-2 py-2 hover:bg-[#EFEFF9] w-full'
                                        onClick={() => {
                                            setOpenSelectContainer(false)
                                            setOpennCustomReason(true)
                                        }}
                                    >
                                        <PlusSquare size={15} /> Add new reason
                                    </button>
                                }

                                <>
                                    {defaultBonusDeduc &&
                                        defaultBonusDeduc?.map(type => (
                                            <SelectItem value={type?.name?.toLowerCase()} key={type?.id}>
                                                {capitalizeText(type.name)}
                                            </SelectItem>
                                        ))}
                                </>
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <DeleteIcon
                            className='bg-[#FFEDEC] rounded-md scale-125 relative -bottom-1.5 lg:-bottom-1 cursor-pointer p-1'
                            onClick={() => {
                                setFieldDetails(field => {
                                    return field.filter(data => data.id !== fieldDetail?.id)
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            {openCustomReasonModal && (
                <CustomReasonModal closeModal={setOpennCustomReason} type={fieldDetail.field_type} />
            )}
        </section>
    )
}

import { ProfileEditIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import DeeleteUploadedCOAPrompt from '@/components/Modals/Accounting/DeleteCOAPrompt/DeleteUploadedCOAPrompt'
import { Trash2 } from 'lucide-react'
import { useState } from 'react'

export default function ManageGLCode() {
    const [removeCOA, setRemoveCOA] = useState(false)

    return (
        <section className='mb-5  flex-1'>
            <>
                <section className=' bg-white rounded-xl p-7'>
                    <h2 className='font-medium text-md border-b border-gray5 pb-2 mb-5'>Integration / Imported file</h2>

                    <div className='flex justify-between items-center p-4 border border-gray5 rounded-lg'>
                        <div>
                            <span className='text-sm font-medium block'>Kuda_MFB_Sage_GL codes.xls</span>
                            <span className='text-gray7 text-xs block font-medium mt-1'>240.77kb</span>
                        </div>
                        <div className='flex items-center gap-4'>
                            <button
                                className='flex flex-col items-center justify-center text-errorRed'
                                onClick={() => setRemoveCOA(true)}
                            >
                                <Trash2 width={17} strokeWidth={1.5} />
                                <span className='text-[.7rem] font-medium'>Delete</span>
                            </button>
                            <button className='flex flex-col items-center justify-center'>
                                <ProfileEditIcon width={17} strokeWidth={1.5} />
                                <span className='text-[.7rem] font-medium text-[#555555]'>Change</span>
                            </button>
                        </div>
                    </div>

                    {/* <div className='mt-8'>
                        <h2 className='font-medium text-md border-b border-gray5 pb-2'>Chart of accounts details</h2>

                        <fieldset className='border border-gray5 rounded-lg p-4 mt-5 flex flex-col gap-2'>
                            <label htmlFor='account_name' className='text-sm text-gray7'>
                                Chart of accounts name
                            </label>
                            <Input
                                id='account_name'
                                placeholder='Enter chart of accounts name'
                                className='max-w-xs h-12 border-gray5'
                            />
                        </fieldset>
                    </div> */}

                    <div className='mt-12 flex gap-3 justify-end'>
                        <Button variant='outline' size={'lg'}>
                            Cancel
                        </Button>
                        <Button size={'lg'}>Save changes</Button>
                    </div>
                </section>

                {removeCOA && <DeeleteUploadedCOAPrompt closeModal={() => setRemoveCOA(false)} />}
            </>
        </section>
    )
}

import { RefObject, useEffect, useState } from 'react'

export default function useClickOutside(ref: RefObject<HTMLDivElement>, closeModal?: () => void) {
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setClicked(true)
                closeModal?.()
                return
            }

            setClicked(false)
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, closeModal])

    return clicked
}

import { UserAccount } from '@/services/payout/types'
import { BusinessPlanBilling, OrgSubscription, UsageRecordType } from './billing'
import { RoleType } from './roles'
import { User } from './user'

export enum RoleEnum {
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    USER = 'USER',
    CONTRACTOR = 'CONTRACTOR',
    FINANCE_ADMIN = 'FINANCE_ADMIN',
}

export type OrgType = {
    _id: string
    email: string
    org_name: string
    org_type: string
    createdAt: string
    updatedAt: string
    id: string
    website: string
    address: string
    state: string
    country: string
    postal_code: string
    tax_id: string
    phone: string
    company_size: string
    company_website: string
    industry: string
    address_line_1: string
    employees: number
    street: string
    kyb_completed: boolean
    description: string
    city: string
    business_type: string
    rc_number: string
    logo: string
    mono_customer_id: string

    business_plan: BusinessPlanBilling & {
        amount: number
        next_renewal_date: Date
    }
    usage_record: UsageRecordType
    subscription: OrgSubscription
    anchor_customer_id?: string
    accounting: boolean
}

export type UserOrgAccount = {
    email: string
    org_name: string
    user: User
    org: OrgType
    id: string
    _id: string
    role: RoleType
    employee_id?: string
}

export type EmployeeType = {
    bank: boolean
    bankAccounts: UserAccount[]
    checked?: boolean | undefined
    createdAt: string
    id: string
    _id: string
    invite_date: string
    org: OrgType
    role: RoleType
    user: User
    status: 'ACTIVE' | 'INACTIVE' | 'SUSPENDED' | 'DEACTIVATED'
    updatedAt: string
    email?: string
    employee_id: string
    teams: { name: string; org: string }[]
    team?: string
    account?: string
}
export type TeamType = {
    name: string
    createdAt: string
    id: string
    members: string[]
    org: string
    team_lead: User
    updatedAt: string
}

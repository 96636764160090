import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import ProgressBar from './components/ProgressBar'
import AuthContextProvider from './context/auth'
import { ImpersonationContextProvider } from './context/impersonation'
import RolesContextProvider from './context/roles'
import ErrorFallBack from './layout/ErrorFallBack'
import AppRoutes from './routes/Routes'
import InvoiceItemsContextProvider from './context/invoice-items'
import CustomersContextProvider from './context/customers'

function App() {
    const toggleTestMode = localStorage.getItem('testMode') === 'true'

    return (
        <Suspense fallback={<ProgressBar />}>
            <Sentry.ErrorBoundary
                fallback={() => {
                    return <ErrorFallBack />
                }}
            >
                <main className={`${toggleTestMode ? 'border-red-600 border-4' : ''} `}>
                    {toggleTestMode && (
                        <span className='text-white bg-red-600 fixed font-medium -rotate-90 right-0 top-1/2 -translate-y-1/2 translate-x-1/3 p-2 rounded-t '>
                            TEST MODE
                        </span>
                    )}
                    <AuthContextProvider>
                        <RolesContextProvider>
                            <CustomersContextProvider>
                                <InvoiceItemsContextProvider>
                                    <ImpersonationContextProvider>
                                        <AppRoutes />
                                        <Toaster
                                            toastOptions={{
                                                success: {
                                                    style: {
                                                        background: 'green',
                                                        color: 'white',
                                                    },
                                                },
                                                error: {
                                                    style: {
                                                        background: 'red',
                                                        color: 'white',
                                                    },
                                                },
                                                position: 'top-right',
                                                style: {
                                                    minWidth: '300px',
                                                },
                                            }}
                                        />
                                    </ImpersonationContextProvider>
                                </InvoiceItemsContextProvider>
                            </CustomersContextProvider>
                        </RolesContextProvider>
                    </AuthContextProvider>
                </main>
            </Sentry.ErrorBoundary>
        </Suspense>
    )
}

export default App

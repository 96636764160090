import { Button } from '@/components'
import { useAuthContext, useExpenseContext } from '@/context'
import { ExpenseType } from '@/types/expense'
import { ExpenseStatus } from '../const'
import { useState } from 'react'
import { toastHandler } from '@/components/utils/Toast'
import ReimburseExpenseModal from '@/components/Modals/Expense/ReimburseExpense/ReimburseExpenseMondal'
import { UserAccount } from '@/services/payout/types'
import { useMutateSubmitDraftExpense, useMutationAuthorizeExpense } from '@/services/expenses/mutations'
import { IsOwner } from '@/utils/permissions'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import { expenseSidebarPermissions } from '@/layout/LayoutComponents/utils'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import DeclineExpenseReasonModal from '@/components/Modals/Expense/DeclineExpense'
import { Menu } from '@headlessui/react'
import { PenLine } from 'lucide-react'
import { ArrowLeftRight } from 'lucide-react'
import { Link2 } from 'lucide-react'
import { FaEllipsisVertical } from 'react-icons/fa6'
import MatchExpenseToAccountModal from '@/components/Modals/Expense/MatchExpenseToAccountModal'
import { prodEnvironment } from '@/utils/utils'

type Props = {
    expense: ExpenseType
    hasAPrimaryBank: () => boolean
    submitterAccount: UserAccount[]
    setExpenseDetails: React.Dispatch<React.SetStateAction<ExpenseType>>
    isRefetchingExpense: boolean
    setLinkBudgetModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExpenseAuthorizePermission({
    expense,
    hasAPrimaryBank,
    submitterAccount,
    setExpenseDetails,
    isRefetchingExpense,
    setLinkBudgetModal,
}: Props) {
    const [reimburseModal, setReimburseModal] = useState(false)

    const [declineExpenseModal, setDeclineExpenseModal] = useState(false)
    const [matchAccount, setMatchAccount] = useState(false)
    const { editModal, setEditModal, categories } = useExpenseContext()
    const hasPermission = useHasAccessPermisson(expenseSidebarPermissions)

    const { mutate: submitDraftExpense, isLoading: isSubmitting } = useMutateSubmitDraftExpense({
        queryParams: { id: expense?.id as string },
        onSuccess: data => {
            toastHandler({
                message: 'Draft submitted',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })
    const { mutate: authorizeExpense, isLoading: isAuthorizationLoading } = useMutationAuthorizeExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })

    const handleAccountCheck = () => {
        if (hasAPrimaryBank()) {
            setReimburseModal(true)
            return
        }
        toastHandler({ message: 'User does not have any bank as primary', state: 'error' })
    }

    const { selectedAccount } = useAuthContext()

    const handleAuthorizeExpense = (status: string) => {
        authorizeExpense(
            { approve: status === ExpenseStatus.approved },
            {
                onSuccess: () => {
                    toastHandler({
                        message: status === ExpenseStatus.approved ? 'Expense Approved' : 'Expense Declined',
                        state: 'success',
                    })
                },
            }
        )
    }
    const isApprovedByMe = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && approver?.approved
    )

    const canApproveExpense = expense?.approvers?.some(user => user?.account?.id === selectedAccount?.id)
    if (!canApproveExpense) return null
    return (
        <>
            <div className='flex flex-col lg:flex-row flex-1'>
                {expense?.status === ExpenseStatus.draft && (
                    <Button
                        loading={isSubmitting}
                        disabled={isAuthorizationLoading || isRefetchingExpense}
                        onClick={() => submitDraftExpense({})}
                        className='bg-brand  min-w-[130px] min-h-[45px]'
                    >
                        Submit Expense
                    </Button>
                )}
                {expense?.status !== ExpenseStatus.draft &&
                    hasPermission &&
                    (expense?.reimbursable &&
                    (expense?.status === ExpenseStatus.approved || expense?.status === ExpenseStatus.processing) &&
                    isApprovedByMe ? (
                        <div className='flex justify-center flex-1  '>
                            <Button
                                onClick={handleAccountCheck}
                                className='min-w-fit px-6 py-2 min-h-fit bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white'
                                disabled={expense?.status === ExpenseStatus.processing || isRefetchingExpense}
                            >
                                {expense?.status === ExpenseStatus.processing ? 'Processing' : 'Reimburse'} expense
                            </Button>
                        </div>
                    ) : (expense?.status === ExpenseStatus.pending ||
                          expense?.status === ExpenseStatus.partialApproval) &&
                      !isApprovedByMe ? (
                        <div className='flex flex-1 flex-col lg:flex-row gap-8 justify-between'>
                            <div className='flex gap-3 justify-end flex-1'>
                                <Button
                                    className='min-w-fit rounded-lg min-h-fit whitespace-nowrap py-2 px-6'
                                    variant={'secondaryOutline'}
                                    onClick={() => {
                                        setDeclineExpenseModal(true)
                                    }}
                                    disabled={isAuthorizationLoading || isRefetchingExpense}
                                >
                                    Decline
                                </Button>

                                <Button
                                    loading={isAuthorizationLoading}
                                    onClick={() => handleAuthorizeExpense(ExpenseStatus.approved)}
                                    disabled={isAuthorizationLoading || isRefetchingExpense}
                                    className='disable:opacity-60 disabled:bg-[#19C37D]/30 bg-[#19C37D] hover:bg-[#479776] text-white rounded-md text-sm py-2.5 px-6 flex items-center gap-2 min-w-fit min-h-fit'
                                >
                                    Approve
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    ))}
                {reimburseModal && (
                    <ReimburseExpenseModal
                        setShowModal={setReimburseModal}
                        accounts={submitterAccount}
                        expense={expense}
                    />
                )}

                {editModal && (
                    <ExpenseFormRoot
                        setShowModal={setEditModal}
                        selectedExpense={expense!}
                        categories={categories}
                        hasAPrimaryBank={!hasAPrimaryBank()}
                        edit={true}
                    />
                )}

                {declineExpenseModal && (
                    <DeclineExpenseReasonModal
                        closeModal={() => setDeclineExpenseModal(false)}
                        expenseId={expense?.id}
                        setExpenseDetails={setExpenseDetails}
                    />
                )}
            </div>
            <p className='bg-gray8 w-[1px] h-7'>&nbsp;</p>
            <Menu as='div' className='relative'>
                <Menu.Button className='border border-gray8 p-1.5 rounded-lg'>
                    <FaEllipsisVertical color='#A8A8A8' size={24} />
                </Menu.Button>
                <Menu.Items className='w-32  flex flex-col gap-4 shadow-xl absolute right-0 p-4 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                    {canApproveExpense && (
                        <Menu.Item as={'div'} className={'cursor-pointer hover:underline'}>
                            <button
                                className='w-full disabled:opacity-30 flex gap-2 items-center'
                                onClick={() => setLinkBudgetModal(true)}
                                disabled={expense?.status === 'denied'}
                            >
                                <Link2 color='#5E5E5E' /> Link to a budget
                            </button>
                        </Menu.Item>
                    )}

                    {!prodEnvironment() && (
                        <Menu.Item
                            onClick={() => setMatchAccount(true)}
                            as={'div'}
                            className={'flex gap-2 items-center cursor-pointer hover:underline'}
                        >
                            <ArrowLeftRight color='#5E5E5E' /> Match to an account
                        </Menu.Item>
                    )}

                    <EditExpenseButtonAction
                        expense={expense}
                        setEditModal={setEditModal}
                        isRefetchingExpense={isAuthorizationLoading || isRefetchingExpense}
                    />
                </Menu.Items>
            </Menu>
            {matchAccount && <MatchExpenseToAccountModal closeModal={() => setMatchAccount(false)} expense={expense} />}
        </>
    )
}

export const EditExpenseButtonAction = ({
    expense,
    setEditModal,
    isRefetchingExpense,
}: {
    expense: ExpenseType
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>
    isRefetchingExpense: boolean
}) => {
    const { selectedAccount } = useAuthContext()

    const isExpenseCreator =
        (expense?.submitter?.id ?? expense?.submitter?._id) === (selectedAccount?.id ?? selectedAccount?._id)

    const isPartOfApprover = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && !approver?.approved
    )

    return (expense?.status === ExpenseStatus.pending || expense?.status === ExpenseStatus.draft) &&
        (isExpenseCreator || isPartOfApprover || IsOwner()) ? (
        <Menu.Item as={'div'} className={' cursor-pointer hover:underline text-sm text-[#5E5E5E] font-medium'}>
            <button
                className='w-full flex gap-2 items-center disabled:opacity-30'
                onClick={() => setEditModal(true)}
                disabled={isRefetchingExpense}
            >
                <PenLine color='#5E5E5E' size={20} /> Edit
            </button>
        </Menu.Item>
    ) : (
        <></>
    )
}

export const ProcessingBar = () => {
    return (
        <div className='border-t border-[#A8A8A8] w-[400px] h-[184px] flex gap-3 justify-center rotate-180'>
            <div
                className='w-16 h-[184px] bg-[#9FDA96] rounded-b-[1rem] animate-animate-up-down'
                style={{ animationDelay: '600ms' }}
            ></div>
            <div
                className='w-16 h-[184px] bg-[#FFAB90] rounded-b-[1rem] animate-animate-up-down'
                style={{ animationDelay: '400ms' }}
            ></div>
            <div
                className='w-16 h-[184px] bg-[#7E82FB] rounded-b-[1rem] animate-animate-up-down delay-[400ms]'
                style={{ animationDelay: '200ms' }}
            ></div>
            <div className='w-16 h-[184px] bg-[#F5D484] rounded-b-[1rem] animate-animate-up-down delay-[600ms]'></div>
        </div>
    )
}

import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'

import ModalWrapper from '../../ModalWrapper'
import { ChartOfAccountType } from '@/types/accounting'
import { CautionIcon } from '@/assets/assets.export'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { capitalizeText } from '@/utils/string'
import { useAccountingContext } from '@/context'

type Props = {
    closeModal: () => void
    selectedCoAs: ChartOfAccountType[]
}
type FormType = { replacementAccounts: { accountType: string; accountName: string }[] }

const DeleteMultipleCOA = ({ closeModal, selectedCoAs }: Props) => {
    const form = useForm<FormType>({
        defaultValues: {
            replacementAccounts: selectedCoAs?.map(coa => ({
                accountName: coa?.account_name,
                accountType: coa?.account_type?.id,
            })),
        },
    })

    const { chartOfAccountData } = useAccountingContext()

    const { fields } = useFieldArray<FormType>({
        control: form.control,
        name: 'replacementAccounts',
    })

    return (
        <ModalWrapper closeModal={closeModal}>
            <main className='max-h-[30rem] lg:h-auto w-screen lg:w-[30rem] overflow-scroll'>
                <div className='flex justify-center items-center mt-4'>
                    <span className='bg-errorRed/10 rounded-full w-16 h-16 p-2.5 inline-flex items-center justify-center'>
                        <CautionIcon className='w-full h-full' />
                    </span>
                </div>
                <section className='m-6'>
                    <h3 className='font-medium opacity-90'>Account deletion requires replacement</h3>

                    <p className='text-gray7 my-4 text-sm tracking-wide leading-6 font-medium'>
                        You are about to delete {selectedCoAs?.length} accounts. ___ of these accounts are linked to
                        transactions and require replacement before deletion.
                    </p>
                </section>

                <section className='mx-6 m-2 mb-24 space-y-3'>
                    {fields.map((field, idx) => (
                        <fieldset className='flex flex-col gap-1.5'>
                            <label className='text-sm' htmlFor='funding_method'>
                                {field.accountName}
                            </label>
                            <Controller
                                name={`replacementAccounts.${idx}.accountType`}
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={form.control}
                                render={({ field }) => (
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <SelectTrigger className=' text-sm  h-10  border-gray8 flex'>
                                            <SelectValue className='' placeholder={'Select account type'} />
                                        </SelectTrigger>

                                        <SelectContent
                                            position='popper'
                                            className='shadow-md bg-white rounded-md w-max px-2 text-sm text-[#202020] h-56'
                                        >
                                            {chartOfAccountData.map(coa => (
                                                <SelectItem
                                                    value={coa.id ?? coa?._id}
                                                    key={coa.id}
                                                    className='cursor-pointer flex item-center gap-2'
                                                >
                                                    {capitalizeText(coa?.account_name)}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </fieldset>
                    ))}
                </section>

                <div className='mt-5 border-t border-gray5 absolute bottom-0 w-full bg-white rounded-b-2xl'>
                    <div className='flex gap-5 justify-end mr-6 my-4'>
                        <Button
                            size={'lg'}
                            className='bg-transparent text-[#202020] hover:bg-transparent text-xs min-w-[120px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button size={'lg'} variant={'error'} className=' text-white text-xs min-w-[130px] '>
                            Yes, delete account
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default DeleteMultipleCOA

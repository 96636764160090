import { createMutation } from '../api/mutation'
import { chartOfAccountKey, customFields, glcodeKey } from './queries'

export const useUploadGlCode = createMutation({
    url: '/gl-code',
    method: 'POST',
    keysToRefetch: [glcodeKey],
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useUpdateGlCode = createMutation({
    url: '/gl-code',
    method: 'PATCH',
    keysToRefetch: [glcodeKey],
})

export const useDeleteGlCode = createMutation({
    url: '/gl-code',
    method: 'DELETE',
    keysToRefetch: [glcodeKey],
})

export const useUploadCOA = createMutation({
    url: '/accounting/account',
    method: 'POST',
    keysToRefetch: [chartOfAccountKey],
})

export const useCreateCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'POST',
    keysToRefetch: [customFields],
})

export const useDeleteCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'DELETE',
    keysToRefetch: [customFields],
})

export const useUpdateCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'PATCH',
    keysToRefetch: [customFields],
})

export const useCreateSingleCOA = createMutation({
    url: '/accounting/account',
    method: 'POST',
    keysToRefetch: [chartOfAccountKey],
})

export const useUpdateSingleCOA = createMutation({
    url: '/accounting/account',
    method: 'PATCH',
    keysToRefetch: [chartOfAccountKey],
})

export const useDeleteCOA = createMutation({
    url: '/accounting/account',
    method: 'DELETE',
    keysToRefetch: [chartOfAccountKey],
})

export const useExportAllCOA = createMutation({
    url: '/accounting/export-accounts',

    method: 'POST',
})

export const useExportCOAByPage = createMutation({
    url: '/accounting/export-current-page',
    method: 'POST',
})

import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { CustomBarTooltip2 as CustomBarTooltip } from '@/components/Tooltips/CustomTooltip'
import { AbbrvFormatNumber } from '@/utils/utils'
import { useCurrencyContext } from '@/context'
import { NewMonthlySummary } from '@/services/transactions/types'

type Props = {
    chartData: NewMonthlySummary[]
    currency: string
}
export const TransactionsBarChart = ({ chartData, currency }: Props) => {
    const { selectedCurrencyInfo } = useCurrencyContext()

    return (
        <ResponsiveContainer height={281} width={'100%'} className={'mx-auto'}>
            <BarChart data={chartData} margin={{ left: 40 }}>
                <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                <XAxis dataKey='name' stroke='#888888' fontSize={12} tickLine={false} axisLine={false} />
                <YAxis
                    // dataKey='total'
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={value => `${selectedCurrencyInfo(currency)?.symbol}${AbbrvFormatNumber(value)}`}
                    hide={false}
                    label={{
                        value: 'Naira',
                        angle: -90,
                        position: 'insideRight',
                        offset: 90,
                    }}
                    offset={10}
                    type='number'
                />
                <Bar dataKey='incomingTotal' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={11} />
                <Bar dataKey='outgoingTotal' fill='#D5D5F5' radius={[4, 4, 0, 0]} barSize={11} />
                <Tooltip
                    filterNull={true}
                    cursor={false}
                    wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                    content={<CustomBarTooltip />}
                />
                <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
            </BarChart>
        </ResponsiveContainer>
    )
}

import { Billing, BusinessPlanBilling, SubscriptionType, UsageRecordType } from '@/types/billing'
import { createQuery } from '../api/query'
import { directoryPlans, orgBusinessPlans, orgPlan, orgPlans, orgSubscription, orgusageRecord } from './mutations'
import { QueryOrgBillingResponse, QueryOrgBusinessPlanBillingResponse } from './type'

export const useGetOrgPlans = createQuery<QueryOrgBillingResponse>({
    url: '/subscriptions/user-plans',
    key: orgPlans,
})

export const useGetDirectoryPlans = createQuery<QueryOrgBillingResponse>({
    url: '/subscriptions/plans',
    key: directoryPlans,
})

export const useGetOrgPlan = createQuery<Billing>({
    url: '/subscriptions/plan',
    key: orgPlan,
})

export const useGetOrgBusinessPlans = createQuery<QueryOrgBusinessPlanBillingResponse>({
    url: '/subscriptions/business-plans',
    key: orgBusinessPlans,
})

export const useGetOrgBusinessPlan = createQuery<BusinessPlanBilling>({
    url: '/subscriptions/business-plan',
    key: orgBusinessPlans,
})

export const useGetOrgSuscription = createQuery<SubscriptionType>({
    url: '/subscriptions/subscription',
    key: orgSubscription,
})

export const useGetOrgUsageRecord = createQuery<UsageRecordType>({
    url: '/subscriptions/usage-record',
    key: orgusageRecord,
})

export const useGetModuleAvailable = createQuery({
    url: '/subscriptions/module-not-available',
    key: [],
})

export const useGetOrgTotalSubscription = createQuery<{
    totalAmount: number
}>({
    url: '/subscriptions/total-amount',
    key: orgSubscription,
})

import { useAuthContext } from '@/context'
import { PayrolFormReusable } from '@/modules/dashboard/Payroll/partials/Settings/partials/PayrollFormCustoms'
import { defaultAdditionProps } from '@/types/payroll'
import { PlusSquare } from 'lucide-react'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PayrollSettingsFullType } from './PayrollSettingsPrompt'

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>
    setFormState: React.Dispatch<React.SetStateAction<PayrollSettingsFullType>>
    formState: PayrollSettingsFullType
}
const defaultAdditions = {
    reason: '',
    amount: 0,
    type: '',
    id: uuidv4(),
    amountType: '',
}
type AmountType = 'percentage' | 'amount'

export const StepTwo = ({ setStep, setFormState, formState }: Props) => {
    const [deductions, setDeductions] = useState<Partial<defaultAdditionProps>[]>(
        formState.default_deductions.length < 1
            ? [{ ...defaultAdditions, id: uuidv4(), type: 'amount', field_type: 'deduction' }]
            : formState.default_deductions.map(deduction => ({
                  ...deduction,
                  id: uuidv4(),
                  field_type: 'deduction',
                  amount: deduction.type === 'amount' ? deduction.amount * 100 : deduction.amount,
              }))
    )
    const [bonuses, setBonuses] = useState<Partial<defaultAdditionProps>[]>(
        formState.default_bonuses.length < 1
            ? [{ ...defaultAdditions, id: uuidv4(), type: 'amount', field_type: 'bonus' }]
            : formState.default_bonuses.map(bonus => ({
                  ...bonus,
                  id: uuidv4(),
                  field_type: 'bonus',
                  amount: bonus.type === 'amount' ? bonus.amount * 100 : bonus.amount,
              }))
    )
    const { selectedAccount } = useAuthContext()

    const handleClick = () => {
        const default_deductions = deductions.map(data => ({
            ...data,
            name: data?.name as string,
            type: data.type as AmountType,
            amount: (data.type === 'amount' ? Number(data?.amount) / 100 : data.amount) as number,
            org: selectedAccount?.org.id as string,
        }))

        const default_bonuses = bonuses.map(data => ({
            ...data,
            name: data?.name as string,
            type: data.type as AmountType,
            amount: (data.type === 'amount' ? Number(data?.amount) / 100 : data.amount) as number,
            org: selectedAccount?.org.id as string,
        }))

        setFormState(prev => ({ ...prev, default_deductions: default_deductions, default_bonuses: default_bonuses }))
    }
    // const disableButton = () => {
    //     const res = Object.values({ ...deductions, ...bonuses })
    //         .map(value => !!value)
    //         .some(data => data === false)
    //     console.warn(res)
    //     return false
    // }
    const isDisabled = ![...deductions, ...bonuses]?.every(item => item?.name && item?.name?.trim() !== '')

    return (
        <div className='mt-5'>
            <div className='h-[71vh] overflow-scroll'>
                <div className='mb-8'>
                    <h2 className='border-b border-[#DADCE0] py-3 mb-4'>Bonus types</h2>
                    <div className='grid grid-cols-1 gap-x-20 gap-y-4 items-center'>
                        {bonuses?.map((bonus, idx) => (
                            <>
                                <div className='flex items-center gap-4 '>
                                    <p>{idx + 1}.</p>
                                    <PayrolFormReusable
                                        fieldDetail={bonus}
                                        setFieldDetails={setBonuses}
                                        key={bonus.id}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                    <button
                        className='flex items-center gap-2 text-sm text-[#454ADE] bg-[#EFEFF9] rounded-md p-2 mt-6'
                        onClick={() =>
                            setBonuses([
                                ...bonuses,
                                {
                                    ...defaultAdditions,
                                    id: uuidv4(),
                                    type: 'amount',
                                    field_type: 'bonus',
                                },
                            ])
                        }
                    >
                        <PlusSquare size={15} color='#454ADE' />
                        New bonus type
                    </button>
                </div>
                <div className='mb-4'>
                    <h2 className='border-b border-[#DADCE0] py-3 mb-4'>Deduction types</h2>
                    <div className='grid grid-cols-1 gap-x-20 gap-y-4 items-center'>
                        {deductions?.map((deduction, idx) => (
                            <>
                                <div className='flex items-center gap-4 '>
                                    <p>{idx + 1}.</p>
                                    <PayrolFormReusable
                                        fieldDetail={deduction}
                                        setFieldDetails={setDeductions}
                                        key={deduction.id}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                    <button
                        className='flex items-center gap-2 text-sm text-[#454ADE] bg-[#EFEFF9] rounded-md p-2 mt-6'
                        onClick={() =>
                            setDeductions([
                                ...deductions,
                                {
                                    ...defaultAdditions,
                                    id: uuidv4(),
                                    type: 'amount',
                                    field_type: 'deduction',
                                },
                            ])
                        }
                    >
                        <PlusSquare size={15} color='#454ADE' />
                        New deduction type
                    </button>
                </div>
            </div>
            <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 py-2 rounded-b-2xl border-t border-[#DADCE0]'>
                <button
                    className='bg-white text-black px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    onClick={() => {
                        handleClick()
                        setStep(1)
                    }}
                >
                    back
                </button>
                <button
                    className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px] disabled:opacity-30'
                    disabled={isDisabled}
                    onClick={() => {
                        handleClick()
                        setStep(3)
                    }}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

export type signedUrlData = {
    fileName: string
    signedUrl: string
    path: string
}

export type signedUrlContext = {
    signedUrl: string
    file: File
    id: string
    path: string
    parent: string
    vault: string
    category: string
}
export type VaultListData = {
    name: string
    owner: string
    org: string
    is_private: boolean
    createdAt: string
    updatedAt: string
    id: string
    is_password_set: boolean
}

export type VaultItem = {
    name: string
    type: string
    vault: string
    parent: string
    owner: string
    org: string
    url: string
    createdAt: string
    updatedAt: string
    id: string
    visibility: VaultItemVisibility
    deleted: boolean
    category: string
}

export type VaultCategory = {
    name: string
    org: string
    owner: string
    createdAt: string
    updatedAt: string
    id: string
}

export enum VaultItemVisibility {
    PERSONAL = 'personal',
    SHARED = 'shared',
    GENERAL = 'general',
}

export type CreateVaultType = {
    name: string
    owner: string
    org: string
    is_private: boolean
    createdAt: string
    updatedAt: string
    id: string
}

export type VaultStorageDetails = {
    usedStorageSize: {
        categories: {
            _id: string
            categoryName: string
            totalSize: number
        }[]
        overallStorageSize: number
    }
    remainingStorageSize: number
}

import { useEffect, useMemo, useState } from 'react'
import OrgPayHistoryTable from '../../components/OrgPayHistoryTable'
import { useGetorgPayrollHistory } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'
import { CiSearch } from 'react-icons/ci'
import filter from '@/assets/filter.svg'
import downArrow from '@/assets/down-arrow.svg'
import { prodEnvironment } from '@/utils/utils'
import { Pagination } from '@/types/utils'

export default function Payhistory() {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNoClicked, setPageNoClicked] = useState(false)

    const { selectedAccount } = useAuthContext()
    const { data, isLoading, isRefetching, refetch } = useGetorgPayrollHistory({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
            page: pageNumber.toString(),
            limit: '10',
        },
    })

    useEffect(() => {
        if (pageNoClicked) {
            refetch()
            setPageNoClicked(false)
        }
    }, [pageNoClicked])

    const orgPayrollHistory = useMemo(() => data?.results ?? [], [data])
    const pagination = useMemo(() => data?.pagination ?? ({} as Pagination), [data])

    if (isLoading) return <ProgressBar />
    return (
        <section>
            <section className='mt-6'>
                <div className='my-4'>
                    <h1 className='text-dark font-medium text-lg'>Pay history</h1>
                    <p className='text-gray7 text-sm'>See history of paid salaries</p>
                </div>
                {orgPayrollHistory?.length > 0 ? (
                    <div className={`p-6 bg-white rounded-[20px]`}>
                        <div className='flex items-center justify-between mb-5'>
                            <div className='border border-[#DADCE0] h-[45px] w-[65%] lg:w-[30%] rounded-lg flex items-center overflow-hidden'>
                                <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                                    <CiSearch fontSize={25} />
                                </div>
                                <input
                                    type='text'
                                    placeholder='Search by date, linked budget'
                                    className='h-full w-full lg:w-[60%] outline-none placeholder:text-xs pr-2'
                                />
                            </div>
                            {!prodEnvironment() && (
                                <div className='flex items-center gap-2'>
                                    <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'>
                                        <img src={filter} alt='button' />
                                    </div>
                                    <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-1.5 cursor-pointer'>
                                        <img src={downArrow} alt='button' />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${isRefetching ? 'opacity-30' : ''}  mt-5`}>
                            <OrgPayHistoryTable
                                payHistory={orgPayrollHistory}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                pagination={pagination}
                                setPageNoClicked={setPageNoClicked}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='h-450px] flex flex-col text-center justify-center items-center'>
                        <h3 className='text-2xl font-bold text-[#5E5E5E] mt-14'>No Payment made</h3>
                        <span className='text-[#202020] text-[14px] w-[45%] mx-auto mt-3 font-normal'>
                            Monitor payment that have being paid to your users
                        </span>
                        <img
                            src={PayHistoryEmptyState}
                            alt='Payroll EMpty state'
                            className='mt-6 h-[200px] aspect-square'
                        />
                    </div>
                )}
            </section>
        </section>
    )
}

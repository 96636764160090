import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { ChartOfAccountType } from '@/types/accounting'
import { Pagination } from '@/types/utils'
import { useEffect, useState } from 'react'
import DeleteCOANoTransaction from '@/components/Modals/Accounting/DeleteCOAPrompt/DeleteCOANoTransaction'
import AddNewAccountModal from '@/components/Modals/Accounts/AddNewAccountModal'
import CheckBox from '@/components/Table/CheckBox'
import { Lock } from 'lucide-react'
import { DeleteIcon } from '@/assets/assets.export'
import DeleteMultipleCOA from '@/components/Modals/Accounting/DeleteCOAPrompt/DeleteMultipleCOA'

type Props = {
    coa: ChartOfAccountType[]
    pagination: Pagination
}
export default function ChartOfAccountTable({ coa }: Props) {
    const columnHelper = createColumnHelper<ChartOfAccountType>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const [openEditModal, setOpenEditeModal] = useState(false)
    const [selectedCOA, setSelectedCOA] = useState<ChartOfAccountType[]>([])
    const [singleSelectedCOA, setSingleSelectedCOA] = useState<ChartOfAccountType>()

    const [rowSelection, setRowSelection] = useState({})

    useEffect(() => {
        if (Object.keys(rowSelection).length) {
            setSelectedCOA(
                coa.filter((_, index) => {
                    return rowSelection[index as keyof typeof rowSelection]
                })
            )
        }
    }, [rowSelection, coa, setSelectedCOA])

    const toggleCOA = (coa: ChartOfAccountType) => {
        const rowId = coa._id ?? coa.id
        const isSelected = selectedCOA.some(coa => (coa._id ?? coa.id) === rowId)

        if (isSelected) {
            setSelectedCOA(selectedCOA.filter(allCoa => (allCoa._id ?? allCoa.id) !== rowId))
        } else {
            setSelectedCOA([...selectedCOA, coa])
        }
    }

    useEffect(() => {
        if (Object.keys(rowSelection).length) {
            setSelectedCOA(
                coa.filter((_, index) => {
                    return rowSelection[index as keyof typeof rowSelection]
                })
            )
        }
    }, [rowSelection, coa, setSelectedCOA])

    const columns: ColumnDef<ChartOfAccountType, any>[] = [
        columnHelper.display({
            id: 'select',
            header: () => {
                const unlockedCOA = coa.filter(data => data?.is_mutable)

                const allSelected = unlockedCOA.every(data =>
                    selectedCOA.some(activeCoa => (activeCoa._id ?? activeCoa?.id) === (data._id ?? data?.id))
                )
                const indeterminate =
                    unlockedCOA.some(data =>
                        selectedCOA.some(activeCoa => (activeCoa._id ?? activeCoa?.id) === (data._id ?? data?.id))
                    ) && !allSelected

                return (
                    <CheckBox
                        checked={allSelected}
                        indeterminate={indeterminate}
                        onChange={() => setSelectedCOA(allSelected ? [] : unlockedCOA)}
                    />
                )
            },
            cell: ({ row }) => {
                const rowId = row.original._id ?? row.original.id
                const isChecked = selectedCOA.some(profile => (profile._id ?? profile?.id) === rowId)

                return row?.original?.is_mutable ? (
                    <CheckBox
                        checked={isChecked}
                        disabled={!row.getCanSelect()}
                        onChange={() => toggleCOA(row.original)}
                    />
                ) : (
                    <Lock className='text-gray7 w-4 h-4 cursor-not-allowed' />
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('account_name', {
            header: () => <p>Account Name</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('code', {
            header: () => <p>ID</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('account_type.name', {
            header: () => <p>Sub Account type</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('description', {
            header: () => <p>Description</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),

        columnHelper.display({
            id: 'actions',
            cell: cell => {
                const hideActions = !cell.row.original?.is_mutable

                if (hideActions) return null
                return (
                    <TableActions
                        rotate
                        actions={[
                            {
                                label: 'Edit Account',
                                method: () => {
                                    setOpenEditeModal(true)
                                    setSingleSelectedCOA(cell.row.original)
                                },
                            },
                            {
                                label: 'Delete Account',

                                method: () => {
                                    setOpenDeleteModal(true)
                                    setSingleSelectedCOA(cell.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]
    return (
        <div className='rounded-lg'>
            {selectedCOA?.length > 0 && (
                <section className='flex justify-between items-center my-2'>
                    <div className='flex items-center gap-6'>
                        <span className='text-xs text-gray7'>{selectedCOA?.length} Accounts are selected</span>
                        <button
                            onClick={() => setSelectedCOA([])}
                            aria-label='Clear selection'
                            className='text-xs font-medium text-brand'
                        >
                            Clear selection
                        </button>
                    </div>
                    <div>
                        <button
                            aria-label='Delete'
                            onClick={() => setOpenDeleteModal(true)}
                            className='flex items-center gap-1 text-xs font-medium text-errorRed'
                        >
                            <DeleteIcon className='w-4 h-4' />
                            Delete
                        </button>
                    </div>
                </section>
            )}
            <Table
                data={coa}
                columns={columns}
                rowClickable={true}
                emptyStateTitle='No Chart of Account'
                emptyStateImg={TransactionEmptyStateIcon}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
            />

            {openDeleteModal && selectedCOA?.length < 2 && (
                <DeleteCOANoTransaction selectedCoa={singleSelectedCOA} closeModal={() => setOpenDeleteModal(false)} />
            )}
            {openEditModal && (
                <AddNewAccountModal
                    selectedCoa={singleSelectedCOA}
                    closeModal={() => setOpenEditeModal(false)}
                    isEdit
                />
            )}

            {openDeleteModal && selectedCOA?.length > 1 && (
                <DeleteMultipleCOA closeModal={() => setOpenDeleteModal(false)} selectedCoAs={selectedCOA} />
            )}
        </div>
    )
}

import { PDFIcon } from '@/assets/assets.export'
import emptyTransaction from '@/assets/empty-state-transactions.svg'
import downArrow from '@/assets/whiteDownload.svg'
import { Button } from '@/components'
import { useAuthContext, useExpenseContext } from '@/context'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import EmptyStates from '@/components/EmptyStates'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import LinkExpenseToBudget from '@/components/Modals/Expense/CreateExpense/LinkExpenseToBudget'
import ResolveAIGeneratedExpense from '@/components/Modals/Expense/ResolveAIGeneratedExpense'
import ResolveAIExpensePrompt from '@/components/Modals/Expense/ResolveAIGeneratedExpense/ResolveAIExpensePrompt'
import ProgressBar from '@/components/ProgressBar'
import { toastHandler } from '@/components/utils/Toast'
import { ExpenseStatus, expenseStatus } from '@/modules/dashboard/Expenses/const'
import { useGetOrgCategories } from '@/services/categories/queries'
import { useScanExpenseReceipt } from '@/services/expenses/mutations'
import { useGetExpenseById } from '@/services/expenses/queries'
import { useGetAccountsForUser } from '@/services/payout/queries'
import { ExpenseType } from '@/types/expense'
import { formatAmount, formatAmountNoSymbol } from '@/utils/money'
import { motion } from 'framer-motion'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FILE_TYPE } from '../utils/enums'
import { handleShowImage } from '../utils/utils'
import ExpenseDetailsTimeLine from './ExpenseDetailsTimeLine'
import LinkedExpenseTransaction from './LinkedExpenseTransaction'
import ExpenseAuthorizePermission from './ExpenseAuthorizePermission'
import ExpenseStatusBadge from './ExpenseStatus'
import OwnerAuthorizeExpensePermission from './OwnerAuthorizeExpensePermission'
import { IsOwner } from '@/utils/permissions'
import { ScanLine } from 'lucide-react'

const ExpenseDetailsPage = () => {
    const { expense_id } = useParams()
    const { selectedAccount } = useAuthContext()

    const [isImportantDetailsMissing, setIsImportantDetailsMissing] = useState<boolean>()
    const [resolveExpenseModal, setResolveExpenseModal] = useState<boolean>(false)
    const [editExpenseFromResolving, setEditExpenseFromResolving] = useState<boolean>(false)
    const [resolveAIExpensePrompt, setResolveAIExpensePrompt] = useState<boolean>(false)

    const { selectedExpense } = useExpenseContext()

    const [linkbudgetModal, setLinkBudgetModal] = useState(false)
    const navigate = useNavigate()

    const [isTruncated, setIsTruncated] = useState<boolean>(true)

    const [expense_details, setExpenseDetails] = useState<ExpenseType>(selectedExpense!)

    const {
        data: expenseByData,
        isLoading: isExpenseLoading,
        isRefetching,
        isFetching,
    } = useGetExpenseById({
        queryParams: {
            id: expense_id as string,
            org_id: selectedAccount?.org?.id as string,
        },
    })

    //========gets all the categories=========
    const { data: categories_from_endpoint } = useGetOrgCategories({})
    const { categories: categories_categories_fromcontext } = useExpenseContext()

    const categories = useMemo(
        () => categories_from_endpoint ?? categories_categories_fromcontext,
        [categories_from_endpoint, categories_categories_fromcontext]
    )

    const expense = useMemo(() => expenseByData ?? { ...expense_details }, [expenseByData, expense_details])
    const [activeUrl, setActiveUrl] = useState(expense?.receipt_urls?.[0] ?? expense?.receipt)

    const { mutate: scanReceiptFn, isLoading } = useScanExpenseReceipt({
        onSuccess: () => {
            toastHandler({ message: 'scanned successfully', state: 'success' })
        },
    })

    //=====to get the submitters's prefered bank
    const { data: submitterAccount } = useGetAccountsForUser({
        enabled: !!expenseByData,
        queryParams: {
            account: expense?.submitter?.id as string,
        },
    })
    const hasAPrimaryBank = () => {
        const bank_details = submitterAccount?.filter(bankDetails => bankDetails.is_primary) ?? []

        if (bank_details?.length > 0) {
            return true
        }
        return false
    }

    const importantDetailsMissing = () => {
        //-----if the expoense is not approved or denied, it shoouldn't show the warning
        if (
            expense?.status === expenseStatus.approved ||
            expense?.status === expenseStatus.denied ||
            expense.status === expenseStatus.reimbursed
        ) {
            setIsImportantDetailsMissing(false)
        }

        if (!!expense?.amount && !!expense?.merchant_name && !!expense?.receipt && !!expense?.description) {
            setIsImportantDetailsMissing(false)
        }
        if (expense.status === ExpenseStatus.draft) {
            setIsImportantDetailsMissing(true)
        }
        if (Number(expense?.ai_scan_total_amount) !== Number(expense?.amount)) {
            setIsImportantDetailsMissing(true)
        }
    }

    const truncatedText =
        isTruncated && expense?.description?.length > 100
            ? `${expense?.description.slice(0, 100)}...`
            : `${expense?.description ?? 'N/A'}`

    useEffect(() => {
        importantDetailsMissing()
        if (!activeUrl) {
            setActiveUrl(expense?.receipt_urls?.[0] ?? expense?.receipt)
        }
    }, [expense])

    const [option, setOption] = useState('details' as string)
    const isPdf = activeUrl?.includes('.pdf')
    const canApproveExpense = expense?.approvers?.some(user => user?.account?.id === selectedAccount?.id)

    if (isExpenseLoading) return <ProgressBar />

    return (
        <div>
            <div className='bg-[#F9F9F9] py-[30px] px-[20px] md:px-[40px] mb-[80px]'>
                <div className='mb-8 inline-flex items-center cursor-pointer' onClick={() => navigate(-1)}>
                    <BsArrowLeft />
                    <p className='ml-2 font-semibold text-lg'>back</p>
                </div>
                <div className='bg-white px-5 py-6 mb-4 rounded-xl'>
                    <div className='bg-[#F9F9F9] flex gap-4 p-2 w-[11.2rem] rounded-lg mb-6'>
                        <div
                            className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative `}
                            onClick={() => setOption('details')}
                        >
                            {option == 'details' && (
                                <motion.span
                                    layoutId='expense_timeline__animation'
                                    className='bg-[#D5D5F5] rounded-md z-0 text-[#202020] inset-0 absolute inline-block w-full h-full '
                                />
                            )}
                            <motion.span layout='position' className='z-20 sticky'>
                                Details
                            </motion.span>
                        </div>
                        <div
                            className={`${'bg-#F9F9F9 text-[#5E5E5E]'}bg-[#D5D5F5] px-2 py-1 rounded cursor-pointer relative w-full`}
                            onClick={() => setOption('timeline')}
                        >
                            {option == 'timeline' && (
                                <motion.span
                                    layoutId='expense_timeline__animation'
                                    className='bg-[#D5D5F5] rounded-md  text-[#202020] inset-0 absolute inline-block w-full h-full '
                                />
                            )}
                            <motion.span
                                layout='position'
                                className='text-[#202020] inset-0 absolute inline-block w-full h-full px-2 py-1'
                            >
                                Timeline
                            </motion.span>
                        </div>
                    </div>
                    {option == 'details' && (
                        <div className='flex flex-col gap-4 text-[#202020]'>
                            <div className='flex flex-col lg:flex-row lg:items-center justify-between border-b border-[#DADCE0] py-3 gap-4'>
                                <h1 className='lg:text-lg font-semibold'>
                                    {expense?.expense_id} - {expense?.category?.label} -{' '}
                                    {expense?.amount
                                        ? `${expense?.currency?.symbol}${formatAmountNoSymbol(expense?.amount)}`
                                        : 'N/A'}
                                </h1>
                                <div className='flex justify-end lg:justify-center items-center gap-[12.5px] flex-wrap'>
                                    {expense?.status !== ExpenseStatus.denied && (
                                        <>
                                            {IsOwner() ? (
                                                <OwnerAuthorizeExpensePermission
                                                    expense={expense}
                                                    hasAPrimaryBank={hasAPrimaryBank}
                                                    setExpenseDetails={setExpenseDetails}
                                                    isRefetchingExpense={
                                                        isExpenseLoading || isRefetching || isFetching || isLoading
                                                    }
                                                    submitterAccount={submitterAccount ?? []}
                                                    setLinkBudgetModal={setLinkBudgetModal}
                                                />
                                            ) : (
                                                <ExpenseAuthorizePermission
                                                    expense={expense}
                                                    hasAPrimaryBank={hasAPrimaryBank}
                                                    setExpenseDetails={setExpenseDetails}
                                                    isRefetchingExpense={
                                                        isExpenseLoading || isRefetching || isFetching || isLoading
                                                    }
                                                    submitterAccount={submitterAccount ?? []}
                                                    setLinkBudgetModal={setLinkBudgetModal}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className=' mt-3'>
                                {expense.ocr_discrepancy && (
                                    <div className='bg-[#FFEBE8] p-3 text-sm text-[#872D10] rounded-md mb-4 w-full flex flex-col lg:flex-row lg:items-center justify-between gap-20'>
                                        <div>
                                            <h6 className='font-semibold text-black'>Important</h6>
                                            <p>
                                                The total amount on all the receipts (
                                                {formatAmount(expense?.ai_scan_total_amount)}) do not match the expense
                                                amount submitted ({formatAmount(expense?.amount)}). Kindly review all
                                                receipts
                                            </p>
                                        </div>
                                        {expense?.status !== ExpenseStatus.approved &&
                                            expense?.status !== ExpenseStatus.reimbursed && (
                                                <button
                                                    className='font-semibold text-black text-left lg:text-right mt-3 lg:mt-0 whitespace-nowrap'
                                                    onClick={() => setResolveExpenseModal(true)}
                                                >
                                                    Resolve issue
                                                </button>
                                            )}
                                    </div>
                                )}
                                {expense.status === ExpenseStatus.draft &&
                                    isImportantDetailsMissing &&
                                    expense?.scanned_with_ai && (
                                        <p className='bg-[#FFEBE8] p-3 text-sm text-[#872D10] rounded-md mb-4 w-auto lg:w-max'>
                                            Important: Your expense draft is missing some important details. Edit draft
                                            for accuracy and completeness before submission.
                                        </p>
                                    )}
                                <div className='hidden lg:block'>
                                    <h2 className='text-base font-semibold mb-2'>Expense description</h2>
                                    <p className='text-#5E5E5E]'>
                                        {truncatedText ?? 'N/A'}
                                        <span className='ml-4 text-brand' onClick={() => setIsTruncated(prev => !prev)}>
                                            {expense?.description?.length > 100
                                                ? isTruncated
                                                    ? 'view more'
                                                    : 'view less'
                                                : ''}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-wrap justify-between flex-col-reverse lg:flex-row'>
                                <div className='w-full lg:w-[70%] mt-5 lg:mt-0'>
                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-8 text-base mb-12'>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Expense ID</h2>
                                            <p>{expense?.expense_id}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Merchant name</h2>
                                            <p>{expense?.merchant_name}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Category</h2>
                                            <p>{expense?.category?.label}</p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Amount</h2>
                                            <p>
                                                {expense?.currency?.symbol}
                                                {formatAmountNoSymbol(expense?.amount) ?? 'N/A'}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Created by</h2>
                                            <p className='whitespace-normal truncate'>
                                                {expense?.submitter?.user?.first_name ?? 'N/A'}{' '}
                                                {expense?.submitter?.user?.last_name ?? ''}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Approver(s)</h2>
                                            {expense?.approvers?.map(approver => (
                                                <p className='whitespace-normal truncate'>
                                                    {approver?.account?.user?.first_name ?? ''}{' '}
                                                    {approver?.account?.user?.last_name ?? ''}{' '}
                                                </p>
                                            ))}
                                            {expense?.approvers?.length == 0 &&
                                                (expense?.status !== ExpenseStatus.draft ? 'Auto-approval' : 'N/A')}
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Status</h2>
                                            <div className='w-[100%]'>
                                                <ExpenseStatusBadge
                                                    status={expense?.status?.toLowerCase() as ExpenseStatus}
                                                />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Expense Date</h2>
                                            <p>
                                                {expense?.createdAt
                                                    ? format(new Date(expense?.date), 'dd/MM/yyyy')
                                                    : 'N/A'}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Expense type</h2>
                                            <p>{expense?.reimbursable ? 'Reimbursable' : 'Non-reimbursable'}</p>
                                        </div>

                                        <div className=''>
                                            <h2 className='font-semibold mb-2'>Budget</h2>
                                            <p>{expense?.budget?.name ?? 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:hidden mt-5 lg:mt-0'>
                                    <h2 className='text-base font-semibold mb-2'>Expense description</h2>
                                    <p className='text-#5E5E5E]'>
                                        {truncatedText ?? 'N/A'}
                                        <span className='ml-4 text-brand' onClick={() => setIsTruncated(prev => !prev)}>
                                            {expense?.description?.length > 100
                                                ? isTruncated
                                                    ? 'view more'
                                                    : 'view less'
                                                : ''}
                                        </span>
                                    </p>
                                </div>
                                <div className='-mt-7 lg:-mt-20'>
                                    {!expense.scanned_with_ai && canApproveExpense && (
                                        <Button
                                            onClick={() => {
                                                scanReceiptFn({ id: expense?.id ?? expense_details?.id })
                                            }}
                                            variant={'outline'}
                                            loading={isLoading}
                                            className='focus:ring-0 gap-2 min-w-fit border-transparent min-h-fit text-sm font-semibold text-brand p-0 justify-start lg:justify-center'
                                        >
                                            <ScanLine size={18} /> Scan Receipt
                                        </Button>
                                    )}
                                    <div
                                        className={`overflow-hidden cursor-pointer lg:w-[15rem] h-max  mx-auto mt-3 lg:mt-0 ${activeUrl ? 'bg-[#F9F9F9]' : ''} rounded-lg`}
                                        onClick={() =>
                                            handleShowImage(
                                                activeUrl as string,
                                                isPdf ? FILE_TYPE.pdf : FILE_TYPE.image
                                            )
                                        }
                                    >
                                        {activeUrl ? (
                                            isPdf ? (
                                                <div className='group relative bg-[#F9F9F9] w-full flex flex-col justify-center items-center p-4'>
                                                    <PDFIcon className='w-[50px] h-[65px] rounded-lg ' />
                                                    <button className='text-sm py-2 text-center inline-block absolute transition-all -bottom-full group-hover:bottom-0  bg-green text-white w-full'>
                                                        download receipt
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='group relative w-full overflow-hidden rounded-lg'>
                                                    <img
                                                        alt='receipt'
                                                        src={activeUrl}
                                                        className='w-full object-cover h-[224px] rounded-lg border-[3px] border-[#DADCE0]'
                                                    />
                                                    <Link
                                                        className='absolute top-3 right-3 bg-[#5E5E5ED4] rounded-sm p-1 cursor-pointer text-[#19C37D]/90'
                                                        to={activeUrl}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        download
                                                    >
                                                        <img src={downArrow} alt='button' />
                                                    </Link>
                                                    <button className='text-sm py-2 text-center inline-block absolute transition-all group-hover:bottom-0 -bottom-full bg-green text-white w-full'>
                                                        view receipt
                                                    </button>
                                                </div>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {expense?.receipt_urls && (
                                        <div className=' flex lg:justify-end mb-4 lg:mb-0 gap-2'>
                                            {expense?.receipt_urls?.map((url, i) => (
                                                <button
                                                    type='button'
                                                    className={`text-primary-100 bg-primary-200  rounded mt-4 border   p-1 ${url === activeUrl ? 'border-brand border-2' : 'border-gray5'}`}
                                                    onClick={() => setActiveUrl(expense?.receipt_urls?.[i])}
                                                >
                                                    <>
                                                        {url?.includes('pdf') ? (
                                                            <PDFIcon className='w-5 h-5' />
                                                        ) : (
                                                            <img
                                                                src={url}
                                                                alt='Receipt'
                                                                className='w-8 h-8 object-cover'
                                                            />
                                                        )}
                                                    </>
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {option === 'timeline' && <ExpenseDetailsTimeLine expense={expense} />}
                </div>
                <div className='p-3 lg:p-5 bg-white rounded-xl mb-12'>
                    <h1 className='pl-5 border-b border-[#DADCE0] text-lg text-[#202020] py-1'>
                        Linked transaction(s)
                    </h1>
                    {expense.transactions &&
                        (expense?.transactions.length > 0 ? (
                            <LinkedExpenseTransaction expense={expense} />
                        ) : (
                            <EmptyStates
                                title='No Linked Transactions'
                                description="You haven't made any transaction relating to this expense yet. Start exploring our services and make your first transaction to see it listed here."
                                image={emptyTransaction}
                            />
                        ))}
                </div>
            </div>

            {resolveExpenseModal && (
                <ResolveAIGeneratedExpense
                    setEditExpense={setEditExpenseFromResolving}
                    expense={expense}
                    setShowModal={setResolveExpenseModal}
                    setOpenResolvePrompt={setResolveAIExpensePrompt}
                />
            )}

            {resolveAIExpensePrompt && <ResolveAIExpensePrompt closeModal={setResolveAIExpensePrompt} />}
            {editExpenseFromResolving && (
                <ExpenseFormRoot
                    setShowModal={setEditExpenseFromResolving}
                    selectedExpense={expense!}
                    categories={categories}
                    hasAPrimaryBank={!hasAPrimaryBank()}
                    edit={true}
                />
            )}

            {linkbudgetModal && <LinkExpenseToBudget setShowModal={setLinkBudgetModal} selectedExpense={expense!} />}
        </div>
    )
}

export default ExpenseDetailsPage

import { Button } from '@/components'
import { ChangeEvent, useRef, useState } from 'react'
import { useCreateSignedUrl } from '@/services/file-upload/mutation'
import { ModuleNameEnum, generateFileName } from '@/hooks/useSingleFileUpload'
import { useAuthContext } from '@/context'

type Props = {
    label?: string
    name: string
    setValue: (data: string[]) => void
    moduleName: ModuleNameEnum
    maxFiles?: number
    accept?: string
}

export const UploadFilesToS3 = ({
    name,
    setValue,
    label,
    moduleName,
    maxFiles = 5,
    accept = '.pdf, .jpeg, .jpg, .png',
}: Props) => {
    const { selectedAccount } = useAuthContext()
    const [files, setFiles] = useState<File[]>([])
    const { mutate, isLoading } = useCreateSignedUrl({
        onSuccess: async dataFromSer => {
            const uploadedUrls: string[] = []

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const signedUrl = dataFromSer[i]?.signedUrl

                if (signedUrl) {
                    await fetch(signedUrl, {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': file.type,
                            'Content-Length': String(file.size),
                        },
                    })

                    uploadedUrls.push(dataFromSer[i]?.url)
                }
            }

            setValue(uploadedUrls)
        },
    })

    const handleGetSignedURLs = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files ? Array.from(e.target.files) : []

        if (selectedFiles.length > maxFiles) {
            alert(`You can only upload up to ${maxFiles} files.`)
            return
        }

        // Generate signed URLs for the selected files
        mutate({
            files: selectedFiles.map(file => ({
                fileName: generateFileName({
                    module: moduleName,
                    unique_identifier: `${name}-${file.name.split('.')[0]}`,
                    org_id: selectedAccount?.org?.id ?? '',
                    selectedFileName: file.name,
                }),
                contentType: file.type,
            })),
            module: moduleName,
        })

        setFiles(selectedFiles) // Update the state with the selected files
    }

    const ref = useRef<HTMLInputElement>(null)

    return (
        <section className={`${isLoading ? 'opacity-60' : ''}`}>
            {label && <h5 className='text-gray7 font-normal text-sm mb-2'>{label}</h5>}
            <div className='border border-[#DADCE0] rounded-xl p-4'>
                <label className='cursor-pointer flex flex-col md:flex-row items-center justify-between gap-4 md:gap-16'>
                    <div>
                        {files.length === 0 && (
                            <span className='text-gray7/80 text-sm text-medium'>
                                Click to upload files. Max {maxFiles} files | Accepts {accept}
                            </span>
                        )}
                        {files.length > 0 && (
                            <div className='flex flex-col gap-2'>
                                {files.map((file, index) => (
                                    <span key={index} className='font-medium break-all'>
                                        {file.name}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>

                    <input
                        disabled={isLoading}
                        name={name}
                        type='file'
                        hidden
                        ref={ref}
                        accept={accept}
                        onChange={handleGetSignedURLs}
                        multiple
                    />
                    <div className='w-full md:w-auto'>
                        <Button
                            type='button'
                            disabled={isLoading}
                            className='min-w-full md:min-w-auto bg-transparent text-brand border-brand border whitespace-nowrap hover:text-white'
                            onClick={() => ref.current?.click()}
                        >
                            {files.length > 0 ? 'Change documents' : 'Upload documents'}
                        </Button>
                    </div>
                </label>
            </div>
        </section>
    )
}

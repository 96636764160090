import * as React from 'react'
import { cn } from '@/lib/styling'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
    bodyClassName?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, bodyClassName, leftIcon, rightIcon, ...props }, ref) => {
        return (
            <div className={cn('relative w-full xl:min-w-[18.1rem]', bodyClassName)}>
                {leftIcon && (
                    <div
                        className='absolute left-0 top-0 h-full flex items-center justify-center pl-3 pr-3 '
                        style={{ width: '2.5rem' }}
                    >
                        {leftIcon}
                    </div>
                )}
                <input
                    className={cn(
                        'flex w-full h-[4.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed',
                        className,
                        leftIcon && 'pl-8',
                        rightIcon && 'pr-12'
                    )}
                    data-testid={props.name}
                    ref={ref}
                    {...props}
                />
                {rightIcon && (
                    <div
                        className='absolute right-0 top-0 h-full flex items-center justify-center pl-3 pr-3 bg-transparent '
                        style={{ width: '2.5rem' }}
                    >
                        {rightIcon}
                    </div>
                )}
            </div>
        )
    }
)
Input.displayName = 'Input'

export { Input }

import { useMemo } from 'react'
import TransactionHistoryTable from '../../Transactions/components/TransactionHistoryTable'
import { useGetVendorTransactions } from '@/services/transactions/queries'
import EmptyStates from '@/components/EmptyStates'
import ProgressBar from '@/components/ProgressBar'
import emptyTransaction from '@/assets/empty-state-transactions.svg'
import { useParams } from 'react-router-dom'

export default function LinkedTransactionsTable() {
    const { vendor_id } = useParams()

    const {
        data,
        isLoading: isTransactionsLoading,
        isFetching: isTransactionsFetched,
    } = useGetVendorTransactions({
        queryParams: {
            vendor_id: vendor_id as string,
        },
    })
    const transactionsData = useMemo(() => data ?? [], [data])

    if (isTransactionsLoading || isTransactionsFetched)
        return (
            <div className='h-48 flex items-center justify-center'>
                <ProgressBar />
            </div>
        )
    return (
        <section className='mt-[1.2rem]'>
            {transactionsData.length > 0 ? (
                <div className={` transition-all rounded-lg ${isTransactionsFetched ? 'opacity-30' : 'opacity-100'} `}>
                    <TransactionHistoryTable transactions={transactionsData} />
                </div>
            ) : (
                <div className='h-450px] flex flex-col text-center justify-center items-center'>
                    <EmptyStates
                        title='No Recent Transactions'
                        description="You haven't made any transactions yet. Start exploring our services and
        make your first transaction to see it listed here."
                        image={emptyTransaction}
                    />
                </div>
            )}
        </section>
    )
}

import { CustomBarTooltip2 as CustomBarTooltip } from '@/components/Tooltips/CustomTooltip'
import { useCurrencyContext } from '@/context'
import { NewMonthlySummary } from '@/services/transactions/types'
import { AbbrvFormatNumber } from '@/utils/utils'
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart, ReferenceLine } from 'recharts'

type Props = {
    chartData: NewMonthlySummary[]
    currency: string
}

export const TransactionsLineChart = ({ chartData, currency }: Props) => {
    const { selectedCurrencyInfo } = useCurrencyContext()

    return (
        <ResponsiveContainer height={281} width={'100%'} className={'mx-auto -mr2'}>
            <AreaChart
                data={chartData}
                margin={{
                    left: 40,
                }}
            >
                <CartesianGrid stroke='#DADCE0' vertical={false} strokeDasharray='5 5' fill='white' />
                <XAxis dataKey='name' stroke='#888888' fontSize={12} tickLine={false} axisLine={false} />
                <YAxis
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={value => `${selectedCurrencyInfo(currency)?.symbol}${AbbrvFormatNumber(value)}`}
                    hide={false}
                    label={{
                        value: 'Naira',
                        angle: -90,
                        position: 'insideRight',
                        offset: 90,
                    }}
                    offset={10}
                    type='number'
                />
                <Tooltip
                    filterNull={true}
                    cursor={false}
                    wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                    content={<CustomBarTooltip />}
                />
                <Area
                    type='monotone'
                    dataKey='incomingTotal'
                    stroke='#454ADE'
                    fill='#165DFF'
                    fillOpacity={0.1}
                    strokeWidth={2}
                    dot={false}
                    activeDot={false}
                />
                <Area
                    type='monotone'
                    dataKey='outgoingTotal'
                    stroke='#D5D5F5'
                    fill='#D5D5F5'
                    strokeWidth={2}
                    dot={false}
                    fillOpacity={0}
                    activeDot={false}
                />
                <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
            </AreaChart>
        </ResponsiveContainer>
    )
}

import { Button, Input } from '@/components'

import ModalWrapper from '../../ModalWrapper'
import { AlertTriangle } from 'lucide-react'

type Props = {
    closeModal: () => void
}
const DeeleteUploadedCOAPrompt = ({ closeModal }: Props) => {
    return (
        <ModalWrapper closeModal={closeModal}>
            <main className='h-[19.5rem] lg:h-auto w-screen lg:w-[30rem]'>
                <section className='m-6'>
                    <div className='flex items-center gap-4'>
                        <span className='bg-errorRed/10 p-2 rounded-full'>
                            <AlertTriangle className='text-errorRed  w-6 h-6' />
                        </span>
                        <h3 className='font-medium text-lg'>Remove Kuda_MFB_Sage_COA.xls</h3>
                    </div>
                    <h4 className='opacity-80 my-4'>This action will have the following effects:</h4>
                    <ul className='list-disc list-inside text-sm text-gray7 space-y-3'>
                        <li>All mapped accounts related to this COA/integration will be unlinked.</li>
                        <li>Any automation or data sync dependent on this integration will stop.</li>
                        <li>Historical data will remain unaffected.</li>
                    </ul>
                    <fieldset className='flex flex-col gap-2 text-sm mt-5'>
                        <label htmlFor='remove_coa' className='opacity-80'>
                            To confirm, type <span className='text-errorRed font-medium'>REMOVE</span> in the field
                            below:
                        </label>
                        <Input className='border-gray5 h-12' />
                    </fieldset>
                </section>
                <div className='mt-5 border-t border-gray5'>
                    <div className='flex gap-5 justify-end mr-6 my-4'>
                        <Button
                            size={'lg'}
                            className='bg-transparent text-[#202020] hover:bg-transparent text-xs min-w-[120px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button size={'lg'} variant={'error'} className=' text-white text-xs min-w-[120px] '>
                            Confirm removal
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default DeeleteUploadedCOAPrompt

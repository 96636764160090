type Props = {
    allWidth?: boolean
}
export default function ProgressBar({ allWidth = true }: Props) {
    return (
        <section
            data-testid='progress-bar'
            className={` ${allWidth ? 'lg:w-[calc(100vw-340px)]' : ''} h-[calc(100vh-81px)] grid place-items-center`}
        >
            <div className='w-[200px] h-[6px] bg-gray/20 rounded-full overflow-hidden'>
                <div className='animate-progressBarLoader w-1/2 h-full bg-[#1b1f3b] rounded-full'></div>
            </div>
        </section>
    )
}

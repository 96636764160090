import { useEffect, useRef, useState } from 'react'
import { Input } from '../Input'
import usePersistedState from '@/hooks/usePersistedState'
import { useGeneratePaymentOTP, useVerifyPaymentOTP } from '@/services/auth/mutations'
import { Button } from '../Button'

type Props = {
    name: string
    nextStep: () => void
    close: () => void
}

const PaymentOTP = ({ name, nextStep, close }: Props) => {
    const [timer, setTimer] = usePersistedState({ key: name, defaultValue: 120 })

    const [otp, setOtp] = useState('')

    const { mutate: generateOtp } = useGeneratePaymentOTP({})
    const { mutate: verifyOtp, isLoading } = useVerifyPaymentOTP({
        onSuccess: () => {
            nextStep()
        },
    })
    const generateOTPRef = useRef(false)

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        if (!generateOTPRef.current) {
            generateOTPRef.current = true
            generateOtp({})
        }
    }, [])

    return (
        <section className='w-screen lg:w-[35rem] p-10 px-5 lg:px-10 z-50'>
            <h4 className='text-[#202020] text-lg font-semibold'>Verify action</h4>
            <p className='mt-[10px] font-medium text-[#202020] text-[14px]'>
                For security reasons, an OTP has been sent to your email to verify this action. Kindly enter OTP to
                proceed
            </p>

            <form>
                <div className='flex flex-col md:flex-row mt-6 '>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Enter OTP
                        </label>

                        <Input
                            onChange={e => setOtp(e.target.value)}
                            className='h-[3.5rem]'
                            type='number'
                            placeholder='Enter your OTP'
                        />
                    </div>
                </div>
            </form>
            <p className='mt-4 text-sm font-medium'>
                I did not receive OTP.{' '}
                <button
                    className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                    onClick={() => {
                        setTimer(120)

                        generateOtp({})
                    }}
                    disabled={timer > 0}
                >
                    {' '}
                    Resend
                </button>{' '}
                {timer !== 0 && <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>}
            </p>

            <div className='flex justify-end gap-6 mt-12'>
                <Button
                    className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent disabled:bg-transparent disabled:opacity-40'
                    onClick={() => {
                        close()
                    }}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    loading={isLoading}
                    onClick={() => {
                        verifyOtp({ otp })
                    }}
                    className='min-w-[100px] px-4'
                    disabled={otp?.length < 4}
                >
                    Proceed
                </Button>
            </div>
        </section>
    )
}

export default PaymentOTP

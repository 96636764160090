import { Button } from '@/components'

import ModalWrapper from '../ModalWrapper'
import { RequestAccessType } from '@/types/request-access'
import { useResendAccessrequest } from '@/services/auth/mutations'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    requestingCompany?: RequestAccessType
    closeModal: () => void
}
const ResendRequestAccessPrompt = ({ requestingCompany, closeModal }: Props) => {
    const { mutate, isLoading } = useResendAccessrequest({
        queryParams: {
            id: (requestingCompany?.id ?? requestingCompany?._id) as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Request resent successfully', state: 'success' })
            closeModal()
        },
    })
    return (
        <ModalWrapper>
            <main className='lg:h-auto w-screen lg:w-[30rem]'>
                <div className='mt-5'>
                    <p className='text-gray7 font-medium text-base mb-7  mx-auto px-5 leading-7'>
                        Are you sure you want to resend the request
                    </p>
                    <div className='flex gap-5 justify-end pr-10 mb-4'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 py-2 h-[2rem] rounded-lg text-xs hover:bg-transparent min-w-[100px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-brand text-white px-6 h-[2rem] rounded-lg text-[14px] min-w-[100px] py-2'
                            loading={isLoading}
                            onClick={() => mutate({})}
                        >
                            Proceed
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default ResendRequestAccessPrompt

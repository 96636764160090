import { convertToBytesText } from '@/components/Modals/Vault/AddNewFileModal'
import { useAuthContext } from '@/context'
import { cn } from '@/lib/styling'
import { useGetVaultStorageDetails } from '@/services/vault/queries'
import { ModuleNameEnum } from '@/types/billing'

export const VaultStorage = () => {
    const { data: vaultStorage, isLoading: isLoadingStorage } = useGetVaultStorageDetails()
    const { setShowUpgradeModal, setCurrentModule } = useAuthContext()

    return (
        <div className={cn(isLoadingStorage && 'opacity-30')}>
            <h1 className='text-dark font-medium text-lg'>Storage plan & usage</h1>
            <p className='text-gray7 text-sm'>Track usage, upgrade storage, and optimize your document management</p>
            <div className='mt-4'>
                <div className='bg-[#F9F9F9] p-4 flex flex-col gap-3 rounded-xl'>
                    <div className='font-medium text-gray7 flex items-center justify-between text-sm'>
                        <p>
                            {' '}
                            {convertToBytesText(vaultStorage?.usedStorageSize.overallStorageSize as number)} of{' '}
                            {convertToBytesText(
                                Number(vaultStorage?.usedStorageSize.overallStorageSize) +
                                    Number(vaultStorage?.remainingStorageSize)
                            )}{' '}
                            used
                        </p>
                        <p>{convertToBytesText(Number(vaultStorage?.remainingStorageSize))} available</p>
                    </div>
                    <div className='h-11 bg-white rounded-lg mb-6'></div>
                    <div className='font-medium text-gray7 text-sm flex items-center gap-8 flex-wrap'>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#DEB145] rounded'></p>
                            <p>PNG/JPEGS</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#FF9152] rounded'></p>
                            <p>PDFs</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#5C9BFF] rounded'></p>
                            <p>XLS/CSV</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <p className='h-4 w-4 bg-[#454ADE] rounded'></p>
                            <p>Texts</p>
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => {
                        setShowUpgradeModal(true)
                        setCurrentModule(ModuleNameEnum.VAULT)
                    }}
                    className='flex mt-6 items-center w-fit gap-2 border border-[#454ADE] px-3 py-2 rounded-lg bg-[#EFEFF9] text-brand text-sm font-semibold'
                >
                    Get more storage
                </button>
            </div>
            {vaultStorage && vaultStorage?.usedStorageSize?.categories?.length > 0 && (
                <div className='bg-[#F9F9F9] p-4 flex flex-col gap-4 mt-7 rounded-xl'>
                    {vaultStorage?.usedStorageSize.categories.map(category => (
                        <div className='font-medium text-gray7 flex items-center justify-between'>
                            <p>{category.categoryName}</p>
                            <p>{convertToBytesText(Number(category.totalSize))}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

import filter from '@/assets/filter.svg'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import { usePayrollContext } from '@/context'
import { PayrollHistoryType } from '@/types/payroll'
import { formatAmount } from '@/utils/money'
import { ColumnDef, ColumnSort, createColumnHelper } from '@tanstack/react-table'
import { format, getDay, getYear } from 'date-fns'
import React from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { calculatePayrollSum, getPayDates } from '../../Payroll/utils'
import CheckBox from '@/components/Table/CheckBox'
import { Pagination } from '@/types/utils'

type Props = {
    payHistory: PayrollHistoryType[]

    pagination: Pagination
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    setPageNoClicked: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PayHistoryTable({
    payHistory,
    pageNumber,
    pagination,
    setPageNoClicked,
    setPageNumber,
}: Props) {
    const columnHelperr = createColumnHelper<PayrollHistoryType>()
    const columnHelper = createColumnHelper<PayrollHistoryType>()
    const [rowSelection, setRowSelection] = React.useState({})
    const { setSort } = usePayrollContext()
    const navigate = useNavigate()
    const { setUserPayrollHistory } = usePayrollContext()

    const handleMoreDetails = (data: PayrollHistoryType) => {
        navigate(`/dashboard/payroll/pay_history/${data.id}?id=${data?.id}`)
    }

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }

    const columns: ColumnDef<PayrollHistoryType, any>[] = [
        columnHelperr.display({
            id: 'select',
            header: ({ table }) => (
                <CheckBox
                    {...{
                        checked: table?.getIsAllRowsSelected(),
                        indeterminate: table?.getIsSomeRowsSelected(),
                        onChange: table?.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <CheckBox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('transaction.createdAt', {
            header: () => <p>Pay day</p>,
            cell: cell => {
                const day = getDay(new Date(cell.renderValue()))
                const year = getYear(new Date(cell.renderValue()))

                return <>{format(getPayDates(cell?.row?.original?.pay_frequency, day, year).lastDay, 'dd/MM/yyyy')}</>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('transaction.createdAt', {
            header: () => <>Pay period</>,
            cell: cell => {
                const day = getDay(new Date(cell.renderValue()))
                const year = getYear(new Date(cell.renderValue()))
                return (
                    <div>
                        <p>
                            {format(getPayDates(cell?.row?.original?.pay_frequency, day, year).startDate, 'dd/MM/yyyy')}{' '}
                            - {format(getPayDates(cell?.row?.original?.pay_frequency, day, year).lastDay, 'dd/MM/yyyy')}
                        </p>
                        <p className='text-[#838383] text-sm mt-1'>{cell.row.original?.pay_frequency}</p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('gross_salary', {
            header: () => <p> Gross salary</p>,
            cell: cell => (
                <div>
                    <p>{formatAmount(cell.renderValue())}</p>
                    <p className='text-[#838383] text-sm mt-1'>{cell.row.original?.pay_frequency}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('deductions', {
            header: () => <p>Deductions</p>,
            cell: cell => (
                <span>
                    {formatAmount(calculatePayrollSum(cell.renderValue(), 'amount', cell?.row.original.gross_salary))}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('bonuses', {
            header: () => <p>Bonuses</p>,
            cell: cell => (
                <span>
                    {formatAmount(calculatePayrollSum(cell.renderValue(), 'amount', cell?.row.original.gross_salary))}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('net_salary', {
            header: () => <p>Net Salary</p>,
            cell: cell => (
                <div>
                    <p>{formatAmount(cell.renderValue())}</p>
                    <p className='text-[#838383] text-sm mt-1'>
                        {cell.row.original?.prorated ? 'Prorated Amount' : 'Full payment'}
                    </p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <div className='flex items-center gap-2'>
                    <div className='h-[40px] w-[40px] border-2 border-[#DADCE0] rounded flex items-center justify-center p-2 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                </div>
            ),
            cell: row => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'More details',
                                icon: AiOutlineEdit,
                                method: () => {
                                    handleMoreDetails(row?.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    const handleRowClick = (data: PayrollHistoryType) => {
        navigate(`/dashboard/payroll/pay_history/${data.id}?id=${data.id}`)
        setUserPayrollHistory(data)
    }

    return (
        <section className='h-full'>
            <Table
                data={payHistory}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                handleSort={handleSort}
                pagination_data={pagination}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                setIsPageNumberClicked={setPageNoClicked}
            />
        </section>
    )
}

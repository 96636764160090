import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useAddNewBusiness, useSwitchAccount } from '@/services/auth/mutations'
import { emailRegex } from '@/utils/string'
import { Info, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../ModalWrapper'
import { QueryCache, useQueryClient } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { UserOrgAccount } from '@/types/org'
import { useNavigate } from 'react-router-dom'
import SwitchOrgHelper from '@/layout/SwitchOrgHelper'
import { kyb } from '@/modules/dashboard/Settings/partials/CompanyCompliance/utils'

type AddNewBusinessModalProps = {
    closeNewBusinessModal: React.Dispatch<React.SetStateAction<boolean>>
}
const formEmptyState = {
    first_name: '',
    last_name: '',
    org_name: '',
    org_type: '',
    email: '',
    phone: '',
    description: '',
}
enum org_type {
    'Sole proprietorship' = 'SOLE_PROPRIETORSHIP',
    'Partnership' = 'PARTNERSHIP',
    'Corporation' = 'CORPORATION',
    'Limited Liability company' = 'LIMITED_LIABILITY_COMPANY',
    'Franchise' = 'FRANCHISE',
    'Joint venture' = 'JOINT_VENTURE',
    'Public traded companies' = 'PUBLIC_TRADED_COMPANIES',
    'Private equity funds' = 'PRIVATE_EQUITY_FUNDS',
    'Government owned' = 'GOVERNMENT_OWNED',
}
const AddNewBusinessModal = ({ closeNewBusinessModal }: AddNewBusinessModalProps) => {
    const { user, selectedAccount, setSelectedAccount } = useAuthContext()
    const queryCache = new QueryCache({})
    const navigate = useNavigate()

    const [errInEmail, setErrInEmail] = useState(false)
    const [sameEmail, setSameEmail] = useState(false)
    const [step, setStep] = useState(1)

    const queryClient = useQueryClient()

    /**
    Switching Org
     */
    const posthog = usePostHog()
    const [isSwitchingToNewOrg, setIsSwitchingToNewOrg] = useState(false)
    const [accountToBeSwitched, setAccountToBeSwitched] = useState<UserOrgAccount>()

    const [formState, setFormState] = useState(formEmptyState)
    const [selectedOrgType, setSelectedOrgType] = useState('')
    const [selectedIndustry, setSelectedIndustry] = useState('')

    const orgTypes = [
        'Sole proprietorship',
        'Partnership',
        'Corporation',
        'Limited Liability company',
        'Franchise',
        'Joint venture',
        'Public traded companies',
        'Private equity funds',
        'Government owned',
    ]
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        // if (name === 'email' && user?.email === formState.email) return

        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const {
        mutate: AddNewBusiness,
        // isLoading,
        // isError,
    } = useAddNewBusiness({
        onSuccess: data => {
            // toastHandler({ message: 'New business successfully added', state: 'success' })
            setAccountToBeSwitched(data)
            setStep(3)

            //Reset the form state
            setFormState(formEmptyState)
        },
        onError: () => {
            toastHandler({ message: 'Please try again', state: 'error' })
            setStep(1)
        },
    })
    const { mutate: switchAccountFn } = useSwitchAccount({
        onSuccess(data: any) {
            if (data.account_data) {
                posthog.identify(data.account_data.id, {
                    first_name: data.account_data.user.first_name,
                    last_name: data.account_data.user.last_name,
                    email: data.account_data.user.email,
                    account_id: data.account_data.id,
                    org_id: data.account_data.org?.id,
                    org_name: data.account_data.org?.org_name,
                })
            }
            if (['ADMIN', 'OWNER', 'FINANCE_ADMIN'].includes(selectedAccount?.role?.name ?? 'USER')) {
                navigate('/dashboard/overview')
            } else {
                navigate('/dashboard/overview')
            }
            closeNewBusinessModal(false)
        },
    })

    const handleFormSubmit = () => {
        const formData = {
            org_name: formState.org_name,
            org_type: org_type[formState.org_type as keyof typeof org_type],
            phone: formState?.phone,
            email: formState?.email,
        }
        if (!formState.org_name || !formState.org_type || !formState.email || !formState.phone)
            return toastHandler({ message: 'Please fill all fields', state: 'error' })
        setStep(2)
        AddNewBusiness(formData)
        queryCache.clear()
    }
    const disableButton = () => {
        const res = Object.values({ ...formState })
            .map(value => !!value)
            .some(data => data === false)
        return res || !emailRegex.test(formState.email)
    }

    useEffect(() => {
        if (user) {
            setFormState(prevState => ({
                ...prevState,
                first_name: user?.first_name,
                last_name: user?.last_name,
                org_type: selectedOrgType,
                phone: user?.phone as string,
            }))
        }
    }, [user, selectedOrgType])

    useEffect(() => {
        setErrInEmail(() => user?.email.includes(formState.email) ?? false)
        setSameEmail(() => user?.email === formState.email)
    }, [formState.email, user?.email])

    if (isSwitchingToNewOrg) return <SwitchOrgHelper account={accountToBeSwitched!} />

    const SettingUp = () => {
        return (
            <>
                {step === 2 && (
                    <div className='flex w-screen lg:w-[650px] h-[405px] flex-col justify-center items-center px-8 lg:px-32 bg-[#FAFAFD] rounded-xl'>
                        <h1 className='text-xl font-semibold text-[#202020]'>Setting up your account</h1>
                        <p className='text-base font-normal mx-auto text-center'>
                            We're putting things together. This won’t take long.
                        </p>
                        <div className='border-t border-[#A8A8A8] w-[400px] h-[184px] flex justify-center  mt-[3.7rem] rotate-180'>
                            <div
                                className='w-16 h-[184px] bg-[#9FDA96] rounded-b-lg animate-animate-up-down'
                                style={{ animationDelay: '600ms' }}
                            ></div>
                            <div
                                className='w-16 h-[184px] bg-[#FFAB90] rounded-b-lg animate-animate-up-down'
                                style={{ animationDelay: '400ms' }}
                            ></div>
                            <div
                                className='w-16 h-[184px] bg-[#7E82FB] rounded-b-lg animate-animate-up-down delay-[400ms]'
                                style={{ animationDelay: '200ms' }}
                            ></div>
                            <div className='w-16 h-[184px] bg-[#F5D484] rounded-b-lg animate-animate-up-down delay-[600ms]'></div>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className='flex w-screen lg:w-[450px] flex-col justify-center items-center h-[300px] px-8 lg:px-16 bg-[#FAFAFD] rounded-xl relative'>
                        <X
                            onClick={() => closeNewBusinessModal(false)}
                            className='rounded-full absolute top-4 right-4 bg-[#F2F2F2] p-1 cursor-pointer'
                            stroke='#838383'
                            size={24}
                        />
                        <div className='mb-4'>
                            <svg
                                className='checkmark w-[56px] h-[56px] rounded-full block'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 52 52'
                            >
                                <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{' '}
                                <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                            </svg>
                        </div>
                        <h1 className='text-base font-medium text-[#202020]'>New business created successfully</h1>
                        <p className='text-xs text-gray7 font-normal mx-auto text-center'>
                            You have successfully created a new business.
                        </p>

                        <p
                            className='text-brand text-sm font-medium mt-8 cursor-pointer hover:underline'
                            onClick={() => {
                                posthog.reset()
                                setIsSwitchingToNewOrg(true)
                                setSelectedAccount(accountToBeSwitched)
                                switchAccountFn({ account: accountToBeSwitched?.id })
                                queryClient.invalidateQueries()
                                window.location.href = '/dashboard/overview'
                            }}
                        >
                            See new business account
                        </p>
                    </div>
                )}
            </>
        )
    }

    return (
        <ModalWrapper formId='newBusiness' closeModal={() => closeNewBusinessModal(false)} shouldConfirmClose>
            <div className=' relative overflow-scroll'>
                {step > 1 ? (
                    <SettingUp />
                ) : (
                    <div className='max-h-[630px] w-screen lg:w-[650px]'>
                        <div className='border-b border-[#DADCE0] p-5 pt-3  flex justify-end fixed top-0 w-full bg-white rounded-t-lg  z-20'>
                            <div className='flex items-center w-[63%] justify-between h-full'>
                                <h2 className='font-semibold text-[#31254B] text-lg'>Add new business</h2>
                                <div
                                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                    onClick={() => closeNewBusinessModal(false)}
                                >
                                    <AiOutlineClose fontSize={25} />
                                </div>
                            </div>
                        </div>
                        <form
                            id='newBusiness'
                            onSubmit={(e: React.FormEvent) => e.preventDefault()}
                            className='mt-14 mb-8 text-sm'
                        >
                            <div className='p-6 pb-0'>
                                <div className={`flex flex-col md:flex-row gap-4 mb-3 `}>
                                    <div className='flex flex-col flex-1 gap-2'>
                                        <label className='flex items-center gap-1'>
                                            First name
                                            <span className=''>*</span>
                                        </label>
                                        <Input
                                            type='text'
                                            name='first_name'
                                            value={user?.first_name}
                                            className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>

                                    <div className='flex flex-col flex-1 gap-2'>
                                        <label className='flex items-center gap-1'>
                                            Last name
                                            <span className=''>*</span>
                                        </label>
                                        <Input
                                            type='text'
                                            name='last_name'
                                            value={user?.last_name}
                                            className=' border-[#DADCE0]  h-[3rem] bg-[#F9F9F9] text-[#A8A8A8]'
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col flex-1 gap-2 mb-3'>
                                    <label className='flex items-center gap-1'>
                                        Organization name
                                        <span className=''>
                                            <Info color='#A8A8A8' size={15} />
                                        </span>
                                    </label>
                                    <Input
                                        type='text'
                                        name='org_name'
                                        value={formState.org_name}
                                        placeholder='Enter organization name'
                                        className=' border-[#DADCE0] h-[3rem]'
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className='flex flex-col md:flex-row gap-4 mb-3'>
                                    <div className='flex flex-col flex-1 gap-2 w-full lg:w-1/2'>
                                        <label className='flex items-center gap-1'>
                                            Organization type
                                            <span>*</span>
                                        </label>
                                        <div>
                                            <Select
                                                name='org_type'
                                                value={selectedOrgType}
                                                onValueChange={setSelectedOrgType}
                                            >
                                                <SelectTrigger className='w-full h-[3rem] border-[#DADCE0]'>
                                                    <SelectValue placeholder='Select organization type' />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {orgTypes.map(orgType => (
                                                        <SelectItem value={orgType} key={orgType}>
                                                            {orgType}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='flex flex-col flex-1 gap-2 w-full lg:w-1/2'>
                                        <label className='flex items-center gap-1'>
                                            Work email
                                            <span>*</span>
                                        </label>
                                        <Input
                                            type='email'
                                            value={formState?.email}
                                            name='email'
                                            onChange={handleInputChange}
                                            className='border border-[#DADCE0] h-[3rem] '
                                        />
                                        <span className='text-xs text-[#CF491E] whitespace-nowrap '>
                                            {errInEmail && `An email different from ${user?.email}`}
                                        </span>
                                    </div>
                                </div>
                                <div className='flex flex-col md:flex-row gap-4 mb-3'>
                                    <div className='flex flex-col flex-1 gap-2'>
                                        <label className='flex items-center gap-1'>
                                            Phone
                                            <span>*</span>
                                        </label>
                                        <Input
                                            type='number'
                                            value={user?.phone}
                                            name='phone'
                                            onChange={handleInputChange}
                                            placeholder='e.g 09012345678'
                                            className={`border border-[#DADCE0] h-[3rem]  ${(user?.phone?.length as number) > 1 ? 'bg-[#F9F9F9] text-[#A8A8A8]' : 'text-[#000]'}`}
                                            disabled={(user?.phone?.length as number) > 1}
                                        />
                                    </div>
                                    <div className='flex flex-col flex-1 gap-2'>
                                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                            Industry *
                                        </label>

                                        <Select
                                            name={'industry'}
                                            onValueChange={setSelectedIndustry}
                                            value={selectedIndustry}
                                        >
                                            <SelectTrigger className='h-12 border border-[#DADCE0] text-sm'>
                                                <SelectValue placeholder='Select Industry' />
                                            </SelectTrigger>
                                            <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                                {kyb.industries.map(industry => (
                                                    <SelectItem value={industry.title}>{industry.title}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div className='flex flex-col flex-1 gap-2'>
                                    <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                        Description
                                    </label>
                                    <Textarea
                                        className='w-full min-w-full resize-none border-[#DADCE0]'
                                        placeholder={`Enter company description`}
                                        name={'description'}
                                        maxLength={120}
                                        value={formState.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className='sticky bg-white bottom-0 w-full flex items-center justify-end gap-4 px-6 py-2 rounded-b-2xl'>
                            <Button
                                className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                                onClick={handleFormSubmit}
                                disabled={sameEmail || disableButton()}
                            >
                                Add new business
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </ModalWrapper>
    )
}

export default AddNewBusinessModal

import { useAuthContext } from '@/context'
import PageContent from '@/layout/PageContent'
import { isAdmin } from '@/utils/permissions'
import { useMemo, useState } from 'react'
import { AdminHelperSteps, UserHelperSteps } from './components/HelperSteps'
import FundWalletRoot from '@/components/Modals/FundWallet/FundWalletRoot'
import { OverviewCards } from './components/new/OverviewCards'
import { UrgentActions } from './components/new/UrgentActions'
import { OverviewCharts } from './components/new/OverviewCharts'
import { RecentActivities } from './components/new/RecentActivities'
import { EfficiencyRating } from './components/new/EfficiencyRating'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { BankAccountConnectionEnum, DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const NewOverview = () => {
    const { selectedAccount } = useAuthContext()
    const adminRoute = isAdmin(selectedAccount!)
    const [showModal, setShowModal] = useState(false)

    const { data, isLoading } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(
        () =>
            data?.filter(account => account.connection_type === BankAccountConnectionEnum.LINK) ??
            ([] as DedicatedBankAccountType[]),
        [data]
    )
    const totalAmount = bankAccounts?.reduce((acc, cur) => acc + cur?.balance, 0)

    const card_data = [
        {
            heading: 'Bank account balance',
            amount: formatAmount(totalAmount),
            action: '/dashboard/accounts/linked_accounts',
            change: '',
            subText: 'View accounts',
            options: [],
        },
        {
            heading: 'Outflow',
            amount: '₦210,000.00',
            action: '/dashboard/accounts/linked_accounts',
            change: '-16%',
            subText: 'Compared to last month',
            options: [],
        },
        {
            heading: 'Inflow',
            amount: '₦1,025,000.00',
            action: '/dashboard/accounts/linked_accounts',
            change: '+12%',
            subText: 'Compared to last month',
            options: [],
        },
    ]

    return (
        <PageContent>
            {adminRoute && <AdminHelperSteps setShowModal={setShowModal} />}
            {!adminRoute && <UserHelperSteps />}
            <section className='w-full pb-[6rem] py-6'>
                <h1 className='mb-5 text-lg font-semibold'>Overview</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {isLoading
                        ? [1, 2, 3].map(num => (
                              <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={175} key={num}>
                                  <Skeleton />
                              </SkeletonTheme>
                          ))
                        : card_data?.map((card, idx) => <OverviewCards card={card} key={idx} />)}
                </div>
                <div className='mt-4'>
                    <UrgentActions />
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
                    <OverviewCharts />
                    <RecentActivities isPriviledgedAdmin={true} />
                </div>
                <div className='mt-4'>
                    <EfficiencyRating />
                </div>
                {showModal && <FundWalletRoot setShowModal={setShowModal} />}
            </section>
        </PageContent>
    )
}

import { Button } from '@/components'

import ModalWrapper from '../../ModalWrapper'
import { ChartOfAccountType } from '@/types/accounting'
import { useDeleteCOA } from '@/services/accounting/mutations'

type Props = {
    closeModal: () => void
    selectedCoa?: ChartOfAccountType
}
const DeleteCOANoTransaction = ({ closeModal, selectedCoa }: Props) => {
    const { mutate: deleteSingleCOA, isLoading } = useDeleteCOA({
        onSuccess: closeModal,
        queryParams: {
            id: (selectedCoa?._id ?? selectedCoa?.id) as string,
        },
    })

    return (
        <ModalWrapper closeModal={closeModal}>
            <main className='h-[19.5rem] lg:h-auto w-screen lg:w-[30rem]'>
                <section className='m-6'>
                    <h3 className='font-medium opacity-90'>Confirm account deletion</h3>

                    <p className='text-gray7 my-4 text-sm tracking-wide leading-6 font-medium'>
                        Deleting <span className='text-brand'>{selectedCoa?.account_name}</span> account will remove it
                        permanently from your chart of accounts. This action cannot be undone. Are you sure you want to
                        proceed?
                    </p>
                </section>
                <div className='mt-5 border-t border-gray5'>
                    <div className='flex gap-5 justify-end mr-6 my-4'>
                        <Button
                            size={'lg'}
                            className='bg-transparent text-[#202020] hover:bg-transparent text-xs min-w-[120px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button
                            loading={isLoading}
                            size={'lg'}
                            variant={'error'}
                            onClick={() => deleteSingleCOA({})}
                            className=' text-white text-xs min-w-[130px] '
                        >
                            Yes, delete account
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default DeleteCOANoTransaction

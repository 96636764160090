import * as React from 'react'
import { VariantProps, cva } from 'class-variance-authority'
import { Loader2 } from 'lucide-react'
import { cn } from '@/lib/styling'

const buttonVariants = cva(
    'active:scale-95 min-w-[165px] flex min-h-[50px] items-center justify-center gap-3 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none transition-all',
    {
        variants: {
            variant: {
                default: 'bg-brand text-white hover:bg-darkbrand disabled:bg-[#D5D5F5]',
                outline: 'text-[#5E5E5E] bg-transparent border rounded-md p-0',
                secondaryOutline:
                    'bg-transparent border hover:border-[#CF491E]/30  hover:bg-transaparent text-[#CF491E]   disabled:bg-transparent disabled:opacity-50',
                secondary: 'bg-[#5E5E5E] text-[#fff] hover:opacity-95 ',
                error: 'bg-[#CF491E]  text-[#fff]  disabled:opacity-50 hover:opacity-70',
            },
            size: {
                default: 'h-10 py-4 px-6',
                sm: ' min-w-[130px] min-h-[35px]',
                lg: ' min-w-[140px] min-h-[40px]',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    loading?: boolean
    icon?: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, children, loading, disabled, icon, ...props }, ref) => {
        const [coords, setCoords] = React.useState({ x: -1, y: -1 })
        const [isRippling, setIsRippling] = React.useState(false)

        React.useEffect(() => {
            if (coords.x !== -1 && coords.y !== -1) {
                setIsRippling(true)
                setTimeout(() => setIsRippling(false), 300)
            } else setIsRippling(false)
        }, [coords])

        React.useEffect(() => {
            if (!isRippling) setCoords({ x: -1, y: -1 })
        }, [isRippling])

        return (
            <button
                disabled={disabled || loading}
                data-testid={props.name}
                className={cn(buttonVariants({ variant, size, className }), 'ripple-button')}
                onClick={e => {
                    const rect = (e.target as HTMLElement)?.getBoundingClientRect()
                    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top })
                }}
                ref={ref}
                {...props}
            >
                {isRippling ? (
                    <span
                        className='ripple'
                        style={{
                            left: coords.x,
                            top: coords.y,
                        }}
                    />
                ) : (
                    ''
                )}
                {children}
                {loading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
                {icon && !loading && icon}
            </button>
        )
    }
)
Button.displayName = 'Button'

export { Button }

import { ArrowLeft } from 'lucide-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import downArrow from '@/assets/down-arrow.svg'
import { useAuthContext, usePayrollContext } from '@/context'
import { useMemo } from 'react'
import { PayrollHistoryType } from '@/types/payroll'
import { capitalizeText } from '@/utils/string'
import { formatAmount } from '@/utils/money'
import { calculatePayrollSum, getPayDates } from '@/modules/dashboard/Payroll/utils'
import { addDays, differenceInDays, format, getDay, getYear } from 'date-fns'
import { useGetUserPayrollHistoryById } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'

export default function MoreDetailsPayHistory() {
    const navigate = useNavigate()
    const { userPayrollHistory } = usePayrollContext()
    const { selectedAccount } = useAuthContext()

    const [urlSearchParams] = useSearchParams()

    const id = urlSearchParams.get('id')

    const { data, isLoading } = useGetUserPayrollHistoryById({
        queryParams: {
            id: id as string,
        },
    })

    const selectedPayHistory = useMemo(
        () => userPayrollHistory ?? data ?? ({} as PayrollHistoryType),
        [userPayrollHistory, data]
    )

    const day = getDay(new Date(selectedPayHistory?.transaction?.createdAt))
    const year = getYear(new Date(selectedPayHistory?.transaction?.createdAt))
    const payDates = getPayDates(selectedPayHistory?.pay_frequency, day, year)

    const dayDifference = differenceInDays(payDates.lastDay, payDates.startDate)

    const payroll_details = [
        {
            name: 'Name',
            desc: `${capitalizeText(selectedAccount?.user?.first_name)} ${capitalizeText(
                selectedAccount?.user?.last_name
            )}`,
        },
        {
            name: 'Employee ID',
            desc: `${selectedAccount?.employee_id}`,
        },
        { name: 'Gross salary', desc: `${formatAmount(selectedPayHistory?.gross_salary)}` },
        {
            name: 'Total deductions',
            desc: formatAmount(
                calculatePayrollSum(selectedPayHistory?.deductions, 'amount', selectedPayHistory?.gross_salary)
            ),
        },

        {
            name: 'Bonuses',
            desc: formatAmount(
                calculatePayrollSum(selectedPayHistory?.bonuses, 'amount', selectedPayHistory?.gross_salary)
            ),
        },
        { name: 'Net salary', desc: formatAmount(selectedPayHistory?.net_salary) },
        {
            name: 'Pay period',
            desc: ` ${day ? `${format(new Date(payDates.startDate), 'dd/MM/yyyy')} - ${format(new Date(payDates.lastDay), 'dd/MM/yyyy')}` : 'N/A'}`,
        },
        {
            name: 'Next pay',
            desc: `${day ? format(addDays(payDates.lastDay, dayDifference), 'dd/MM/yyyy') : 'N/A'}`,
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <div className='h-screen overflow-scroll'>
            <section className='mx-[2.7rem] py-3 pt-2 mb-6 '>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className=' bg-white rounded-xl px-7 py-6 pb-11'>
                    <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                        <h2 className=' font-semibold text-lg'>
                            {payroll_details[2].desc} - {payroll_details[0].desc}
                        </h2>
                    </div>
                    {false && (
                        <div className='bg-[#F8F1DE] p-3 mt-6 rounded-xl'>
                            <h1 className='text-[#202020] font-semibold'>Attention. Missing details</h1>
                            <p className='text-sm text-[#5E5E5E] mt-1'>
                                This user has exhausted their Vacation leave allowance
                                <span className='font-semibold'> (15 days)</span>.
                            </p>
                        </div>
                    )}
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-8 text-base mb-12 max-w-[50rem] gap-x-[2.5rem] mt-8 '>
                        {payroll_details.map((detail, idx) => (
                            <>
                                <div className={`flex flex-col`} key={idx}>
                                    <h4 className='text-md font-semibold'>{detail.name}</h4>
                                    <span className='text-[#5E5E5E] text-[1rem] whitespace-nowrap'>{detail.desc}</span>
                                </div>
                                {idx === 10 && <br />}
                            </>
                        ))}
                    </div>
                </section>
            </section>
        </div>
    )
}

import ProgressBar from '@/components/ProgressBar'
import { useGetAllRequestAccess } from '@/services/auth/queries'
import React, { useEffect, useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import DirectoryRequestAccessTable from '../components/DirectoryRequestAccessTable'
import { ColumnSort, Pagination } from '@/types/utils'
import { debounce } from 'lodash'
import { SortColumn } from '@/utils/enum'
import { getSortString } from '@/components/Table/utils'

export default function BusinessDirectoryRequestAccess() {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNoClicked, setPageNoClicked] = useState(false)
    const [backspaceClicked, setBackSpaceClicked] = useState(false)

    const [searchQuery, setSearchQuery] = useState('')

    const [sort, setSort] = useState<SortColumn>()

    const handleSort = (sort: ColumnSort) => {
        setSort(sort)
    }

    const { data, isLoading, refetch, isRefetching } = useGetAllRequestAccess({
        queryParams: {
            page: pageNumber.toString(),
            limit: '10',
            search: searchQuery,
            sort: getSortString(sort as ColumnSort),
        },
    })

    useEffect(() => {
        if (pageNoClicked) {
            refetch()
            setPageNoClicked(false)
        }
        if (sort) {
            refetch()
        }
    }, [pageNoClicked, sort])

    const debouncedSearch = React.useRef(
        debounce(() => {
            refetch()
        }, 300)
    ).current

    useEffect(() => {
        if (backspaceClicked) {
            refetch()
            setBackSpaceClicked(false)
        }

        if (searchQuery) {
            debouncedSearch()
        }

        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, searchQuery, debouncedSearch, refetch])

    const requestAccessData = useMemo(() => data?.results ?? [], [data])

    const pagination = useMemo(() => data?.pagination ?? ({} as Pagination), [data])

    if (isLoading) return <ProgressBar />
    return (
        <>
            <section>
                <div className='my-7'>
                    <div className=''>
                        <h1 className='text-xl font-semibold'>Request Access</h1>
                        <p className='text-base text-[#5E5E5E] font-medium mt-2'>Manage Access Requests</p>
                    </div>
                </div>
                <div className='border border-[#DADCE0] h-[56px] w-auto lg:w-[35rem] rounded-lg flex items-center overflow-hidden'>
                    <div className='text-[#A8A8A8] flex items-center justify-center h-full px-3'>
                        <CiSearch fontSize={25} />
                    </div>
                    <input
                        type='text'
                        placeholder='Search by Org name'
                        className='flex-1 outline-none bg-[#FAFAFD]'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className={`mt-5 pb-32 overflow-x-scroll  ${isRefetching ? 'opacity-30' : ''}`}>
                    <DirectoryRequestAccessTable
                        pagination={pagination}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setPageNoClicked={setPageNoClicked}
                        requestAccessData={requestAccessData}
                        handleSort={handleSort}
                    />
                </div>
            </section>
        </>
    )
}

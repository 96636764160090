import { useAuthContext } from '@/context'
import { useGetTransactions } from '@/services/transactions/queries'
import { TransactionType, TransactionTypeType } from '@/types/transactions'
import { useMemo } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { capitalizeText } from '@/utils/string'
import { formatRelative } from 'date-fns'
import { RecentTransactionIcon } from '@/assets/assets.export'

type Props = { isPriviledgedAdmin: boolean }

export const RecentActivities = ({ isPriviledgedAdmin }: Props) => {
    const { selectedAccount } = useAuthContext()

    const { data: overview_data, isLoading } = useGetTransactions({
        enabled: isPriviledgedAdmin !== undefined,
        queryParams: {
            account_type: isPriviledgedAdmin ? 'Org' : 'UserOrgAccount',
            id: isPriviledgedAdmin ? (selectedAccount?.org?.id as string) : (selectedAccount?.id as string),
            limit: '5',
        },
    })
    const overviewTransaction = useMemo(() => overview_data?.results ?? ([] as TransactionType[]), [overview_data])

    return (
        <div className='border border-gray8 rounded-[20px] py-5 px-3 lg:px-6 bg-white'>
            <p className='text-lg font-semibold'>Recent activities</p>
            <p className='text-gray7 font-medium text-sm'>These are your most recent activities</p>
            <div className='mt-5 space-y-3'>
                {isLoading
                    ? [1, 2, 3, 4].map(num => (
                          <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={93} key={num}>
                              <Skeleton className='my-2' />
                          </SkeletonTheme>
                      ))
                    : overviewTransaction?.slice(0, 4)?.map(trans => (
                          <div className='py-3 px-2 lg:px-4 bg-offwhite border border-gray8 rounded-[8px]'>
                              <div className='flex w-full justify-between items-center font-semibold'>
                                  <p>{trans?.description}</p>
                                  <p className='text-gray7 text-sm'>
                                      {capitalizeText(formatRelative(new Date(trans?.createdAt), new Date()))
                                          ?.replaceAll('at', '@')
                                          .replace(/am|pm/gi, match => match.toUpperCase())}
                                      {/* @ {`${format(new Date(trans?.createdAt), 'hh:mm aa')}`} */}
                                  </p>
                              </div>
                              <div className='mt-2'>
                                  <p className='text-sm'>
                                      <span className='text-brand font-semibold'>
                                          {' '}
                                          {[
                                              TransactionTypeType.ACCOUNT_FUNDING,
                                              TransactionTypeType.WALLET_FUNDING,
                                              TransactionTypeType.TRANSFER,
                                          ].includes(trans?.type) ? (
                                              <>
                                                  {capitalizeText(trans?.initiated_by?.first_name)}{' '}
                                                  {capitalizeText(trans?.initiated_by?.last_name)}
                                              </>
                                          ) : (
                                              <>
                                                  {capitalizeText(trans?.credit?.account?.user?.first_name)}{' '}
                                                  {capitalizeText(trans?.credit?.account?.user?.last_name)}
                                              </>
                                          )}
                                      </span>{' '}
                                      submitted an expense report for Transportation with an amount of N7,945.00
                                  </p>
                              </div>
                          </div>
                      ))}
                {overviewTransaction?.length === 0 && (
                    <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                        <RecentTransactionIcon />
                        <h2 className='text-gray7 font-medium mt-5'>No activities yet</h2>
                        {/* <p className='text-sm'>No transaction has been made yet</p> */}
                    </div>
                )}
            </div>
        </div>
    )
}

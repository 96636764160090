import { Button } from '@/components'
import ModalWrapper from '../../ModalWrapper'
import { useMutateResolveOCRDiscrepancy } from '@/services/expenses/mutations'
import { useParams } from 'react-router-dom'
import { toastHandler } from '@/components/utils/Toast'

interface Props {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ResolveAIExpensePrompt({ closeModal }: Props) {
    const { expense_id } = useParams()

    const { mutate, isLoading } = useMutateResolveOCRDiscrepancy({
        queryParams: {
            id: expense_id!,
        },
        onSuccess: () => {
            closeModal(false)
            toastHandler({ message: 'Successfully resolved', state: 'success' })
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] max-w-[540px] px-3 lg:px-8 py-8 rounded-[1.3rem]'>
                Resolving this issue means you are okay with the details submitted. Do you still want to resolve this
                issue(s)?
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <button
                        disabled={isLoading}
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </button>
                    <Button loading={isLoading} onClick={() => mutate({})} className=''>
                        Yes, resolve
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}

import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Controller, useForm } from 'react-hook-form'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/Textarea'
import { Button } from '@/components/Button'
import { ChartOfAccountType } from '@/types/accounting'
import { useAccountingContext } from '@/context'
import { SelectSeparator } from '@radix-ui/react-select'
import { useCreateSingleCOA, useUpdateSingleCOA } from '@/services/accounting/mutations'

type FormType = {
    acct_type: string
    acct_name: string
    description: string
    acct_code?: number
}
type Props = {
    closeModal: () => void
    selectedCoa?: ChartOfAccountType
    isEdit?: boolean
}

const AddNewAccountModal = ({ closeModal, isEdit, selectedCoa }: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<FormType>({
        defaultValues: {
            acct_code: Number(selectedCoa?.code),
            acct_name: selectedCoa?.account_name,
            acct_type: selectedCoa?.account_type?.id,
            description: selectedCoa?.description,
        },
    })

    const { accounttingTypes } = useAccountingContext()

    const acctType = watch('acct_type')
    const descValue = watch('description')

    const { mutate: createSingleFn, isLoading } = useCreateSingleCOA({ onSuccess: closeModal })

    const { mutate: updateSingleFn, isLoading: isUpdating } = useUpdateSingleCOA({
        queryParams: { id: (selectedCoa?._id ?? selectedCoa?.id) as string },
        onSuccess: closeModal,
    })

    const onSubmit = (values: FormType) => {
        const data = {
            account_name: values?.acct_name,

            code: values?.acct_code?.toString(),
            description: values?.description,
        }
        if (isEdit) {
            updateSingleFn({
                ...data,
            })
            return
        }

        createSingleFn({
            ...data,
            account_type: values?.acct_type,
        })
    }
    return (
        <ModalWrapper>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className=' min-h-[18rem] w-[calc(100vw-25px)] md:w-[28rem] overflow-scroll'
            >
                <div className='flex items-center border-b border-[#DADCE0] p-3  justify-between'>
                    <h2 className='text-center text-lg font-semibold opacity-80'>
                        {isEdit ? 'Edit' : 'Add new'} account
                    </h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='px-5 space-y-4 mt-5'>
                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm' htmlFor='funding_method'>
                            Account type*
                        </label>
                        <Controller
                            name='acct_type'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                        disabled={isEdit}
                                        className=' text-sm  h-10  border-gray8 flex disabled:opacity-70'
                                        variant={acctType ? 'selected' : 'new'}
                                    >
                                        <SelectValue className='' placeholder={'Select account type'} />
                                    </SelectTrigger>
                                    <SelectContent className='w-[24.5rem] h-56'>
                                        {accounttingTypes?.map(type => (
                                            <SelectGroup className='my-1'>
                                                <SelectLabel className='text-black pl-2'>{type?.name}</SelectLabel>
                                                {type?.children.map(child => (
                                                    <SelectItem
                                                        className='text-gray7 pl-6'
                                                        value={child?._id ?? child?.id}
                                                    >
                                                        {child?.name}
                                                    </SelectItem>
                                                ))}
                                                <SelectSeparator />
                                            </SelectGroup>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </fieldset>
                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm ' htmlFor='funding_method'>
                            Account name*
                        </label>
                        <Controller
                            name='acct_name'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter account name'
                                    className='border-gray8 text-sm  h-10 placeholder:text-sm'
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset className='flex flex-col gap-1.5'>
                        <label className='text-sm flex items-center justify-between' htmlFor='funding_method'>
                            Description
                            <span className=' text-xs'>{descValue?.length ?? '0'}/120</span>
                        </label>
                        <Controller
                            name='description'
                            rules={{
                                required: true,
                                maxLength: 120,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    value={field.value as string}
                                    className='border border-[#DADCE0] pt-2 min-h-[2rem] text-sm resize-none placeholder:text-sm'
                                    maxLength={120}
                                    placeholder='Enter description'
                                />
                            )}
                        />
                    </fieldset>

                    <fieldset className='mt-5 flex flex-col gap-1.5'>
                        <label className='text-sm' htmlFor='funding_method'>
                            Account code*
                        </label>
                        <Controller
                            name='acct_code'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='number'
                                    placeholder='Enter account code'
                                    className=' border-gray8 text-sm w-56 h-10 placeholder:text-sm'
                                />
                            )}
                        />
                    </fieldset>
                </div>
                <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                    <Button
                        disabled={!isValid}
                        loading={isLoading || isUpdating}
                        type='submit'
                        className='text-sm min-h-[2.5rem] py-4'
                    >
                        {isEdit ? 'Edit' : ' Save new'} account
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}

export default AddNewAccountModal

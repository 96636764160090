import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import { CreateCategoryModal } from '@/components/Modals/Vault/CreateCategoryModal'
import { DeleteCategoryModal } from '@/components/Modals/Vault/DeleteCategory'
import { useGetVaultCatgeories } from '@/services/vault/queries'
import classNames from 'classnames'
import { Pen } from 'lucide-react'
import { useEffect, useState } from 'react'
import { CiSquarePlus } from 'react-icons/ci'
import { v4 as uuidv4 } from 'uuid'

const defaultCategory = {
    id: uuidv4(),
    name: '',
}
export type categoryProps = {
    id: string
    name: string
}
export const VaultCategories = () => {
    const [categories, setCategories] = useState<Partial<categoryProps>[]>([defaultCategory])
    const { data, isLoading } = useGetVaultCatgeories({})
    const [openModal, setOpenModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<categoryProps>()

    useEffect(() => {
        const mappedCategories = data?.map(category => ({
            id: category.id,
            name: category.name,
        }))
        setCategories(mappedCategories ?? [defaultCategory])
    }, [data])
    return (
        <div>
            <h1 className='text-dark font-medium text-lg'>Catgeories</h1>
            <p className='text-gray7 text-sm'>Manage vault categories</p>
            <div className={classNames('border border-gray8 rounded-xl px-3 py-4 mt-5', isLoading && 'opacity-30')}>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                    {categories
                        .slice(0)
                        .reverse()
                        .map((category, idx) => (
                            <div key={category.id} className='flex items-center gap-2'>
                                <p className='text-sm text-gray7 font-medium'>{idx + 1}.</p>
                                <input
                                    type='text'
                                    className='outline-none w-full ml-1 placeholder:text-sm h-[2.3rem] border border-[#DADCE0] rounded-lg px-3 placeholder:truncate focus:outline focus:outline-gray2'
                                    required
                                    defaultValue={category.name}
                                    placeholder='Enter category name'
                                    value={category.name}
                                    disabled
                                    onChange={e => {
                                        setCategories(field => {
                                            return field.map(data => {
                                                if (data.id === category?.id) {
                                                    data.name = e.target.value
                                                    return data
                                                }
                                                return data
                                            })
                                        })
                                    }}
                                />
                                <Pen
                                    onClick={() => {
                                        setSelectedCategory(category as unknown as categoryProps)
                                        setUpdate(true)
                                        setOpenModal(true)
                                    }}
                                    className='cursor-pointer'
                                />
                                <span
                                    className='cursor-pointer bg-[#FFEDED] rounded-full p-1 hidden'
                                    onClick={() => {
                                        setSelectedCategory(category as unknown as categoryProps)
                                        setOpenDeleteModal(true)
                                    }}
                                >
                                    <CancelIcon className='text-red h-5 w-5 !stroke-errorRed' />
                                </span>
                            </div>
                        ))}
                </div>
                <div className='mt-4 lg:pl-7 pt-3'>
                    <button
                        className='flex items-center gap-2 border border-[#454ADE] px-3 py-2 rounded-lg bg-[#EFEFF9] text-brand text-sm font-semibold mb-8'
                        onClick={() => {
                            setUpdate(false)
                            setOpenModal(true)
                        }}
                    >
                        <CiSquarePlus size={22} />
                        Add new category
                    </button>
                    <p className='h-[.5px] bg-gray8 w-full'></p>
                    <div className='mt-3 justify-end hidden'>
                        <Button className=''>Save changes</Button>
                    </div>
                </div>
            </div>
            {openModal && (
                <CreateCategoryModal
                    closeModal={setOpenModal}
                    update={update}
                    category={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
            )}
            {openDeleteModal && (
                <DeleteCategoryModal
                    closeModal={setOpenDeleteModal}
                    category={selectedCategory as categoryProps}
                    setSelectedCategory={setSelectedCategory}
                />
            )}
        </div>
    )
}

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { OverAPeriodIcon } from '@/assets/assets.export'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { ReactComponent as LineChart } from '@/assets/linechart.svg'
import { ReactComponent as BarChartIcon } from '@/assets/barchart.svg'

import { useEffect, useMemo, useState } from 'react'
import { formatAmountNoSymbol } from '@/utils/money'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useGetSpecificMonthTransact } from '@/services/transactions/queries'
import {
    calculateAverageTotal,
    findMonthWithHighestTotal,
    getFullMonthName,
} from '@/modules/dashboard/Transactions/components/utils'
import { useSearchParams } from 'react-router-dom'
import { TransactionsBarChart } from './TransactionsBarChart'
import { TransactionsLineChart } from './TransactionsLineChart'
import { cn } from '@/lib/styling'

const chartType = {
    barChart: 'bar-chart',
    lineChart: 'line-chart',
}

export const OverviewCharts = () => {
    const [chartTypeVal, setChartTypeVal] = useState(chartType?.barChart)
    const { selectedAccount } = useAuthContext()
    const [isSummaryYearClicked, setIsSummaryYearClicked] = useState(false)
    const { selectedCurrencyInfo } = useCurrencyContext()

    const [searchParams] = useSearchParams()
    const currency = searchParams.get('currency') as string

    const thisYear = new Date().getFullYear()
    const [transYear, setTransYear] = useState(thisYear + '')
    const [graph, setGraph] = useState('ivo')
    const { data, refetch, isLoading, isRefetching } = useGetSpecificMonthTransact({
        queryParams: {
            owner: selectedAccount?.org?.id as string,
            year: transYear as string,
        },
    })
    const chartData = useMemo(() => data ?? [], [data])
    const averageData = calculateAverageTotal(chartData)
    const highestMonth = findMonthWithHighestTotal(chartData)

    useEffect(() => {
        if (isSummaryYearClicked) {
            refetch()
        }
    }, [transYear])
    return (
        <div className='border border-gray8 rounded-[20px] px-3 lg:px-6 p-6 bg-white'>
            <section className=''>
                <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 items-start justify-between mb-6'>
                    <Select
                        name='graph'
                        onValueChange={data => {
                            setGraph(data)
                        }}
                        value={graph}
                    >
                        <SelectTrigger className='w-fit bg-white border border-gray8 text-gray7 p-3 whitespace-nowrap'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='w-[12rem]'>
                            <SelectItem value='ivo'>Inflow vs Outflow</SelectItem>
                            <SelectItem value='ovi'>Outflow vs Inflow</SelectItem>
                        </SelectContent>
                    </Select>
                    <div className='flex items-center h-full gap-3'>
                        {!isLoading && (
                            <>
                                <Select
                                    name='invoiceYear'
                                    onValueChange={data => {
                                        setTransYear(data)
                                        setIsSummaryYearClicked(true)
                                    }}
                                    value={transYear}
                                >
                                    <SelectTrigger className='w-full bg-offwhite border border-gray8 text-gray7'>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent className='w-[8rem]'>
                                        <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                        <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                    </SelectContent>
                                </Select>
                                <Tabs value={chartTypeVal} className='border bg-offwhite border-gray8 rounded-md'>
                                    <TabsList className='border-none pt-0 h-full flex items-center px-2'>
                                        <TabTriggersContainer className='border-none justify-center gap-4 p-1'>
                                            <TabsTrigger
                                                onClick={() => setChartTypeVal(chartType.barChart)}
                                                value={chartType.barChart}
                                                className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                            >
                                                <BarChartIcon className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                            </TabsTrigger>
                                            <TabsTrigger
                                                onClick={() => setChartTypeVal(chartType.lineChart)}
                                                value={chartType.lineChart}
                                                className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                            >
                                                <LineChart className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                            </TabsTrigger>
                                        </TabTriggersContainer>
                                    </TabsList>
                                </Tabs>
                            </>
                        )}
                    </div>
                </div>
                <div className=''>
                    {!(averageData.averageIncomingTotal == 0 && averageData.averageOutgoingTotal == 0) ? (
                        <div className='flex gap-3 items-center mt-2 text-xs font-medium'>
                            <p className='flex items-center'>
                                <span className='h-4 w-4 bg-brand mr-2 rounded'></span> Inflow
                            </p>
                            <p className='flex items-center'>
                                <p className='h-4 w-4 bg-[#D5D5F5] mr-2 rounded'>&nbsp;</p>Outflow
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {isLoading ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                        <Skeleton />
                    </SkeletonTheme>
                ) : (
                    <div className={cn(isRefetching && 'opacity-30')}>
                        {/*==== Bar chart ====*/}
                        {!(averageData.averageIncomingTotal == 0 && averageData.averageOutgoingTotal == 0) ? (
                            <>
                                <div className={`bg-white p-1 md:p-4 md:pl-0 rounded-md mt-6`}>
                                    {chartTypeVal === chartType?.barChart && (
                                        <TransactionsBarChart chartData={chartData} currency={currency} />
                                    )}
                                    {chartTypeVal === chartType?.lineChart && (
                                        <TransactionsLineChart chartData={chartData} currency={currency} />
                                    )}
                                </div>
                                <div className='px-3 md:px-4 py-3 bg-offwhite rounded-lg '>
                                    The most money spent was in the month of{' '}
                                    <span className='font-semibold text-brand'>
                                        {highestMonth?.monthWithHighestOutgoingTotal &&
                                            getFullMonthName(highestMonth.monthWithHighestOutgoingTotal?.name)}
                                    </span>{' '}
                                    --{' '}
                                    <span className='font-semibold'>
                                        {selectedCurrencyInfo(currency)?.symbol}
                                        {formatAmountNoSymbol(
                                            highestMonth.monthWithHighestOutgoingTotal?.outgoingTotal
                                        )}
                                    </span>{' '}
                                    {/* <br /> */}
                                    while the least money spent was in the month of{' '}
                                    <span className='font-semibold text-brand'>
                                        {highestMonth?.monthWithHighestIncomingTotal &&
                                            getFullMonthName(highestMonth.monthWithHighestIncomingTotal?.name)}
                                    </span>{' '}
                                    --{' '}
                                    <span className='font-semibold'>
                                        {' '}
                                        {selectedCurrencyInfo(currency)?.symbol}
                                        {formatAmountNoSymbol(
                                            highestMonth.monthWithHighestIncomingTotal?.incomingTotal
                                        )}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                                    <OverAPeriodIcon />
                                    {/* <h2 className='text-gray7 font-medium mt-5'>No transactions</h2> */}
                                    <p className='text-sm mt-6'>No transactions here yet</p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </section>
        </div>
    )
}

import { ArrowDown } from 'lucide-react'
import { RadialBarChart, RadialBar, PolarAngleAxis, ResponsiveContainer } from 'recharts'

const EfficiencyChart = () => {
    const data = [{ name: 'Effective Workflow', value: 87 }]

    return (
        <div className='flex flex-col items-center relative'>
            <div className='h-[216px] w-full flex items-center justify-center overflow-clip '>
                <ResponsiveContainer height={281} width={'100%'} className={'mx-auto'}>
                    <RadialBarChart
                        cx='50%'
                        cy='65%'
                        innerRadius='180%'
                        outerRadius='100%'
                        barSize={20}
                        data={data}
                        startAngle={195}
                        endAngle={-15}
                    >
                        <defs>
                            <linearGradient id='gaugeGradient' x1='0' y1='0' x2='1' y2='1'>
                                <stop offset='0%' stopColor='#DDFCA4' />
                                <stop offset='40%' stopColor='#45DE7D69' />
                                <stop offset='100%' stopColor='#09D65E' />
                            </linearGradient>
                        </defs>
                        {/* Hide axis ticks and define domain as 0 to 100 */}
                        <PolarAngleAxis type='number' domain={[0, 100]} tick={false} />

                        <RadialBar
                            dataKey='value'
                            cornerRadius={10}
                            background={{ fill: '#D9D9D9' }}
                            fill='url(#gaugeGradient)'
                        />
                    </RadialBarChart>
                </ResponsiveContainer>
            </div>
            {/* Labels below the chart */}
            <div className='absolute text-center top-16'>
                <p className='text-[40px] font-extrabold -mb-1'>87%</p>
                <p className='text-sm text-gray7'>Effective workflow</p>
                <p className='text-errorRed text-xs flex items-center font-semibold mt-4'>
                    <ArrowDown size={16} className='mr-0.5' /> 12%
                    <span className='text-black ml-3'>Compared to last week</span>
                </p>
            </div>
        </div>
    )
}

export default EfficiencyChart

import { Menu } from '@headlessui/react'
import { UserAccount } from '@/services/payout/types'
import { ExpenseType } from '@/types/expense'
import React, { useState } from 'react'
import { useAuthContext, useExpenseContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import { ExpenseStatus } from '../const'
import { Button } from '@/components'
import ReimburseExpenseModal from '@/components/Modals/Expense/ReimburseExpense/ReimburseExpenseMondal'
import {
    useMutateSubmitDraftExpense,
    useMutationAuthorizeExpense,
    useOwnerOverrideExpense,
} from '@/services/expenses/mutations'
import { CgSpinner } from 'react-icons/cg'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { EditExpenseButtonAction } from './ExpenseAuthorizePermission'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { expenseSidebarPermissions } from '@/layout/LayoutComponents/utils'
import DeclineExpenseReasonModal from '@/components/Modals/Expense/DeclineExpense'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { Link2, ArrowLeftRight } from 'lucide-react'
import MatchExpenseToAccountModal from '@/components/Modals/Expense/MatchExpenseToAccountModal'
import { prodEnvironment } from '@/utils/utils'

type Props = {
    expense: ExpenseType
    hasAPrimaryBank: () => boolean
    submitterAccount: UserAccount[]
    setExpenseDetails: React.Dispatch<React.SetStateAction<ExpenseType>>
    isRefetchingExpense: boolean
    setLinkBudgetModal: React.Dispatch<React.SetStateAction<boolean>>
}

const OwnerAuthorizeExpensePermission = ({
    expense,
    setLinkBudgetModal,
    hasAPrimaryBank,
    isRefetchingExpense,
    setExpenseDetails,
    submitterAccount,
}: Props) => {
    const [reimburseModal, setReimburseModal] = useState(false)
    const { editModal, setEditModal, categories } = useExpenseContext()
    const [declineExpenseModal, setDeclineExpenseModal] = useState(false)

    const [matchAccount, setMatchAccount] = useState(false)

    const hasPermission = useHasAccessPermisson(expenseSidebarPermissions)

    const { mutate: authorizeExpense, isLoading: isAuthorizationLoading } = useMutationAuthorizeExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            toastHandler({
                message: 'Expense Approved',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })

    const { mutate: submitDraftExpense, isLoading: isSubmitting } = useMutateSubmitDraftExpense({
        queryParams: { id: expense?.id as string },
        onSuccess: data => {
            toastHandler({
                message: 'Draft submitted',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })

    const { mutate: overrideExpenseApproval, isLoading } = useOwnerOverrideExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })
    const handleAccountCheck = () => {
        if (hasAPrimaryBank()) {
            setReimburseModal(true)
            return
        }
        toastHandler({ message: 'User does not have any bank as primary', state: 'error' })
    }

    const handleAuthorizeExpense = (status: string, override?: boolean) => {
        if (override) {
            overrideExpenseApproval(
                { approve: status === ExpenseStatus.approved },
                {
                    onSuccess: () => {
                        toastHandler({
                            message:
                                status === ExpenseStatus.approved ? 'Expense Approved (Override)' : 'Expense Declined',
                            state: 'success',
                        })
                    },
                }
            )

            return
        }
        authorizeExpense({ approve: status === ExpenseStatus.approved })
    }
    const { selectedAccount } = useAuthContext()
    const isPartOfApprover = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && !approver?.approved
    )

    // if (expense.status === ExpenseStatus.reimbursed) return

    return (
        <>
            <div className='flex flex-col lg:flex-row flex-1'>
                {expense?.status === ExpenseStatus.draft && (
                    <Button
                        loading={isSubmitting}
                        disabled={isAuthorizationLoading || isRefetchingExpense}
                        onClick={() => submitDraftExpense({})}
                        className='bg-brand  min-w-[130px] min-h-[40px]'
                    >
                        Submit Expense
                    </Button>
                )}
                {expense?.status !== ExpenseStatus.draft &&
                    hasPermission &&
                    (expense?.reimbursable &&
                    (expense?.status === ExpenseStatus.approved || expense?.status === ExpenseStatus.processing) ? (
                        <div className='flex justify-end lg:justify-center flex-1  '>
                            <Button
                                onClick={handleAccountCheck}
                                className='min-w-fit px-6 py-2 min-h-fit bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white'
                                disabled={expense?.status === ExpenseStatus.processing || isRefetchingExpense}
                            >
                                {expense?.status === ExpenseStatus.processing ? 'Processing' : 'Reimburse'} expense
                            </Button>
                        </div>
                    ) : expense?.status !== ExpenseStatus.approved &&
                      expense?.status !== ExpenseStatus.denied &&
                      expense?.status !== ExpenseStatus.reimbursed ? (
                        <div className='flex gap-3 justify-center flex-1'>
                            <Button
                                className='min-w-fit rounded-lg min-h-fit whitespace-nowrap py-2 px-6'
                                variant={'secondaryOutline'}
                                onClick={() => {
                                    setDeclineExpenseModal(true)
                                }}
                                disabled={isAuthorizationLoading || isRefetchingExpense || isLoading}
                            >
                                Decline
                            </Button>
                            <TooltipProvider delayDuration={10}>
                                <Menu as={'animate'} className={'relative'}>
                                    <Menu.Button
                                        disabled={isRefetchingExpense || isLoading || isAuthorizationLoading}
                                        className={
                                            'whitespace-nowrap disable:opacity-60 bg-[#19C37D] hover:bg-[#316c53] text-white rounded-md text-sm py-2.5 px-6 flex items-center gap-2'
                                        }
                                    >
                                        Approve Expense
                                        {(isLoading || isAuthorizationLoading) && (
                                            <CgSpinner className='animate-spin' size={15} />
                                        )}
                                    </Menu.Button>
                                    <Menu.Items
                                        className={
                                            'bottom-9 absolute bg-white border border-gray5 rounded-md w-full p-2 space-y-3'
                                        }
                                    >
                                        <Menu.Item>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-2' type='button'>
                                                    <button
                                                        onClick={() => handleAuthorizeExpense(ExpenseStatus.approved)}
                                                        disabled={
                                                            !isPartOfApprover ||
                                                            isRefetchingExpense ||
                                                            isLoading ||
                                                            isAuthorizationLoading
                                                        }
                                                        className='disabled:opacity-50 disabled:cursor-not-allowed text-xs font-medium text'
                                                    >
                                                        Approve (Policy)
                                                    </button>
                                                </TooltipTrigger>

                                                <TooltipContent>
                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                        Approve based on your role in this expense policy
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-2' type='button'>
                                                    <button
                                                        onClick={() =>
                                                            handleAuthorizeExpense(ExpenseStatus.approved, true)
                                                        }
                                                        className='text-xs font-medium disabled:opacity-50 disabled:cursor-not-allowed'
                                                        disabled={
                                                            isRefetchingExpense || isLoading || isAuthorizationLoading
                                                        }
                                                    >
                                                        Approve (Override)
                                                    </button>
                                                </TooltipTrigger>

                                                <TooltipContent>
                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                        Approve as an authorized user outside this expense policy
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            </TooltipProvider>
                        </div>
                    ) : (
                        <></>
                    ))}

                {reimburseModal && (
                    <ReimburseExpenseModal
                        setShowModal={setReimburseModal}
                        accounts={submitterAccount}
                        expense={expense}
                    />
                )}
                {editModal && (
                    <ExpenseFormRoot
                        setShowModal={setEditModal}
                        selectedExpense={expense!}
                        categories={categories}
                        hasAPrimaryBank={!hasAPrimaryBank()}
                        edit={true}
                    />
                )}

                {declineExpenseModal && (
                    <DeclineExpenseReasonModal
                        closeModal={() => setDeclineExpenseModal(false)}
                        expenseId={expense?.id}
                        setExpenseDetails={setExpenseDetails}
                    />
                )}

                {matchAccount && (
                    <MatchExpenseToAccountModal closeModal={() => setMatchAccount(false)} expense={expense} />
                )}
            </div>
            <p className='bg-gray8 w-[1px] h-7'>&nbsp;</p>
            <Menu as='div' className='relative'>
                <Menu.Button className='border border-gray8 p-1.5 rounded-lg'>
                    <FaEllipsisVertical color='#A8A8A8' size={24} />
                </Menu.Button>
                <Menu.Items className='w-[210px] flex flex-col gap-4 shadow-2xl absolute right-0 p-4 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                    {
                        <Menu.Item
                            as={'div'}
                            className={'cursor-pointer hover:underline text-sm text-[#5E5E5E] font-medium'}
                        >
                            <button
                                className='w-full disabled:opacity-30 flex gap-2 items-center'
                                onClick={() => setLinkBudgetModal(true)}
                                disabled={expense?.status === 'denied'}
                            >
                                <Link2 color='#5E5E5E' size={20} /> Link to a budget
                            </button>
                        </Menu.Item>
                    }
                    {!prodEnvironment() && (
                        <Menu.Item
                            as={'div'}
                            onClick={() => setMatchAccount(true)}
                            className={
                                'flex gap-2 items-center cursor-pointer hover:underline text-sm text-[#5E5E5E] font-medium'
                            }
                        >
                            <ArrowLeftRight color='#5E5E5E' size={20} /> Match to an account
                        </Menu.Item>
                    )}
                    {
                        <EditExpenseButtonAction
                            expense={expense}
                            setEditModal={setEditModal}
                            isRefetchingExpense={isAuthorizationLoading || isRefetchingExpense || isLoading}
                        />
                    }
                </Menu.Items>
            </Menu>
        </>
    )
}

export default OwnerAuthorizeExpensePermission

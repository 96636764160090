import { PlusSquare } from 'lucide-react'
import { useMemo, useState } from 'react'
import { DocumentsNav } from '../../components/DocumentsNav/DocumentsNav'
import { AddNewFileModal, convertToBytesText } from '@/components/Modals/Vault/AddNewFileModal'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { UploadingPopup } from '@/components/Modals/Vault/UploadingPopup'
import {
    useGetAllVaults,
    useGetVaultCatgeories,
    useGetVaultItems,
    useGetVaultStorageDetails,
} from '@/services/vault/queries'
import ProgressBar from '@/components/ProgressBar'
import { NoVaultComponent } from '../../components/NoVaultComponent'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ItemsView } from '../../components/FolderNavigation/ItemsView'
import FilterVault from '../../components/AdvancedFilter/VaultsAdvanceFilter'
import { VaultCategory, VaultItem } from '@/services/vault/types'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext } from '@/context'
import { Menu } from '@headlessui/react'
import { FolderIcon, LockedFolder, NewDoc, NewFolder } from '@/assets/assets.export'
import { ActivateVaultModal } from '@/components/Modals/Vault/ActivateVaultModal'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'
import UpgradeVaultPlan from '@/components/Modals/Vault/UpgradeVaultPlan'
import FilterVaultChip from '../../components/AdvancedFilter/FilterVaultChip'

export const VaultDocuments = () => {
    const [createFolder, setCreateFolder] = useState(false)
    const [showAddNewFile, setShowAddNewFile] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [showUpgradeVault, setShowUpgradeVault] = useState(false)
    const [vaultId, setVaultId] = useState('')
    const [showVaultPassword, setShowVaultPassword] = useState(false)
    const { enabled: isVaultEnabled } = useAccessToModule({ module: ModuleNameEnum.VAULT })

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const [searchParams] = useSearchParams()

    const { data: vaults, isLoading: isLoadingVaults } = useGetAllVaults({})
    const vaultsData = useMemo(() => vaults ?? [], [vaults])
    const singleVault = vaultsData?.[0]

    const { data: vaultItems, isLoading: isLoadingVaultItems } = useGetVaultItems({
        queryParams: {
            vault: singleVault?.id,
            parent: searchParams.get('folder') ?? singleVault?.id,
            visibility: 'general',
        },
        enabled: !!vaults && !searchParams.get('folder'),
    })

    const { data: vaultStorage, isLoading: isLoadingStorage } = useGetVaultStorageDetails({
        enabled: !!vaults,
    })

    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])
    const { data: vaultCategories } = useGetVaultCatgeories({
        enabled: !!vaults,
    })

    const navigate = useNavigate()

    const folders = vaultItems?.filter(item => item.type === 'folder')
    if (isLoadingVaults || isLoadingVaultItems || isLoadingStorage) return <ProgressBar />
    // h-screen overflow-scroll
    return (
        <section className={` pb-6`}>
            <div className='block lg:flex justify-between mb-6 lg:mb-0 relative'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>
                        Welcome to {vaults && vaults?.length > 0 && `"${vaults?.[0]?.name}"`} vault
                    </h1>
                    <p className='text-gray7 text-sm'>
                        Easily upload, share, and manage all your business documents in one place.
                    </p>
                </div>

                <UploadingPopup />
                {showFilter && (
                    <FilterVault
                        closeModal={setShowFilter}
                        vaults={vaultItems as VaultItem[]}
                        orgMembers={orgMembers}
                        categories={vaultCategories}
                    />
                )}
            </div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center lg:items-start '>
                {vaultsData?.length > 0 && (
                    <div className='w-full lg:w-[23%] bg-white p-2 lg:p-5 flex items-center flex-col justify-between gap-3 rounded-xl lg:min-h-[598px]'>
                        <div className='flex flex-row items-center lg:flex-col gap-3 w-full'>
                            <Menu as='div' className='relative w-[88px] lg:w-full'>
                                <Menu.Button
                                    onClick={() => {
                                        if (!isVaultEnabled) {
                                            setShowUpgradeModal(true)
                                            setCurrentModule(ModuleNameEnum.VAULT)
                                            return
                                        }
                                    }}
                                    className='bg-[#EFEFF9] text-sm font-semibold py-2 flex items-center justify-center gap-2 w-[88px] lg:w-full rounded-lg px-3 text-brand border border-transparent hover:border-brand h-fit'
                                >
                                    <PlusSquare size={20} /> New
                                </Menu.Button>
                                <Menu.Items className='w-[165px] lg:w-full shadow-xl flex flex-col absolute left-0 py-2 px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                                    <Menu.Item>
                                        <span
                                            className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                            onClick={() => {
                                                setShowAddNewFile(true)
                                            }}
                                        >
                                            <NewDoc />
                                            Upload file(s)
                                        </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <span
                                            className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                            onClick={() => {
                                                setCreateFolder(true)
                                            }}
                                        >
                                            <NewFolder />
                                            Create folder
                                        </span>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                            <div className='flex flex-row w-full lg:flex-col gap-3 justify-between'>
                                <p
                                    className={`cursor-pointer text-sm font-semibold ${searchParams.size === 0 && 'text-brand'} border-l border-gray8 pl-3 lg:pl-0 lg:border-none`}
                                    onClick={() => navigate('')}
                                >
                                    Home
                                </p>
                                <p
                                    onClick={() => navigate('?access=personal')}
                                    className={`cursor-pointer text-sm font-medium hidden lg:flex items-center gap-2 ${searchParams.get('access') === 'personal' ? 'text-brand' : 'text-gray7'}`}
                                >
                                    <LockedFolder /> Personal Folder
                                </p>
                                <p
                                    onClick={() => navigate('?access=shared')}
                                    className={`cursor-pointer text-sm font-medium hidden lg:flex items-center gap-2 ${searchParams.get('access') === 'shared' ? 'text-brand' : 'text-gray7'}`}
                                >
                                    <FolderIcon /> Shared to others
                                </p>
                                <DocumentsNav folders={folders ?? []} />
                            </div>
                        </div>
                        <div className='hidden lg:block w-full border border-gray8 p-3 pt-4 rounded-lg'>
                            <div className='w-full h-2 bg-[#F2F2F2] rounded-[40px] overflow-clip'>
                                <div
                                    className={'bg-[#5C9BFF] rounded-l-[40px] h-2'}
                                    style={{
                                        width: vaultStorage
                                            ? `${Math.ceil((vaultStorage?.usedStorageSize.overallStorageSize / (vaultStorage?.usedStorageSize.overallStorageSize + vaultStorage?.remainingStorageSize)) * 100)}%`
                                            : '0%',
                                    }}
                                ></div>
                            </div>
                            <p className='mt-3 text-sm text-gray7 font-medium'>
                                {convertToBytesText(vaultStorage?.usedStorageSize.overallStorageSize as number)} of{' '}
                                {convertToBytesText(
                                    Number(vaultStorage?.usedStorageSize.overallStorageSize) +
                                        Number(vaultStorage?.remainingStorageSize)
                                )}{' '}
                                used
                            </p>
                            <button
                                onClick={() => setShowUpgradeVault(true)}
                                className='py-2 w-full rounded-lg text-center text-sm font-medium text-gray7 border border-gray8 mt-6'
                            >
                                Get more storage
                            </button>
                        </div>
                    </div>
                )}
                <div
                    className={`${vaultsData?.length === 0 ? 'w-full mt-8' : 'w-full lg:w-[74%]'} bg-white rounded-2xl pt-5 pb-8 px-6 lg:min-h-[598px]`}
                >
                    {vaultsData?.length === 0 ? (
                        <>
                            <NoVaultComponent setShowVaultPassword={setShowVaultPassword} setVaultId={setVaultId} />
                        </>
                    ) : (
                        <>
                            <FilterVaultChip categories={vaultCategories as VaultCategory[]} orgMembers={orgMembers} />
                            <ItemsView setShowFilter={setShowFilter} />
                        </>
                    )}
                </div>
            </div>
            {showAddNewFile && (
                <AddNewFileModal closeModal={setShowAddNewFile} vault={singleVault} vaultCategories={vaultCategories} />
            )}
            {createFolder && (
                <CreateNewFolder closeModal={setCreateFolder} vault={vaultsData?.[0]} orgMembers={orgMembers} />
            )}
            {showVaultPassword && <ActivateVaultModal closeModal={setShowVaultPassword} vault_id={vaultId} />}
            {showUpgradeVault && <UpgradeVaultPlan closeModal={() => setShowUpgradeVault(false)} />}
        </section>
    )
}

import React, { useMemo, useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { ExpenseType } from '@/types/expense'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectSeparator,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { useGetAccountingTypes } from '@/services/accounting/queries'
import { Button } from '@/components/Button'

type Props = {
    closeModal: () => void
    expense: ExpenseType
}
const MatchExpenseToAccountModal = ({ closeModal, expense }: Props) => {
    const { data } = useGetAccountingTypes()
    const accounttingTypes = useMemo(() => data?.hierarchy ?? [], [data])

    const [selectedCoa, setSelectedCoa] = useState('')
    return (
        <ModalWrapper closeModal={closeModal}>
            <section className='w-[calc(100vw-25px)] md:w-[28rem] overflow-scroll '>
                <h2 className=' font-semibold text-lg opacity-90 m-4'>
                    Match this Expense ({expense?.expense_id}) to an account
                </h2>

                <div className='m-4'>
                    <p className=' text-gray7 my-3 font-medium text-sm'>
                        Matching an expense to an account helps in reconciling transactions and aids faster and accurate
                        journal entries.
                    </p>

                    <fieldset className='flex flex-col gap-1.5 mx-1 mt-5 '>
                        <label htmlFor='selected_account' className='opacity-90'>
                            Select account
                        </label>
                        <Select name='selected_account' onValueChange={setSelectedCoa} value={selectedCoa}>
                            <SelectTrigger className=' text-sm  h-10  border-gray8 flex disabled:opacity-70'>
                                <SelectValue className='' placeholder={'Select account type'} />
                            </SelectTrigger>
                            <SelectContent className='w-[24.5rem] h-56'>
                                {accounttingTypes?.map(type => (
                                    <SelectGroup className='my-1'>
                                        <SelectLabel className='text-black pl-2'>{type?.name}</SelectLabel>
                                        {type?.children.map(child => (
                                            <SelectItem className='text-gray7 pl-6' value={child?._id ?? child?.id}>
                                                {child?.name}
                                            </SelectItem>
                                        ))}
                                        <SelectSeparator />
                                    </SelectGroup>
                                ))}
                            </SelectContent>
                        </Select>
                    </fieldset>
                </div>

                <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                    <Button
                        onClick={closeModal}
                        variant={'outline'}
                        size={'lg'}
                        className='text-xs min-w-[100px] px-3 '
                    >
                        back
                    </Button>
                    <Button size={'lg'} className='text-xs px-4'>
                        Confirm matching
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}

export default MatchExpenseToAccountModal

import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { useAuthContext } from '@/context'
import { capitalizeText } from '@/utils/string'

type PaymentProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    addProfile: React.Dispatch<React.SetStateAction<boolean>>
    setSettings: React.Dispatch<React.SetStateAction<boolean>>
}

const NewProfilePrompt = ({ closeModal, setSettings }: PaymentProps) => {
    const { user } = useAuthContext()
    return (
        <ModalWrapper>
            <main className='h-auto lg:h-[16.6rem] w-screen lg:w-[38rem]'>
                <div className='p-6'>
                    <h1 className='text-lg font-semibold mb-3'>Welcome, {capitalizeText(user?.first_name)}!</h1>
                    <p className='text-[#5E5E5E] font-medium text-base mb-10 mx-auto'>
                        Set up crucial payroll settings first before creating user payment profiles. Customize pay days,
                        deductions and bonuses in the settings section for accurate payroll processing. Then, proceed to
                        effortlessly create payment profiles.
                    </p>
                    <div className='flex gap-5 justify-end'>
                        <Button
                            className='bg-transparent text-[#202020] px-5 h-[3rem] rounded-lg text-xs hover:bg-transparent min-w-[100px] border border-gray8'
                            onClick={() => closeModal(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            className='bg-brand text-white px-8 h-[3rem] rounded-lg text-[14px] min-w-[130px]'
                            onClick={() => {
                                // addProfile(true)
                                closeModal(false)
                                setSettings(true)
                            }}
                        >
                            Proceed
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default NewProfilePrompt
